import React from 'react'

import { Input, DarkGreyButton } from '../..'
import { RegistrationForm } from '../../types'

import { LetterLabelTitle, LetterLabel } from './styles'
import { calculateInputWidth } from './utils'

interface ConfirmDataProps {
  formData: RegistrationForm
  // setFormData: (value: SetStateAction<RegistrationForm>) => void
}

const ConfirmData = ({ formData }: ConfirmDataProps) => {
  return (
    <div className="reactanguloInterno">
      <div className="contentInputs-one">
        <div className="containerInput">
          <LetterLabel>Nombre*</LetterLabel>
          <Input
            id="nameregister"
            name="name"
            value={formData.name}
            disabled={true}
          />
        </div>
        <div className="containerInput">
          <LetterLabel>Apellidos*</LetterLabel>

          <Input
            id="lastnameregister"
            name="lastName"
            value={formData.lastname}
            disabled={true}
          />
        </div>
        {formData.birthDate && (
          <div className="containerInput">
            <LetterLabel>Fecha de nacimiento</LetterLabel>

            <Input
              id="datebornregister"
              name="dateOfBirth"
              value={formData.birthDate}
              disabled={true}
            />
          </div>
        )}

        <div className="containerInput">
          <LetterLabel>Email*</LetterLabel>
          <Input
            id="emailregister"
            name="email"
            value={formData.email}
            disabled={true}
          />
        </div>
        <div className="containerInput">
          <LetterLabel>Télefono*</LetterLabel>

          <Input
            id="phoneregister"
            name="phone"
            value={formData.phone}
            disabled={true}
          />
        </div>
        {formData.genre && (
          <div className="containerInput">
            <LetterLabelTitle>Género</LetterLabelTitle>
            <div className="container-inpus-options">
              <Input
                id="gener-femine"
                name="genre"
                value={formData.genre}
                disabled={true}
                inputClass={'input-selected selected'}
                width={calculateInputWidth(formData.genre)}
              />
            </div>
          </div>
        )}

        {formData.experienceType && (
          <div className="containerInput">
            <LetterLabelTitle>Tipo de Experiencia</LetterLabelTitle>

            <div className="container-inpus-options">
              <Input
                id="exp-principiante"
                name="experienceType"
                value={formData.experienceType}
                disabled={true}
                inputClass={'input-selected selected'}
                width={calculateInputWidth(formData.experienceType)}
              />
            </div>
          </div>
        )}

        <div className="containerInput">
          <LetterLabelTitle>Tipo de actividad en Diga-ah!</LetterLabelTitle>

          <div className="container-inpus-options">
            {formData.activities &&
              formData.activities.map((item: string, i: number) => {
                return (
                  <Input
                    key={i}
                    id={item === 'Cursos' ? 'act-cursos' : 'act-individuales'}
                    name={item === 'Curso' ? 'actividad1' : 'activity'}
                    value={item}
                    disabled={true}
                    inputClass={'input-selected selected'}
                    width={calculateInputWidth(item)}
                  />
                )
              })}
          </div>
        </div>
      </div>
      <DarkGreyButton type="submit" children={'Confirmar datos'} width={140} />
    </div>
  )
}

export default ConfirmData
