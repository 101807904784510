import React, { ChangeEvent, useState } from 'react'

import { Spacer } from '../../global'
import { PasswordInput } from '../../inputs'
import { SectionSubtitle } from '../../texts'
import { ChangePasswordContainer } from '../styles'

const ChangePasswordPart = ({
  formType,
  credentials,
  handleChange,
  inputClass,
}: {
  formType: string | null
  credentials: {
    currentPassword?: string
    password: string
    confirmPassword: string
    email?: string
    confirmEmail?: string
  }
  handleChange: (value: string, filterName: string) => void
  inputClass?: string
}) => {
  const [error, setError] = useState<string | null>(null)

  const disabledInput =
    credentials.password === '' || credentials.currentPassword === ''
      ? true
      : false

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    // Restablecer los mensajes de error
    setError('')

    let errorMessage = ''

    if (name === 'currentPassword' && value === '') {
      errorMessage += '<p>Ingresa la contraseña actual</p>'
    }

    if (name === 'password') {
      // Verificar la contraseña
      const hasMinLength = value.length >= 8
      const hasUppercase = /[A-Z]/.test(value)
      const hasLowercase = /[a-z]/.test(value)
      const hasNumber = /[0-9]/.test(value)
      const hasSymbol = /[@$!%*?&]/.test(value)

      if (
        !hasMinLength ||
        !hasUppercase ||
        !hasLowercase ||
        !hasNumber ||
        !hasSymbol
      ) {
        errorMessage += '<p>La contraseña debe:</p> '
        if (!hasMinLength) {
          errorMessage += '<p>Tener al menos 8 caracteres</p> '
        }
        if (!hasUppercase) {
          errorMessage += '<p>Tener al menos una mayúscula</p>'
        }
        if (!hasLowercase) {
          errorMessage += '<p>Tener al menos una minúscula</p>'
        }
        if (!hasNumber) {
          errorMessage += '<p>Tener al menos un número</p>'
        }
        if (!hasSymbol) {
          errorMessage += '<p>Tener al menos un símbolo</p>'
        }
      }
    }

    if (name === 'confirmPassword' && value !== credentials.password) {
      errorMessage += '<p>Las contraseñas no coinciden</p>'
    }

    setError(errorMessage)

    handleChange(value, name)
  }

  return (
    <ChangePasswordContainer>
      <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={24}>
        Contraseña
      </SectionSubtitle>
      <Spacer height={20} />
      {formType !== 'new-user' && (
        <>
          <SectionSubtitle fontWeight={500} fontSize={12}>
            Contraseña actual
          </SectionSubtitle>

          <PasswordInput
            fieldName="currentPassword"
            value={credentials.currentPassword ?? ''}
            handleChange={handleChangeInput}
            inputClass={inputClass}
          />
          <Spacer height={20} />
        </>
      )}

      <SectionSubtitle fontWeight={500} fontSize={12}>
        {formType !== 'new-user' ? 'Nueva contraseña' : 'Contraseña'}
      </SectionSubtitle>
      <PasswordInput
        fieldName="password"
        value={credentials.password}
        handleChange={handleChangeInput}
        inputClass={inputClass}
      />

      <Spacer height={20} />
      <SectionSubtitle fontWeight={500} fontSize={12}>
        <>
          Confirmar{' '}
          {formType !== 'new-user' ? 'nueva contraseña' : 'contraseña'}
        </>
      </SectionSubtitle>

      <PasswordInput
        fieldName="confirmPassword"
        value={credentials.confirmPassword}
        handleChange={handleChangeInput}
        inputClass={inputClass}
        disabledInput={disabledInput}
      />

      {error && (
        <div
          className="error"
          dangerouslySetInnerHTML={{ __html: error }}
        ></div>
      )}
    </ChangePasswordContainer>
  )
}

export default ChangePasswordPart
