import React, { ChangeEvent, useState } from 'react'

import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri'

import { Input } from '..'

const PasswordInput = ({
  fieldName,
  value,
  handleChange,
  inputClass,
  disabledInput,
}: {
  fieldName: string
  value: string
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
  inputClass?: string
  disabledInput?: boolean
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  return (
    <div className="content-password">
      <label>
        <Input
          name={fieldName}
          id={fieldName}
          type={showPassword ? 'text' : 'password'}
          onChange={handleChange}
          value={value}
          placeholder="*****"
          inputClass={inputClass}
          disabled={disabledInput}
        />
        <div className="password-button" onClick={handleShowPassword}>
          {showPassword ? (
            <RiEyeCloseLine color={'#8E92BC'} size={'24'} />
          ) : (
            <RiEyeLine color={'#8E92BC'} size={'24'} />
          )}
        </div>
      </label>

      {/* <Input
        id={`${fieldName}register`}
        name={fieldName}
        type={
          (fieldName === 'password' && !showPassword) ||
          (fieldName === 'confirmPassword' && !showPasswordConfirm)
            ? 'password'
            : 'text'
        }
        placeholder={translateFieldName(fieldName)
          .replace(/([A-Z])/g, ' $1')
          .trim()}
        value={fieldValue}
        onChange={handleChange}
        full={fieldValue}
      />
      {fieldName === 'password' ? (
        !showPassword ? (
          <RiEyeCloseLine
            color={'#8E92BC'}
            size={'24'}
            onClick={() => setShowPassword(true)}
          />
        ) : (
          <RiEyeLine
            color={'#8E92BC'}
            size={'24'}
            onClick={() => setShowPassword(false)}
          />
        )
      ) : fieldName === 'confirmPassword' ? (
        !showPasswordConfirm ? (
          <RiEyeCloseLine
            color={'#8E92BC'}
            size={'24'}
            onClick={() => setShowPasswordConfirm(true)}
          />
        ) : (
          <RiEyeLine
            color={'#8E92BC'}
            size={'24'}
            onClick={() => setShowPasswordConfirm(false)}
          />
        )
      ) : null} */}
    </div>
  )
}

export default PasswordInput
