import React, { ChangeEvent, useEffect, useState } from 'react'

import { AiOutlinePlus } from 'react-icons/ai'

import { SectionTitle, DragAndDrop, LightGreyButton } from '../../../components'
import { useInputReset } from '../../../hooks'
import { Spacer } from '../../global'
import { QuotesProps, Person, WeeklyTheme, Profesor } from '../../types'

import { DescriptionSection, ValueSection, AddPeopleSection } from './sections'
import { DisplayWeekData, InputWeek } from './sections/work-material'
import { FormContainer, SectionContainer } from './styles'

const profesor: Profesor = {
  id: 1,
  name: 'Felipe',
  lastname: 'forastiere',
  phone: '+34 666 666 666',
  birthDate: '16/01/1988',
  email: 'email@email.com',
  genre: 'Masculino',
  title: 'Vocal coach-cantante-músico',
  activity: ['tecnica', 'legit', 'lorem'],
  experienceType: [
    'Tecnica lirica y legit',
    'Ejecución',
    'Rehabilitación vocal',
  ],
  yearsTeaching: 2,
  yearsOfExperience: 2,
  yearsSinging: 3,
  yearsOfMethodology: 3,
  biography:
    'Nací en Luján, donde me crié: una pequeña ciudad en la provincia de Buenos Aires, Argentina. Hijo de un bioquímico y una psicóloga, además de músicos, por lo que siempre he tenido acceso a lenguajes diferentes que estimularon y enriquecieron mi forma de entender el mundo, y hoy, la voz. A los 10 años comencé a estudiar guitarra. Al descubrir sus reglas, comencé a investigar el piano. Y más tarde el canto. ',
  image: '/assets/profile.png',
  type: 'professor',
}
const professorsList = [
  profesor,
  {
    id: 2,
    name: 'Felipe',
    lastname: 'Lorem',
    phone: '+34 666 666 666',
    birthDate: '16/01/1988',
    email: 'email@email.com',
    genre: 'Masculino',
    title: 'Vocal coach-cantante-músico',
    activity: [],
    experienceType: [
      'Tecnica lirica y legit',
      'Ejecución',
      'Rehabilitación vocal',
    ],
    yearsTeaching: 2,
    yearsOfExperience: 2,
    yearsSinging: 3,
    yearsOfMethodology: 3,
    biography:
      'Nací en Luján, donde me crié: una pequeña ciudad en la provincia de Buenos Aires, Argentina. Hijo de un bioquímico y una psicóloga, además de músicos, por lo que siempre he tenido acceso a lenguajes diferentes que estimularon y enriquecieron mi forma de entender el mundo, y hoy, la voz. A los 10 años comencé a estudiar guitarra. Al descubrir sus reglas, comencé a investigar el piano. Y más tarde el canto. ',
    image: '/assets/profile.png',
    type: 'professor',
  },
  {
    id: 3,
    name: 'Ipsum',
    lastname: 'Jorga',
    phone: '+34 666 666 666',
    birthDate: '16/01/1988',
    email: 'email@email.com',
    genre: 'Masculino',
    title: 'Vocal coach-cantante-músico',
    activity: [],
    experienceType: [
      'Tecnica lirica y legit',
      'Ejecución',
      'Rehabilitación vocal',
    ],
    yearsTeaching: 2,
    yearsOfExperience: 2,
    yearsSinging: 3,
    yearsOfMethodology: 3,
    biography:
      'Nací en Luján, donde me crié: una pequeña ciudad en la provincia de Buenos Aires, Argentina. Hijo de un bioquímico y una psicóloga, además de músicos, por lo que siempre he tenido acceso a lenguajes diferentes que estimularon y enriquecieron mi forma de entender el mundo, y hoy, la voz. A los 10 años comencé a estudiar guitarra. Al descubrir sus reglas, comencé a investigar el piano. Y más tarde el canto. ',
    image: '/assets/profile.png',
    type: 'professor',
  },
]

const INITIAL_STATE_WEEK: WeeklyTheme = {
  id: 1,
  weekTitle: '',
  content: [
    { id: 0, contentTitle: '', contentLink: '', contentDescription: '' },
  ],
  task: [],
  isVisible: true,
  isActive: true,
}

const NewCourseForm = () => {
  const { resetInput } = useInputReset()
  const [studentsList, setStudentList] = useState<Person[]>([])

  const [profesorsList, setProfesorsList] = useState<Person[]>([])

  const [files, setFiles] = useState<File[]>([])
  const [weekInformation, setWeekInformation] =
    useState<WeeklyTheme>(INITIAL_STATE_WEEK)
  const [weeksData, setWeeksData] = useState<WeeklyTheme[]>([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [weekTasks, setWeekTasks] = useState<any>([])
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    description: '',
  })
  const [formQuotas, setFormQuotas] = useState<{
    totalValue: number
    amountQuotas: number
    quotasValue: QuotesProps[]
  }>({
    totalValue: 0,
    amountQuotas: 0,
    quotasValue: [],
  })
  const [disableButton, setDisableButton] = useState(true)
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleQuotas = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormQuotas({
      ...formQuotas,
      [name]: value,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleWeekTask = (tasks: any) => {
    if (
      Array.isArray(weekInformation.task) &&
      weekInformation.task.length !== 0
    ) {
      const taskId = weekInformation.task.length
      const newTasks = {
        id: taskId + 1,
        taskLink: tasks.taskLink,
        taskDescription: tasks.taskDescription,
      }
      setWeekInformation((prevWeekInformation) => ({
        ...prevWeekInformation,
        task: [...(prevWeekInformation.task || []), newTasks],
      }))
      setWeekTasks([...weekTasks, newTasks])
    } else {
      const newTasks = {
        id: 1,
        taskLink: tasks.taskLink,
        taskDescription: tasks.taskDescription,
      }
      setWeekInformation((prevWeekInformation) => ({
        ...prevWeekInformation,
        task: [...(prevWeekInformation.task || []), newTasks],
      }))
      setWeekTasks([newTasks])
    }
  }

  const handleAddWeek = () => {
    if (
      weeksData &&
      weeksData.length !== 0 &&
      weeksData[weeksData.length - 1].weekTitle !== ''
    ) {
      setWeeksData([...weeksData, weekInformation])
      setWeekInformation({ ...INITIAL_STATE_WEEK, id: weeksData.length + 1 })
      setWeekTasks([])
    } else {
      setWeeksData([weekInformation])
      resetInput('weekTitle')
    }
  }

  useEffect(() => {
    const isDisabled = !weekInformation.weekTitle.trim()
    setDisableButton(isDisabled)
  }, [weeksData, weekInformation])

  return (
    <FormContainer>
      <SectionContainer direction="column">
        <SectionTitle text="Foto de portada" />
        <Spacer height={8} />
        <DragAndDrop files={files} setFiles={setFiles} />
      </SectionContainer>
      <Spacer height={32} />
      <DescriptionSection handleChange={handleChange} />
      <ValueSection
        formQuotas={formQuotas}
        handleQuotas={handleQuotas}
        setFormQuotas={setFormQuotas}
      />
      <Spacer height={50} />
      <AddPeopleSection
        title="alumno"
        professorsList={professorsList}
        peopleList={studentsList}
        setList={setStudentList}
      />
      <AddPeopleSection
        title="profesor"
        professorsList={professorsList}
        peopleList={profesorsList}
        setList={setProfesorsList}
      />
      <Spacer height={50} />
      <SectionContainer direction="column">
        <SectionTitle text="Material de trabajo" />
        <Spacer height={26} />
        <DisplayWeekData weeksData={weeksData} />
        <Spacer height={54} />
        <InputWeek
          weekNumber={weeksData.length + 1}
          weekTasks={weekTasks}
          weekInformation={weekInformation}
          handleWeekTask={handleWeekTask}
          weeksData={weeksData}
          setWeeksData={setWeeksData}
          setWeekInformation={setWeekInformation}
        />
        <Spacer height={54} />
        {/* desabilitar esto hasta que no esten completos los campos obligatorios  */}
        <LightGreyButton
          buttonClass="more-weeks"
          fontSize={12}
          fontWeight={500}
          onClick={handleAddWeek}
          isDisabled={disableButton}
        >
          <AiOutlinePlus /> Semana
        </LightGreyButton>
      </SectionContainer>
    </FormContainer>
  )
}

export default NewCourseForm
