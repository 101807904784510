import React, { useEffect, useState } from 'react'

import { GrAdd } from 'react-icons/gr'
import { useLocation, useNavigate } from 'react-router-dom'

import { useProfessors, useStudents, useUser } from '../../context'
import { capitalizeFirstLetter } from '../helpers/functions'
import { AdminMenu, InlineMenuHeaderProps } from '../types'

import { NavbarContainer, NavbarLinkContainer, NavbarLink } from './styles'

const InlineMenuHeader = ({
  modalStatus,
  setModalStatus,
}: InlineMenuHeaderProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    // Verifica si la URL contiene los segmentos "/alumnos/", "/profesores/" o está en la página principal "/"
    const isAlumnos = location.pathname.includes('/alumnos')
    const isProfesores = location.pathname.includes('/profesores')
    const isNewUser = location.pathname.includes('/nuevo-usuario')

    if (!isAlumnos && !isProfesores) {
      setActiveItem('')
    }

    if (location.pathname === '/profile' || isNewUser) {
      setActiveItem('')
    }
  }, [location])

  const { onEditionMode } = useUser()
  const { setActiveProfessor } = useProfessors()
  const { setActiveStudent } = useStudents()
  const [activeItem, setActiveItem] = useState('administración')

  const menu = Object.values(AdminMenu) as string[]

  const handleClick = (item: string) => {
    navigate(`/${item}`)
    setActiveItem(item)
    setActiveProfessor(null)
    setActiveStudent(null)
  }

  return (
    <NavbarContainer>
      <NavbarLinkContainer>
        {menu.map((item, i) => {
          if (item !== AdminMenu.bono) {
            return (
              <NavbarLink
                className={activeItem === item ? 'active' : ''}
                onClick={() => handleClick(item)}
                disabled={onEditionMode}
                key={i}
              >
                {capitalizeFirstLetter(item)}
              </NavbarLink>
            )
          } else {
            return (
              <NavbarLink
                className={activeItem === item ? 'active' : undefined}
                onClick={() => setModalStatus(!modalStatus)}
                key={i}
              >
                <GrAdd />
                Agregar Bono
              </NavbarLink>
            )
          }
        })}
      </NavbarLinkContainer>
    </NavbarContainer>
  )
}

export default InlineMenuHeader
