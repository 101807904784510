import React, { useState, useEffect, ChangeEvent, useRef } from 'react'

import { FaRegEyeSlash } from 'react-icons/fa6'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'

import { MainInputProps } from '../../../../../types'
import {
  WeekNumber,
  WeekTitleInput,
  WeekIcons,
  MainInputContainer,
} from '../styles'

const MainInput = ({
  weeksData,
  weekInformation,
  weekNumber,
  hideWeek,
  deleteWeek,
  showContent,
  setWeekInformation,
  setShowContent,
}: MainInputProps) => {
  const [isActive, setIsActive] = useState(false) // State to manage the active class
  const ref = useRef<HTMLDivElement>(null)

  const handleChange = (event: ChangeEvent<HTMLInputElement> | undefined) => {
    if (!event) {
      return
    }

    const { name, value } = event.target

    setWeekInformation((prevWeekInformation) => ({
      ...prevWeekInformation,
      id: weeksData.length + 1,
      [name]: value,
    }))
  }

  const handleContainerClick = () => {
    if (ref.current) {
      ref.current.focus()
    }
    setIsActive(true)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsActive(false)
    }
  }

  const handleCopy = () => {
    console.log('copy')
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    // ${isFocused ? "active" : "inactive"}
    <MainInputContainer
      className={`${isActive ? 'active' : ''}`} // Add the active class conditionally
      ref={ref}
      onClick={handleContainerClick}
    >
      <WeekNumber> Semana {weekNumber}</WeekNumber>
      <WeekTitleInput
        name="weekTitle"
        placeholder="Nombre de la semana..."
        value={weekInformation.weekTitle}
        onChange={handleChange}
      />

      <div className="arrow" onClick={() => setShowContent(!showContent)}>
        {showContent ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </div>

      <WeekIcons>
        <div className="icon" onClick={() => hideWeek(weekNumber)}>
          <FaRegEyeSlash />
        </div>
        <div className="icon" onClick={handleCopy}>
          <img src="/assets/icons/layers.svg" alt="" />
        </div>
        <div className="icon" onClick={() => deleteWeek(weekNumber)}>
          <img src="/assets/icons/delete.svg" alt="" />
        </div>
      </WeekIcons>
    </MainInputContainer>
  )
}

export default MainInput
