import styled from 'styled-components'

export const DragAndDropContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const Image = styled.div<{ $image: string }>`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000;
  background-image: ${(props) => `url(${props.$image})`};

  .overlay {
    width: 100%;
    height: 100%;
    background: #313131a3;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    cursor: pointer;

    p {
      font-size: 16px;
      line-height: 15px;
      color: #fff;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      svg {
        font-size: 24px;
      }
    }

    &:hover {
      opacity: 1;
    }
  }
`
export const EditImageModal = styled.div`
  height: auto;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
`
