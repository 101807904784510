import React, { useEffect, useState } from 'react'

import { DatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { RiEdit2Line, RiSaveLine, RiDeleteBin5Line } from 'react-icons/ri'

import { SectionSubtitle, DateCalendar, Select } from '../..'
import { useCupons, useStudents, useUser } from '../../../context'
import { Spacer } from '../../global'
import { transformDate } from '../../helpers/functions'
import { Cupon } from '../../types'
import { TableContainer, MarksContainer, LightBlueMark } from '../styles'

const ProfessorIndividualClass = () => {
  const { studentsList } = useStudents()
  const {
    saveIndividualClass,

    professorCupons,
    cupons,
    deleteCupon,
  } = useCupons()
  const { onEditionMode, setOnEditionMode } = useUser()
  const { activeCupon, setActiveCupon } = useCupons()

  const [date, setDate] = useState<Dayjs>(dayjs())
  const [searchStudent, setSearchStudent] = useState<string | null>(null)
  const [list, setList] = useState<Cupon[] | null>(professorCupons)
  const [cuponNewDate, setCuponNewDate] = useState<Cupon | null>(null)

  const handleDateFilter = (date: Dayjs | null) => {
    if (date) {
      setDate(date)
    }
  }

  const matchIdStudent = (studentId: string | number) => {
    return (
      studentsList &&
      studentsList
        .filter((x) => x.uid === studentId)
        .map((stu, i) => {
          const _name = stu.name + ' ' + stu.lastname
          const _photo = stu.image ? stu.image : ''
          return (
            <span key={i}>
              <img className="photo" src={_photo} />
              <span className="yellow">Alumno: </span> {_name}
            </span>
          )
        })
    )
  }

  const handleDateChange = (date: Dayjs | null) => {
    if (activeCupon) {
      const updatedCupon = {
        ...activeCupon,
        initDate: dayjs(date).format('YYYY-MM-DD'),
        dueDate: dayjs(date).format('YYYY-MM-DD'),
      }

      setCuponNewDate(updatedCupon)
    }
  }

  const handleSave = () => {
    setOnEditionMode(false)
    if (cuponNewDate) {
      saveIndividualClass(cuponNewDate.uid, cuponNewDate)
    }
  }

  const handleDelete = (couponId: string, studentId: string) => {
    deleteCupon(couponId, studentId)
  }

  useEffect(() => {
    if (searchStudent && professorCupons) {
      const filterByStudent = professorCupons.filter(
        (_class) => _class.idStudent === searchStudent,
      )
      setList(filterByStudent)
    } else {
      setList(professorCupons)
    }
  }, [searchStudent, professorCupons, cupons, activeCupon])

  return (
    list && (
      <TableContainer>
        <MarksContainer>
          <SectionSubtitle fontSize={14} fontWeight={500}>
            Clases individuales :
          </SectionSubtitle>
          <LightBlueMark>{date.format('MMMM')}</LightBlueMark>
          <LightBlueMark>{professorCupons?.length}</LightBlueMark>

          <DatePicker
            views={['year', 'month']}
            openTo="month"
            value={date}
            onChange={handleDateFilter}
            slotProps={{
              calendarHeader: {
                sx: {
                  '.MuiPickersArrowSwitcher-root': {
                    display: 'none',
                  },
                },
              },
              popper: {
                sx: {
                  '.MuiPaper-root': {
                    borderRadius: '10px',
                  },
                  '.MuiDateCalendar-root': {
                    height: '290px',
                    width: '230px',

                    '.MuiMonthCalendar-root': {
                      width: '100%',
                    },
                  },
                  ...{
                    '& .MuiPickersMonth-monthButton.Mui-selected': {
                      backgroundColor: '#08A8D2',
                    },
                  },
                },
              },
            }}
          />

          <div style={{ width: 350 }}>
            {studentsList && (
              <Select
                list={studentsList}
                handleSelectChange={(e) => setSearchStudent(e.target.value)}
                name="filterByStudent"
                defaultValue="Filtrar por alumno"
              />
            )}
          </div>
        </MarksContainer>
        <Spacer height={18} />

        {list.map((item, i) => {
          if (
            dayjs(item.initDate).isSame(dayjs(date), 'month') &&
            dayjs(item.initDate).isSame(dayjs(date), 'year')
          ) {
            return (
              <div
                className={`t-row ${activeCupon?.uid === item.uid && 'active'}`}
                onClick={() => setActiveCupon(item)}
                key={i}
              >
                <div>Clase Individual </div>
                {onEditionMode && activeCupon?.uid === item.uid ? (
                  <DateCalendar
                    handleDate={handleDateChange}
                    defaultValue={dayjs(item.dueDate)}
                  />
                ) : (
                  <div className="red">
                    Fecha: {transformDate(item.dueDate)}
                  </div>
                )}
                <div className="t-student">
                  {matchIdStudent(item.idStudent)}
                </div>
                <div className="t-actions">
                  {onEditionMode && activeCupon?.uid === item.uid ? (
                    <div onClick={handleSave}>
                      <RiSaveLine />
                    </div>
                  ) : (
                    <div
                      className="t-actions-edit"
                      onClick={() => {
                        setOnEditionMode(true)
                        setActiveCupon(item)
                      }}
                    >
                      <RiEdit2Line />
                    </div>
                  )}
                  <div
                    className="t-actions-delete"
                    onClick={() => handleDelete(item.uid, item.idStudent)}
                  >
                    <RiDeleteBin5Line />
                  </div>
                </div>
              </div>
            )
          }
        })}
      </TableContainer>
    )
  )
}

export default ProfessorIndividualClass
