import React, { useState } from 'react'

import { useUser } from '../../context'
import NavBar from '../NavBar/NavBar'
import { LayoutProps } from '../types'
import '@fontsource/plus-jakarta-sans'

import { MainContent, MainContentNoUser, NavBarContainer } from './styles'

// import Header from '../Header/Header'

const Layout = ({ children }: LayoutProps) => {
  const { userRole, loadingUser } = useUser()

  const [isNavbarOpen, setIsNavbarOpen] = useState(false)

  const handleToggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen)
  }

  return (
    !loadingUser && (
      <div
        className="container-fluid d-flex"
        style={{
          padding: 0,
          height: 'fit-content',
          justifyContent: userRole ? 'flex-end' : '',
        }}
      >
        {userRole ? (
          <>
            <NavBarContainer>
              <NavBar isOpen={isNavbarOpen} onToggle={handleToggleNavbar} />
            </NavBarContainer>
            <MainContent>{children}</MainContent>
          </>
        ) : (
          <MainContentNoUser>{children}</MainContentNoUser>
        )}
      </div>
    )
  )
}

export default Layout
