import styled from 'styled-components'

import '@fontsource/plus-jakarta-sans'

export const Form = styled.div`
  padding: 0 8px;
  margin-top: 30px;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content-description {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 190px;
    border-radius: 10px;
    border: 0.5px solid #08a8d2;
    box-shadow: 0px 4px 8px 0px rgba(142, 146, 188, 0.05);
    margin-bottom: 50px;

    .description {
      color: #0a0a18;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: -0.4px;
      max-width: 900px;
    }
  }

  .content-form {
    background-color: #fdfdfd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px 0px rgba(142, 146, 188, 0.05);
    width: 100%;

    div {
      max-width: 900px;

      .title-answer {
        color: #0a0a18;
        text-align: justify;

        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 30px */
        letter-spacing: -0.4px;

        margin-bottom: 60px;
      }
      .container-question {
        margin-bottom: 45px;
      }
      .content-answer {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 10px;
        p {
          color: #0a0a18;
          text-align: justify;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 27px */
          letter-spacing: -0.36px;
        }
      }

      .content-number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #f6f6f6;
        margin-bottom: 6px;
        color: #8e92bc;
        border: solid 1px #08a8d2;
        margin-right: 10px;
      }

      .container-inpus-options {
        display: flex;
        width: 45%;
        align-items: flex-start;
        align-content: flex-start;
        gap: 8px;
        flex-wrap: wrap;
      }

      .input-selected {
        border-radius: 10px;
        background: #f6f6f6;
        cursor: pointer;
        transition: background-color 0.3s;
        color: #0a0a18;

        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.12px;
      }

      .selected {
        border: 0.5px solid #08a8d2;
        background: #bdddeb !important;
        background-color: red;
      }

      .thanks-text {
        margin-top: 100px;
        margin-bottom: 50px;
        color: #0a0a18;
        text-align: justify;

        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 30px */
        letter-spacing: -0.4px;
      }

      .button-save {
        display: flex;
        justify-content: center;
        width: 180px;
        height: 40px;
      }
    }
  }

  .textarea-answer {
    overflowy: hidden;
    resize: none;
    minwidth: 100%;
    maxwidth: 100%;
    margin-left: 10px;
    resize: none;
    color: #0a0a18;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
  }
`

export const ContainerInitialForm = styled.div`
  width: 100%;
  background-image: url('/assets/icons/Copia-DIGA-AH-1.svg');
`

export const InitialFormContent = styled.div`
  width: 100%;
  padding: 32px;
  background: #f4f4f4;
`
