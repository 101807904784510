import styled from 'styled-components'

import '@fontsource/plus-jakarta-sans'

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`

export const PhotoContainer = styled.div<{ $image: string }>`
  width: 159px;
  height: 134px;
  border-radius: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000;
  background-position: center;
  background-image: ${(props) => `url(${props.$image})`};
`

export const PersonData = styled.div`
  display: flex;
  flex-direction: column;
  color: #313131;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.12px;
  margin-left: 10px;
  justify-content: flex-start;

  .name {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #0a0a18;
    margin-bottom: 5px;
    text-transform: capitalize;
  }

  .type {
    display: flex;
    margin-bottom: 15px;

    span {
      color: #08a8d2;
    }

    .prof-exp {
      text-transform: capitalize;
      letter-spacing: -0.12px;
      color: #000;
    }
  }

  .bonos {
    display: flex;
    flex-direction: row;

    .bono {
      background-color: #313131;
      border-radius: 15px;
      padding: 6px 12px;
      margin-right: 10px;
      color: #fdfdfd;
      text-transform: capitalize;
    }
  }
`
