import styled from 'styled-components'

import '@fontsource/plus-jakarta-sans'
import { DragAndDropProps } from '../../types'

export const DragAndDropContainer = styled.form<DragAndDropProps>`
  padding: 0;

  .drag-drop {
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 112px;
    width: 270px;
    border: 1px dotted;
    padding: 8px 7px;
    background-color: ${(props: { backgroundColor: string }) =>
      props.backgroundColor ? 'lightgray' : 'white'};
    margin-bottom: 8px;
    position: relative;
    overflow: hidden;

    svg {
      color: #8e92bc;
      font-size: 20px;
      margin-right: 10px;
    }

    #img-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      z-index: 0;
    }
  }

  .dragAndDropDisclaimer {
    color: #8e92bc;
    font-size: 12px;
  }
`

export const DragAndDropComponent = styled.div``
