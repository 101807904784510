import React, { ChangeEvent } from 'react'

import { SectionSubtitle, InlineMenu, Input, Textarea } from '..'
import { useUser } from '../../context'
import { Spacer } from '../global'
import { AdminNewUser, Profesor } from '../types'

const ProfessorsSection = ({
  type,
  editedProfile,
  handleMenuChange,
  handleChange,
}: {
  type?: string | null
  editedProfile: Profesor | AdminNewUser | null
  handleMenuChange: (
    filterName: string,
    value: string | string[] | null,
  ) => void
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
}) => {
  const { onEditionMode } = useUser()
  const inNewUser = type === 'new-user' ? true : onEditionMode

  return (
    editedProfile && (
      <>
        <Spacer height={40} />
        <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={21}>
          Clases
        </SectionSubtitle>
        <Spacer height={16} />
        <InlineMenu
          menuName="experticeMenu"
          userType="admin"
          activeFilter={editedProfile.activities}
          setActiveFilter={(value) => handleMenuChange('activities', value)}
          isDisabled={!inNewUser}
        />
        <Spacer height={40} />
        <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={21}>
          Aptitudes/ estudios
        </SectionSubtitle>
        <Spacer height={16} />
        <InlineMenu
          menuName="experienceMenu"
          userType="admin"
          activeFilter={editedProfile.experienceType}
          setActiveFilter={(value) => handleMenuChange('experienceType', value)}
          isDisabled={!inNewUser}
        />
        <Spacer height={40} />
        <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={21}>
          Experiencia (en años)
        </SectionSubtitle>
        <Spacer height={16} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            gap: 32,
          }}
        >
          <div>
            <SectionSubtitle fontWeight={400} fontSize={14} textalign="center">
              Antigüedad en la metodologia
            </SectionSubtitle>
            <Spacer height={13} />
            <Input
              type="number"
              name="yearsTeaching"
              id="yearsTeaching"
              placeholder={editedProfile.yearsTeaching?.toString()}
              defaultValue={editedProfile.yearsTeaching}
              disabled={!inNewUser}
              onChange={handleChange}
              inputClass="profession-data profile-input"
            />
            <Spacer height={13} />
            <SectionSubtitle fontWeight={400} fontSize={14} textalign="center">
              años
            </SectionSubtitle>
          </div>
          <div>
            <SectionSubtitle fontWeight={400} fontSize={14} textalign="center">
              Experiencia artistica
            </SectionSubtitle>
            <Spacer height={13} />
            <Input
              type="number"
              name="yearsOfExperience"
              id="yearsOfExperience"
              placeholder={editedProfile.yearsOfExperience?.toString()}
              defaultValue={editedProfile.yearsOfExperience}
              disabled={!inNewUser}
              onChange={handleChange}
              inputClass="profession-data profile-input"
            />
            <Spacer height={13} />
            <SectionSubtitle fontWeight={400} fontSize={14} textalign="center">
              años
            </SectionSubtitle>
          </div>
          <div>
            <SectionSubtitle fontWeight={400} fontSize={14} textalign="center">
              Espectaculos realizados
            </SectionSubtitle>
            <Spacer height={13} />
            <Input
              type="number"
              name="yearsSinging"
              id="yearsSinging"
              placeholder={editedProfile.yearsSinging?.toString()}
              defaultValue={editedProfile.yearsSinging}
              disabled={!inNewUser}
              onChange={handleChange}
              inputClass="profession-data profile-input"
            />
          </div>
          <div>
            <SectionSubtitle fontWeight={400} fontSize={14} textalign="center">
              Acompaño a
            </SectionSubtitle>
            <Spacer height={13} />
            <Input
              type="number"
              name="yearsOfMethodology"
              id="yearsOfMethodology"
              placeholder={editedProfile.yearsOfMethodology?.toString()}
              defaultValue={editedProfile.yearsOfMethodology}
              disabled={!inNewUser}
              onChange={handleChange}
              inputClass="profession-data profile-input"
            />
            <Spacer height={13} />
            <SectionSubtitle fontWeight={400} fontSize={14} textalign="center">
              alumnos
            </SectionSubtitle>
          </div>
        </div>
        <Spacer height={40} />
        <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={21}>
          Biografìa
        </SectionSubtitle>
        <Spacer height={16} />
        <Textarea
          type="biography"
          name="biography"
          id="biography"
          placeholder={editedProfile.biography}
          defaultValue={editedProfile.biography}
          readOnly={!inNewUser}
          onChange={handleChange}
          height={100}
          inputClass="profile-input"
        />
      </>
    )
  )
}

export default ProfessorsSection
