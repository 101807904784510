import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react'

import {
  Auth,
  signInWithPopup,
  createUserWithEmailAndPassword as createUserWithEmailAndPasswordFunc,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFunc,
  UserCredential,
  GoogleAuthProvider,
  FacebookAuthProvider,
  updatePassword,
  updateEmail,
  User,
} from 'firebase/auth'
import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore'

import { Person } from '../components/types'
import { auth, firestore } from '../firebase/firebaseConfig'
import { updateDocument } from '../firebase/utils'
import { getCookie, removeCookie, setCookie } from '../hooks/cookies'

interface UserContextProps {
  currentUser: User | null
  user: Person | null
  userRole: string | null
  auth: Auth
  onEditionMode: boolean
  loadingUser: boolean
  createUserWithEmailAndPassword: (
    email: string,
    password: string,
  ) => Promise<UserCredential | null>
  signInWithGoogle: (userType: string) => void
  signInWithEmailAndPassword: (
    email: string,
    password: string,
    userType: string,
  ) => Promise<void>
  signInWithFacebook: (userType: string) => void
  changePassword: (
    currentPassword: string,
    newPassword: string,
  ) => Promise<void>
  signOut: () => void
  setOnEditionMode: Dispatch<SetStateAction<boolean>>
  updateUser: (userData: Person) => Promise<void>
  changeEmail: (newEmail: string, userRole: string) => Promise<void>
}

const UserContext = createContext<UserContextProps>({
  currentUser: null,
  user: null,
  userRole: null,
  auth: auth,
  onEditionMode: false,
  loadingUser: false,
  createUserWithEmailAndPassword: () => Promise.resolve(null),
  signInWithGoogle: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithFacebook: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  setOnEditionMode: () => Promise.resolve(false),
  updateUser: () => Promise.resolve(),
  changeEmail: () => Promise.resolve(),
})

export const useUser = () => useContext(UserContext)

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [user, setUser] = useState<Person | null>(null)
  const [userRole, setUserRole] = useState<string | null>(null)
  const [onEditionMode, setOnEditionMode] = useState<boolean>(false)
  const [loadingUser, setLoadingUser] = useState(false)

  const userCollection = (userRole: string) => {
    return userRole === 'professor'
      ? 'professors'
      : userRole === 'student'
        ? 'students'
        : userRole
  }

  useEffect(() => {
    setLoadingUser(true)
    const unsubscribe = auth.onAuthStateChanged(async (_user) => {
      setCurrentUser(_user)

      const role = getCookie('userRole')
      const _userRole = role || (userRole && userCollection(userRole))

      if (_user && _userRole) {
        const userRef = doc(firestore, _userRole, _user.uid)

        const unsubscribeUser = onSnapshot(userRef, (snapshot) => {
          setUser(snapshot.data() as Person)
        })

        return () => unsubscribeUser()
      } else {
        removeCookie('userRole')
        removeCookie('userToken')
        setUser(null)
      }
    })

    setLoadingUser(false)
    return () => unsubscribe()
  }, [])

  const handleAuthError = (error: unknown) => {
    console.error('Authentication error:', error)
  }

  const createUserWithEmailAndPassword = async (
    email: string,
    password: string,
  ) => {
    try {
      return await createUserWithEmailAndPasswordFunc(auth, email, password)
    } catch (error) {
      handleAuthError(error)
      return null
    }
  }

  const signInWithPopupProvider = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (provider: any, userType: string) => {
      try {
        const result = await signInWithPopup(auth, provider)
        const currentUser = result.user
        const token = await currentUser.getIdToken()

        setCookie('userToken', token, { expires: 30 })
        setCookie('userRole', userType, { expires: 30 })
        setUserRole(userType)

        const userDocRef = doc(firestore, userType, currentUser.uid)
        const userDoc = await getDoc(userDocRef)

        if (!userDoc.exists()) {
          const userData: Person = {
            uid: currentUser.uid,
            name: currentUser.displayName || '',
            lastname: '',
            phone: currentUser.phoneNumber || '',
            birthDate: '',
            email: currentUser.email || '',
            genre: '',
            activities: [],
            experienceType: '',
            image: currentUser.photoURL || '',
            form: false,
            role: userType,
          }
          await setDoc(userDocRef, userData)
          setUser(userData)
        } else {
          setUser(userDoc.data() as Person)
        }
      } catch (error) {
        handleAuthError(error)
      }
    },
    [],
  )

  const signInWithGoogle = (userType: string) => {
    const provider = new GoogleAuthProvider()
    return signInWithPopupProvider(provider, userType)
  }

  const signInWithFacebook = (userType: string) => {
    const provider = new FacebookAuthProvider()
    return signInWithPopupProvider(provider, userType)
  }

  const signInWithEmailAndPassword = async (
    email: string,
    password: string,
    userType: string,
  ) => {
    try {
      const result = await signInWithEmailAndPasswordFunc(auth, email, password)
      const currentUser = result.user

      const token = await currentUser.getIdToken()
      setCookie('userToken', token, { expires: 30 })
      setCookie('userRole', userType, { expires: 30 })
      setUserRole(userType)

      const userDoc = await getDoc(doc(firestore, userType, currentUser.uid))

      if (userDoc.exists()) {
        setUser(userDoc.data() as Person)
      } else {
        setUser(null)
      }
    } catch (error) {
      handleAuthError(error)
    }
  }

  const updateUser = async (userData: Person) => {
    if (!currentUser)
      throw new Error('No se ha encontrado un usuario autenticado')

    try {
      await updateDocument(userData.role, currentUser.uid, userData)
    } catch (error) {
      handleAuthError(error)
    }
  }

  const changeEmail = async (newEmail: string, userRole: string) => {
    if (!currentUser)
      throw new Error('No se ha encontrado un usuario autenticado')

    try {
      await updateEmail(currentUser, newEmail)
      await updateDocument(userCollection(userRole), currentUser.uid, {
        email: newEmail,
      })

      setUser((prevUser) =>
        prevUser ? { ...prevUser, email: newEmail } : null,
      )
    } catch (error) {
      handleAuthError(error)
    }
  }

  const changePassword = async (
    currentPassword: string,
    newPassword: string,
  ) => {
    if (!currentUser)
      throw new Error('No se ha encontrado un usuario autenticado')

    try {
      const credentials = await signInWithEmailAndPasswordFunc(
        auth,
        currentUser.email || '',
        currentPassword,
      )
      if (credentials) {
        await updatePassword(currentUser, newPassword)
      } else {
        throw new Error('Contraseña actual incorrecta')
      }
    } catch (error) {
      handleAuthError(error)
    }
  }

  const signOut = () => {
    auth
      .signOut()
      .then(() => {
        removeCookie('userToken')
        removeCookie('userRole')
        setUserRole(null)
        setUser(null)
      })
      .catch(handleAuthError)
  }

  return (
    <UserContext.Provider
      value={{
        currentUser,
        user,
        userRole,
        auth,
        onEditionMode,
        loadingUser,
        createUserWithEmailAndPassword,
        signInWithGoogle,
        signInWithEmailAndPassword,
        signInWithFacebook,
        changePassword,
        signOut,
        setOnEditionMode,
        updateUser,
        changeEmail,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
