import React, { useState, useEffect, useRef } from 'react'

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import dayjs, { Dayjs } from 'dayjs'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'

import { SectionSubtitle } from '../..'
import { DropdownCalendarProps } from '../../types'

import { DropdownCalendarContainer, CalendarContainer } from './styles'

const inputData = {
  type: 'date',
  minDate: dayjs().subtract(2, 'year'),
  maxDate: dayjs().add(2, 'months'),
  width: '100%',
}

const DropdownCalendar = ({
  dateSearch,
  setDateSearch,
  date,
  setDate,
}: DropdownCalendarProps) => {
  const [showCalendar, setShowCalendar] = useState(false)
  const calendarRef = useRef<HTMLDivElement>(null)

  const setDateInput = (date: Dayjs | null) => {
    const _date = date && date.format('YYYY-MM-DD')

    setDateSearch(_date)
    setDate(dayjs(date))
    setShowCalendar(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node)
      ) {
        setShowCalendar(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <DropdownCalendarContainer ref={calendarRef} $open={showCalendar}>
      <SectionSubtitle
        fontSize={12}
        fontWeight={400}
        classSub="dropdown-title"
        onClick={() => setShowCalendar(!showCalendar)}
      >
        <>
          {dateSearch ? dateSearch : 'Buscar por fecha'}

          {!showCalendar ? <RiArrowDownSLine /> : <RiArrowUpSLine />}
        </>
      </SectionSubtitle>
      {showCalendar && (
        <CalendarContainer>
          {/* <Search
						type={inputData.type}
						min={inputData.minDate}
						max={inputData.maxDate}
						width={inputData.width}
						searchValue={dateSearch}
						setSearchValue={setDateInput}
					/> */}
          <DateCalendar
            value={date}
            onChange={(date) => setDateInput(date)}
            disableFuture
            minDate={inputData.minDate}
            maxDate={inputData.maxDate}
          />
          <p onClick={() => setDateInput(dayjs())} className="today-button">
            HOY
          </p>
        </CalendarContainer>
      )}
    </DropdownCalendarContainer>
  )
}

export default DropdownCalendar
