import React from 'react'

import { SectionSubtitle } from '../../../../../texts'
import { ContentLink, DisplayWeekTasks } from '../styles'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DisplayWeekTask = ({ weekTasks }: { weekTasks: any }) => {
  return (
    <DisplayWeekTasks>
      <ContentLink>
        {weekTasks.map(
          (item: { id: number; taskLink: string; taskDescription: string }) => {
            return (
              <div className="week-task" key={item.id}>
                <SectionSubtitle fontSize={16} fontWeight={500}>
                  <>Tarea {item.id}</>
                </SectionSubtitle>

                <div className="week-task-content">
                  <SectionSubtitle fontSize={14} fontWeight={500}>
                    <>Archivo: {item.taskLink}</>
                  </SectionSubtitle>
                  <SectionSubtitle fontSize={14} fontWeight={500}>
                    <>Consigna: {item.taskDescription}</>
                  </SectionSubtitle>
                </div>
              </div>
            )
          },
        )}
      </ContentLink>
      <div className="space-16"></div>
    </DisplayWeekTasks>
  )
}

export default DisplayWeekTask
