import React from 'react'

import { Spacer } from '../../global'
import { Student, listHeads } from '../../types'
import { TableContainer } from '../styles'

const AdminDebtorsBonusList = ({
  studensList,
}: {
  studensList: Student[] | null
}) => {
  return (
    <TableContainer>
      <div className="t-row t-head bonus-list debt">
        {listHeads.debtors.map((item, i) => {
          return <div key={i}>{item}</div>
        })}
      </div>
      <Spacer height={10} />
      {studensList &&
        studensList.map((student, i) => {
          return (
            <div className="t-row bonus-list" key={i}>
              <div className="with-image">
                <div
                  className="profile-image"
                  style={{
                    backgroundImage: `url(${student.image ?? '/assets/profile.png'})`,
                  }}
                ></div>
                {student.name} {student.lastname}
              </div>
              <div>
                Debe:{' '}
                <span style={{ color: '#ec8f87', fontWeight: 600 }}>
                  {student.currentAttendance - student.currentAmountOfClasses}{' '}
                  clases
                </span>
              </div>
            </div>
          )
        })}
    </TableContainer>
  )
}

export default AdminDebtorsBonusList
