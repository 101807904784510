import { initializeApp } from 'firebase/app'
import { browserLocalPersistence, getAuth, setPersistence } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL:
    'https://db-digaah-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: 'G-08JFQQM7Q3',
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth()
export const firestore = getFirestore(app)

export const storage = getStorage(app)

// Configura la persistencia de autenticación
setPersistence(auth, browserLocalPersistence)
