export enum AdminMenu {
  admin = 'administración',
  student = 'alumnos',
  professors = 'profesores',
  bono = 'bono',
}

export interface AdminMenuItems {
  label: string
  description: string
}

export const AdminStudentMenu: Record<string, AdminMenuItems> = {
  bonos: { label: 'historial-de-bonos', description: 'Historial de Bonos' },
  courses: { label: 'historial-de-cursos', description: 'Historial de Cursos' },
  profile: { label: 'perfil', description: 'Perfil' },
}

export const AdminProfessorMenu: Record<string, AdminMenuItems> = {
  activity: {
    label: 'historial-actividades',
    description: 'Historial de Actividades',
  },
  shared: {
    label: 'procesos-compartidos',
    description: 'Procesos Compartidos',
  },
  profile: { label: 'perfil', description: 'Perfil' },
}

export const AdminBonusMenu = [
  'Bonos vencidos',
  'Bonos que le falten 1 clase',
  'Deudores',
  'Alumnos Inactivos',
]

export enum UserType {
  admin = 'Administrador',
  student = 'Alumno',
  professor = 'Profesor',
}

export enum AdminStudent {
  bonos = 'historial-de-bonos',
  courses = 'historial-de-cursos',
  profile = 'perfil',
}

export enum Experience {
  beginner = 'Principiante',
  intermediate = 'Intermedio',
  profesional = 'Profesional',
}

export const listHeads = {
  due: ['Nombre del Alumno', 'Bono activo', 'Asistencia', ' '],
  lastClass: [
    'Nombre del Alumno',
    'Clases totales',
    'Asistencia',
    'Vencimiento',
  ],
  debtors: ['Nombre del Alumno', 'Debe'],
  inactiveStudents: [
    'Nombre del Alumno',
    'Bono activo',
    'Asistencia',
    'Vencimiento',
    'Ultima Clase',
  ],
}

export const experticeMenu = [
  'Canto y técnica vocal​',
  'Técnica lírica',
  'Repertorio y estilo',
  'Técnica vocal | ejecución',
  'Bailarina',
  'Ejecución',
  'Rehabilitación vocal',
]

export const experienceMenu = [
  'Cantante',
  'Vocal coach',
  'Logopeda',
  'Actriz',
  'Bailarina',
]
