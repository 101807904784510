import styled from 'styled-components'

export const SelectComponent = styled.select`
  border-radius: 10px;
  border: 1px solid #fdfdfd;
  background: #fafafa;
  color: #8e92bc;
  padding: 10px 12px;
  width: 100%;
  color: #8e92bc;
  border: 1px solid #dbdbdb;
  outline: none;
`
