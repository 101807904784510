import { Navigate, Route, Routes } from 'react-router-dom'

import { AdministrationPage, InitialForm, ProfilePage } from '../pages'
import NewUser from '../pages/administration/NewUser'
import Professors from '../pages/administration/Professors'
import Students from '../pages/administration/Students'

export const PrivateRoutesAdmin = () => {
  return (
    <Routes>
      <Route index element={<AdministrationPage />} />

      <Route path="/" element={<AdministrationPage />}>
        <Route path="/alumnos" element={<Students />} />
        <Route path="/alumnos/:part" element={<Students />} />
        <Route path="/profesores" element={<Professors />} />
        <Route path="/profesores/:part" element={<Professors />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/nuevo-usuario" element={<NewUser />} />
      </Route>
      <Route path="/initial-form" element={<InitialForm />} />

      {/* Ruta por defecto para rutas desconocidas */}

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}
