import React from 'react'

import './index.css'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import ReactDOM from 'react-dom/client'
import 'dayjs/locale/es'

import App from './App'
import {
  CuponsProvider,
  ProfessorsProvider,
  StorageProvider,
  StudentsProvider,
  UserProvider,
} from './context'
import reportWebVitals from './reportWebVitals'

dayjs.locale('es')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <UserProvider>
      <StudentsProvider>
        <ProfessorsProvider>
          <StorageProvider>
            <CuponsProvider>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                dateLibInstance={dayjs}
                adapterLocale="es"
              >
                <App />
              </LocalizationProvider>
            </CuponsProvider>
          </StorageProvider>
        </ProfessorsProvider>
      </StudentsProvider>
    </UserProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
