import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import {
  DetailPerson,
  InlineMenuNoButton,
  SectionSubtitle,
  LightGreyButton,
  StuBonus,
  StuCourses,
  StuProfile,
  ListWithSearch,
} from '../../components'
import FilteringList from '../../components/dropdowns/dropdownList/FilteringList'
import { Spacer } from '../../components/global'
import { AdminStudentMenu } from '../../components/types'
import { Profesor, Student } from '../../components/types/types'
import { useCupons, useStudents, useUser } from '../../context'

import { AdminsContainer } from './styles'

const Students = () => {
  const navigate = useNavigate()
  const { part } = useParams<{ part: string }>()

  const { saveStudent, activeStudent, setActiveStudent, studentsList } =
    useStudents()

  const { cupons, setStudentCupons, studentCupons } = useCupons()

  const { onEditionMode, setOnEditionMode } = useUser()

  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredStudents, setFilteredStudents] = useState<Student[] | null>(
    null,
  )

  const selectStudent = (student: Profesor | Student) => {
    if (!onEditionMode && 'professors' in student) {
      setActiveStudent(student as Student)

      navigate(`/alumnos/${AdminStudentMenu.bonos.label}`)
    } else {
      setActiveStudent(student as Student)
    }
  }

  const updateProfile = () => {
    if (activeStudent) {
      saveStudent(activeStudent, activeStudent.uid)
      setOnEditionMode(!onEditionMode)
    }
  }

  useEffect(() => {
    if (activeStudent) {
      const filterCupons = cupons.filter(
        (cupon) => cupon.idStudent === activeStudent.uid,
      )
      setStudentCupons(filterCupons)
    }
  }, [activeStudent, cupons])

  useEffect(() => {
    setFilteredStudents(FilteringList(searchValue, studentsList))
  }, [searchValue])

  useEffect(() => {
    if (activeStudent === null && !onEditionMode && studentsList) {
      setActiveStudent(studentsList[0])
      navigate(`/alumnos/${AdminStudentMenu.bonos.label}`)
    }
  }, [])

  return (
    <AdminsContainer>
      <ListWithSearch
        type="alumno"
        searchValue={searchValue}
        filteredStudents={filteredStudents}
        setSearchValue={setSearchValue}
        selectStudent={selectStudent}
      />
      <div className="data">
        {activeStudent && (
          <>
            <InlineMenuNoButton
              parent="alumnos"
              menu={AdminStudentMenu}
              editing={onEditionMode}
            />
            {part !== AdminStudentMenu.profile.label && (
              <>
                <DetailPerson person={activeStudent} />
                <Spacer height={16} />
              </>
            )}

            <div className="section-title">
              <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={24}>
                <>
                  <Spacer height={16} />
                  {part === AdminStudentMenu.profile.label
                    ? 'Datos personales'
                    : part === AdminStudentMenu.courses.label
                      ? AdminStudentMenu.courses.description
                      : AdminStudentMenu.bonos.description}
                  <Spacer height={32} />
                </>
              </SectionSubtitle>

              {part === AdminStudentMenu.profile.label && (
                <LightGreyButton
                  fontSize={12}
                  padding="8px 20px"
                  fontWeight={400}
                  buttonClass={onEditionMode ? 'active-button' : ''}
                  onClick={updateProfile}
                >
                  {onEditionMode ? 'Guardar cambios' : 'Editar'}
                </LightGreyButton>
              )}
            </div>

            {part === AdminStudentMenu.bonos.label && studentCupons && (
              <StuBonus
                studentCupons={studentCupons}
                activeStudent={activeStudent}
              />
            )}
            {part === AdminStudentMenu.courses.label &&
              activeStudent.courses && <StuCourses />}

            {part === AdminStudentMenu.profile.label && (
              <StuProfile
                activeStudent={activeStudent}
                selectStudent={selectStudent}
              />
            )}
          </>
        )}
      </div>
    </AdminsContainer>
  )
}

export default Students
