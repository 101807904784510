import React, { Dispatch, SetStateAction, useState } from 'react'

import { BonusFilter, NewCourseForm } from '../../components'
import CarouselCourses from '../../components/Carousel/CarouselCourses/CarouselCourses'

const courses = [
  {
    title: 'Formación para vocal coaches | 1° generación',
    image: '/assets/logo.png',
  },
  {
    title: 'Formación para vocal coaches | 2° generación',
    image: '/assets/logo.png',
  },
  {
    title: 'Formación para vocal coaches | 3° generación',
    image: '/assets/logo.png',
  },
  {
    title: 'Formación para vocal coaches | 4° generación',
    image: '/assets/logo.png',
  },
  {
    title: 'Formación para vocal coaches | 5° generación',
    image: '/assets/logo.png',
  },
  {
    title: 'Formación para vocal coaches | 6° generación',
    image: '/assets/logo.png',
  },
]

const Admin = ({
  showCourseForm,
  setShowCourseForm,
}: {
  showCourseForm: boolean
  setShowCourseForm: Dispatch<SetStateAction<boolean>>
}) => {
  const [showCoursesDetails, setShowCoursesDetails] = useState(false)

  const displayCoursesEdition = () => {
    setShowCoursesDetails(!showCoursesDetails)
    setShowCourseForm(!showCourseForm)
  }

  return (
    <>
      <CarouselCourses
        items={courses}
        title={'Cursos activos'}
        type={'routine'}
        showCourseForm={showCourseForm}
        setShowCourseForm={setShowCourseForm}
        displayCoursesEdition={displayCoursesEdition}
        showCoursesDetails={showCoursesDetails}
      />
      {showCoursesDetails && <NewCourseForm />}
      <BonusFilter />
    </>
  )
}

export default Admin
