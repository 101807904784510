import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import { collection, deleteDoc, doc, setDoc } from 'firebase/firestore'

import { Student } from '../components/types'
import { firestore } from '../firebase/firebaseConfig'
import { subscribeToCollection, updateDocument } from '../firebase/utils'

interface StudentContextProps {
  studentsList: Student[] | null
  activeStudent: Student | null

  setActiveStudent: Dispatch<SetStateAction<Student | null>>
  setStudentsList: Dispatch<SetStateAction<Student[] | null>>
  createStudent: (newStudent: Student) => Promise<void>
  saveStudent: (updatedStudent: Student, studentId: string) => Promise<void>
  deleteStudent: (studentId: string) => Promise<void>
}

const StudentContext = createContext<StudentContextProps>({
  studentsList: null,
  activeStudent: null,

  setActiveStudent: () => Promise.resolve(null),
  setStudentsList: () => Promise.resolve(null),
  createStudent: () => Promise.resolve(),
  saveStudent: () => Promise.resolve(),
  deleteStudent: () => Promise.resolve(),
})

export const useStudents = () => useContext(StudentContext)

export const StudentsProvider = ({ children }: { children: ReactNode }) => {
  const [activeStudent, setActiveStudent] = useState<Student | null>(null)
  const [studentsList, setStudentsList] = useState<Student[] | null>(null)

  useEffect(() => {
    const unsubscribeStudents = subscribeToCollection<Student>(
      'students',
      setStudentsList,
    )

    return () => unsubscribeStudents()
  }, [])

  const createStudent = async (newStudent: Student) => {
    const newStudentRef = doc(collection(firestore, 'students'))

    await setDoc(newStudentRef, { ...newStudent, uid: newStudentRef.id })
  }

  const saveStudent = async (updatedStudent: Student, studentId: string) => {
    await updateDocument<Student>('students', studentId, updatedStudent)
  }

  const deleteStudent = async (studentId: string) => {
    await deleteDoc(doc(firestore, 'students', studentId))
  }

  return (
    <StudentContext.Provider
      value={{
        studentsList,
        activeStudent,
        setActiveStudent,
        setStudentsList,
        createStudent,
        saveStudent,
        deleteStudent,
      }}
    >
      {children}
    </StudentContext.Provider>
  )
}
