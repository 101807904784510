import styled from 'styled-components'

import '@fontsource/plus-jakarta-sans'

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ItemWithImage = styled.div<{ $hasSeparation?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 24px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.$hasSeparation ? '1px solid #f6f6f6' : ''};

  .name {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #8e92bc;
    margin-left: 8px;
  }

  &.disabled {
    cursor: no-drop;
  }

  &.active {
    background: #f6f6f6;
    border-bottom: none;

    .name {
      color: #0a0a18;
    }
  }
`

export const Photo = styled.div<{ $image: string }>`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-position: center;
  background-size: cover;
  background-image: ${(props) => `url(${props.$image})`};
`

export const CuponsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const CuponButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  .button {
    background-color: #f6f6f6;
    cursor: pointer;
    border-radius: 10px;
    padding: 8px 16px;
    color: #8e92bc;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.12px;

    &.active {
      color: #313131;
    }
  }
`

export const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 18px;
  border-bottom: 1px solid #dbdbdb;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
`

export const CuponContainer = styled.div<{ click: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  cursor: ${(props: { click: string }) => props.click && props.click};
  line-height: 15.12px;

  .general {
    color: #313131;
  }
  .price {
    color: #8e92bc;
    span {
      color: #313131;
    }
  }
  .payed {
    color: #ec8f87;
  }

  .teacher {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;

    .photo {
      background-size: cover;
      background-position: center;
      border-radius: 50%;
      height: 24px;
      width: 24px;
    }
  }
  .weeks {
    color: #08a8d2;
  }

  .center {
    text-align: center;
  }
`
