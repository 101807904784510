import React, { useState } from 'react'

import Spinner from 'react-bootstrap/esm/Spinner'
import { FcGoogle } from 'react-icons/fc'
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri'
import { Link, useLocation } from 'react-router-dom'

import {
  Input,
  DarkGreyButton,
  LightGreyButton,
  SectionSubtitle,
} from '../../components'
import { Spacer } from '../../components/global'
import { useUser } from '../../context'

import { FormularioInicio, FormularioRectangulo, TitleForm } from './styles'

const Login = () => {
  const location = useLocation()
  const userType = location.pathname.split('-')[1] ?? 'student'

  const { signInWithGoogle, signInWithEmailAndPassword } = useUser()

  const [formLogin, setFormLogin] = useState({
    email: '',
    password: '',
  })
  const [validEmail, setValidEmail] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const onClickIniciarSesion = async () => {
    setIsLoading(true)
    try {
      await signInWithEmailAndPassword(
        formLogin.email,
        formLogin.password,
        userType,
      )
    } catch (FirebaseError) {
      console.error('Error signing in with Google:', error)
      setError(error)
    }
    setIsLoading(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    if (name === 'email') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      if (emailPattern.test(value)) {
        setValidEmail(true)
      } else {
        setValidEmail(false)
      }
    }
    setFormLogin((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const onClickwithGmail = async () => {
    try {
      await signInWithGoogle(userType)
    } catch (FirebaseError) {
      setError(error)
      console.error('Error signing in with Google:', error)
    }
  }

  return (
    <FormularioInicio>
      <div className="holaSvg" />
      {error ? (
        <div className="error-message">{error}</div>
      ) : isLoading ? (
        <Spinner />
      ) : (
        <>
          <TitleForm>Hola!</TitleForm>
          <FormularioRectangulo>
            <SectionSubtitle fontSize={18} fontWeight={500}>
              Iniciar sesión
            </SectionSubtitle>
            <Spacer height={30} />

            <div className="contentInputs">
              <div className="containerInput">
                <SectionSubtitle fontSize={14} fontWeight={500}>
                  Usuario
                </SectionSubtitle>
                <Spacer height={5} />

                <div>
                  <Input
                    id="userLogin"
                    name="email"
                    placeholder="Nombre de usuario o correo"
                    onChange={handleChange}
                  />
                </div>
                {!validEmail && formLogin.email && (
                  <p className="messageError">El email no es valido.</p>
                )}
              </div>
              <Spacer height={10} />
              <div className="containerInputPassword">
                <SectionSubtitle fontSize={14} fontWeight={500}>
                  Contraseña
                </SectionSubtitle>
                <Spacer height={5} />
                <div className="svg-eye">
                  <Input
                    id="passwordLogin"
                    name="password"
                    placeholder="Contraseña"
                    onChange={handleChange}
                    type={showPassword ? 'text' : 'password'}
                  />
                  <div className="pass-icons">
                    {!showPassword ? (
                      <RiEyeCloseLine
                        color={'#8E92BC'}
                        size={'22'}
                        onClick={() => setShowPassword(true)}
                      />
                    ) : (
                      <RiEyeLine
                        color={'#8E92BC'}
                        size={'22'}
                        onClick={() => setShowPassword(false)}
                      />
                    )}
                  </div>
                </div>
                <Link to="/recoverpassword" className="link-forgot-password">
                  Olvidé mi contraseña
                </Link>

                {/* <div className="container-checkbox">
                    <input
                      type="checkbox"
                      id="miCheckbox"
                      name="miCheckbox"
                      className="checkbox-input"
                    />
                    <label>Recuerdame</label>
                  </div> */}
              </div>
            </div>
            <DarkGreyButton
              onClick={onClickIniciarSesion}
              width={200}
              isDisabled={!validEmail || formLogin.password.length < 5}
            >
              Iniciar Sesión
            </DarkGreyButton>
            <Spacer height={5} />
            <p className="other-option">
              No tienes una cuenta? <Link to="/sign-in">Registrate</Link>
            </p>
            <Spacer height={25} />
            <LightGreyButton
              onClick={onClickwithGmail}
              children={
                <>
                  <FcGoogle style={{ fontSize: 22, marginRight: 15 }} />

                  <p>Iniciar sesión con google</p>
                </>
              }
              fontSize={14}
              fontWeight={400}
              padding="15px 20px"
            />

            {/* <div className="content-buttons">
              <LightGreyButton
                onClick={onClickwithGmail}
                children={
                  <>
                    <div className="svg-google" />
                    <p>Continuar con Gmail</p>
                  </>
                }
                fontSize={14}
                buttonClass="button-gmail"
              />

              <LightBlueButton
                onClick={onClickwithGmail}
                children={
                  <>
                    <div className="svg-facebook" />
                    <p>Continuar con Facebook</p>
                  </>
                }
                fontSize={14}
                buttonClass="button-facebook"
              />
            </div> */}
          </FormularioRectangulo>
        </>
      )}
    </FormularioInicio>
  )
}

export default Login
