import styled from 'styled-components'

export const ModalOverlay = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 999;
`

export const ModalContent = styled.div`
  background: #ffffff;
  padding: 20px;
  margin: 15px auto;
  border-radius: 8px;
  box-sizing: border-box;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  position: relative;
`

export const ModalClose = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
`
