import styled from 'styled-components'

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 24px;

  .row {
    display: flex;
    width: 100%;
    align-items: stretch;
    gap: 52px;

    &.info {
      div {
        width: fit-content;
        text-align: left;
      }
    }
  }

  .active-activity {
    color: #0a0a18;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
    border-radius: 10px;
    background: #f8f8f8;
    border: 1px solid #f8f8f8;
    text-decoration: none;
    padding: 10px 20px;
    gap: 3px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border: 1px solid #08a8d2;
    background: #bdddeb;
    &:hover,
    &:focus {
      border: 1px solid #08a8d2;
      background: #bdddeb;
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .active {
      border: 0.5px solid #08a8d2;
      background: #bdddeb;
    }
  }

  .dropbox-link {
    text-align: left;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding: 8px 12px;
    max-width: 317px;

    a {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.24px;
      text-decoration-line: underline;
    }
  }

  .change-credentials-button {
    border: 1px solid #08a8d2;
    padding: 15px 25px;
    font-weight: 500;
  }

  .save-profile-button {
    font-size: 16px;
    padding: 12px 25px;
    height: auto;
  }
`

export const RowInformation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
`

export const ImageContainer = styled.div`
  width: 190px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const InputsContainer = styled.div`
  width: calc(100% - 300px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;

  .input-container {
    width: calc(50% - 20px);
    padding-top: 10px;

    .profile-input {
      color: #8e92bc;
      background: transparent;
      border: 1px solid #dbdbdb;

      &:disabled {
        color: #0a0a18;
        background: #f6f6f6;
        border: none;
      }
    }
  }
`

export const ChangeCredentialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 92px;
  justify-content: flex-start;
`

export const ChangePasswordContainer = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;

  .content-password {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;

    label {
      width: 100%;
      position: relative;
      align-items: center;
      display: flex;

      .password-button {
        position: absolute;
        right: 10px;
      }
    }
    svg {
      margin-left: 10px;
      cursor: pointer;
      width: 22px;
    }
  }

  .error {
    margin-top: 20px;
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
`

export const ChangeEmailContainer = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
`
