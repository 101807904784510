import styled from 'styled-components'

import '@fontsource/plus-jakarta-sans'

export const CardsContainer = styled.div<{ $showCoursesDetails?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.$showCoursesDetails ? '0px' : '20px')};
`

export const CardContainer = styled.div<{ $showCoursesDetails?: boolean }>`
  width: 286px;
  height: fit-content;
  padding: ${(props) =>
    props.$showCoursesDetails ? '8px 8px 15px 8px' : '8px 8px 31px 8px'};
  border-radius: 10px;
  background: #f6f6f6;

  margin-right: 16px;
  position: relative;
`

export const CardCouchDescription = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 22.5px;
`

export const ImageRutine = styled.div`
  width: 270px;
  height: 94px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin-bottom: 8px;
`

export const ImageCoach = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`

export const CardRutineText = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 10px;

  .title {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
    color: #0a0a18;
  }

  .description {
    margin-top: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    letter-spacing: -0.02em;
    color: #54577a;
  }
`

export const CardCoachText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #030410;
  }
  .description {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #54577a;
  }
`

export const CoachBio = styled.div`
  display: flex;
  flex-direction: row;

  img {
    height: 24px;
    margin-right: 8px;
  }

  .bio-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #030410;
  }
`

export const CarouselButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    color: #030410;
  }

  .carousel-arrows {
    position: relative;
    color: #030410;
    width: 80px;
    height: 24px;
    display: flex;
    align-items: center;

    button {
      width: 24px;
      height: 24px;
      border: none;
      display: flex;
      align-items: center;
      background: transparent;
      margin: 0 14px;
      cursor: pointer;

      &:focus {
        outline: none;
      }
      span {
        color: #030410;
        width: 25px;
        height: 24px;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`

export const CardEdition = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .editionButton {
    cursor: pointer;
    padding-right: 16px;
    &:last-child {
      padding-right: 0;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
`
