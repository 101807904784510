import React from 'react'

import { DarkGreyButtonProps } from '../types'

import { DarkGreyButtonContainer } from './styles'

const DarkGreyButton = ({
  onClick,
  children,
  width,
  isDisabled,
  buttonClass,
  type,
  height,
}: DarkGreyButtonProps) => {
  return (
    <DarkGreyButtonContainer
      type={type}
      disabled={isDisabled}
      width={width}
      height={height}
      onClick={onClick}
      className={buttonClass}
    >
      {children}
    </DarkGreyButtonContainer>
  )
}
export default DarkGreyButton
