import styled from 'styled-components'

import '@fontsource/plus-jakarta-sans'

export const ContentSectionCourses = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: 500px) {
    justify-content: center;
  }
`

export const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  border-radius: 10px;
  border: 0.5px solid #08a8d2;
  background: #fdfdfd;
  box-shadow: 0px 4px 8px 0px rgba(142, 146, 188, 0.05);
  margin-right: 24px;

  @media (max-width: 768px) {
    margin-right: 0px;
    margin-bottom: 24px;
    width: 100%;
  }
`
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 140px;
  border-radius: 10px;
  background: #f8f8f8;
  padding: 8px 8px 38px;
  @media (max-width: 768px) {
    width: 100%;
    height: 140px;
    border-radius: 10px;
    padding: 8px 8px 38px;
  }
`

export const ContentImage = styled.div`
  width: 270px;
  height: 94px;
  flex-shrink: 0;
  border-radius: 10px;
  background: url('/assets/girl-sing.png') lightgray 50% / cover no-repeat;
  @media (max-width: 500px) {
    width: 100%;
  }
`

export const ContentTitle = styled.div`
  display: flex;
  width: 270px;
  height: 27px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  margin-top: 8px;

  p {
    text-align: left;
    color: #0a0a18;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
  }
`

export const ContainerInfo = styled.div`
  display: flex;
  width: 100%;
  margin-top: 13px;
  @media (max-width: 500px) {
    margin-left: 8px;
  }
`
export const ContentInfoQuotes = styled.div`
  display: flex;
  width: 90px;
  padding: 4px 8px;
  margin-right: 8px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  background: #ffdc50;

  p {
    color: #313131;
    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
    span {
      color: #8e92bc;
    }
  }
`
