import styled from 'styled-components'
import '@fontsource/plus-jakarta-sans'

export const AdminsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;

  .list {
    width: 300px;
    background-color: #fdfdfd;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: calc(100vh - 240px);
    overflow-y: scroll;
    scrollbar-color: #bdddeb white;
    scrollbar-width: thin;

    @media screen and (max-width: 1024px) {
      width: 220px;
    }
  }

  .data {
    padding: 8px 24px;
    background-color: #fdfdfd;
    width: calc(100% - 300px);
    display: flex;
    flex-direction: column;
    overflow: scroll;
    height: calc(100vh - 240px);
    scrollbar-color: #bdddeb white;
    scrollbar-width: thin;
    border-radius: 10px;

    @media screen and (max-width: 1024px) {
      width: calc(100% - 220px);
    }
  }

  .professors-searchs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;

    .disable {
      color: #8e92bc;
    }
  }

  .section-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .active-button {
      background-color: #c6c6c6;
    }
  }
`

export const NewUserButton = styled.div<{ $hasSeparation: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 24px;
  gap: 12px;
  width: 100%;
  border-bottom: ${(props) =>
    props.$hasSeparation ? '1px solid #f6f6f6' : ''};

  button {
    background: #f6f6f6;
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 14px;
    }

    &:focus {
      outline: none;
    }
  }

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.28px;
    color: #8e92bc;
  }
`
export const NewUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 24px;

  .row {
    display: flex;
    width: 100%;
    align-items: stretch;
    gap: 52px;

    &.info {
      div {
        width: fit-content;
        text-align: left;
      }
    }
  }

  .active-activity {
    color: #0a0a18;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
    border-radius: 10px;
    background: #f8f8f8;
    border: 1px solid #f8f8f8;
    text-decoration: none;
    padding: 10px 20px;
    gap: 3px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border: 1px solid #08a8d2;
    background: #bdddeb;
    &:hover,
    &:focus {
      border: 1px solid #08a8d2;
      background: #bdddeb;
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;
    gap: 8px;

    button.active {
      border: 1px solid #08a8d2;
      background: #bdddeb;
    }
  }

  .dropbox-link {
    text-align: left;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding: 8px 12px;
    max-width: 317px;

    a {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.24px;
      text-decoration-line: underline;
    }
  }
`

export const RowInformation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px 50px;

  div {
    width: 30%;

    input:disabled {
      color: #8e92bc;
    }
  }
`
