import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { DarkGreyButton, Spinner } from '../../components'
import ProfileNewUser from '../../components/AdminNewUser/ProfileNewUser'
import { Section } from '../../components/ContainerSection'
import { Spacer } from '../../components/global'
import ErrorNewUser from '../../components/modal/errorNewUser'
import { AdminNewUser } from '../../components/types'
import { createUser } from '../../firebase/utils'

const INITIAL_STATE = {
  name: '',
  lastname: '',
  phone: '',
  birthDate: '',
  email: '',
  genre: '',
  title: '',
  activities: [],
  experienceType: [],
  image: '',
  role: '',
  yearsTeaching: 0,
  yearsOfExperience: 0,
  yearsSinging: 0,
  yearsOfMethodology: 0,
  biography: '',
  professors: [],
  dropboxLink: '',
  password: '',
  confirmPassword: '',
}

const NewUser = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const userParam = queryParams.get('user')

  const [newProfile, setNewProfile] = useState<AdminNewUser>(INITIAL_STATE)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorModal, setErrorModal] = useState<boolean>(true)

  const resetState = () => {
    setNewProfile(INITIAL_STATE)
  }

  const onCreateNewUser = async () => {
    if (
      newProfile.password !== newProfile.confirmPassword ||
      newProfile.email === ''
    ) {
      return
    }

    await createUser(
      newProfile,
      setIsLoading,
      setError,
      newProfile.role,
      {},
      resetState,
    )
  }

  useEffect(() => {
    if (userParam) {
      setNewProfile({ ...newProfile, role: userParam })
    }
  }, [userParam])

  useEffect(() => {
    if (error) {
      setErrorModal(true)
    }
  }, [error])

  return (
    <>
      {errorModal && error && (
        <ErrorNewUser
          errorMessage={error}
          setOpenModal={setErrorModal}
          setError={setError}
        />
      )}

      <Section title="Nuevo usuario" childrenClass="content-childrens">
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Spinner />
          </div>
        ) : (
          <div>
            <ProfileNewUser
              type="new-user"
              editedProfile={newProfile}
              setEditedProfile={setNewProfile}
            />
            <Spacer height={30} />
            <div
              style={{
                width: '100%',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <DarkGreyButton
                onClick={onCreateNewUser}
                isDisabled={
                  newProfile.name.length === 0 ||
                  newProfile.lastname.length === 0 ||
                  newProfile.phone.length === 0 ||
                  newProfile.birthDate.length === 0 ||
                  newProfile.password.length === 0 ||
                  newProfile.password !== newProfile.confirmPassword ||
                  newProfile.email.length === 0
                }
              >
                CREAR USUARIO
              </DarkGreyButton>
            </div>
          </div>
        )}
      </Section>
    </>
  )
}

export default NewUser
