import styled from 'styled-components'

export const CalendarContainer = styled.div`
  width: 256px;
  border-radius: 8px;
  background-color: #fdfdfd;
  padding-bottom: 36px;
`

export const CalendarHeaderYear = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
`

export const CalendarRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const CalendarBodyMonths = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 32px);
  grid-template-rows: repeat(3, 32px);
  justify-items: center;
  align-items: center;
  gap: 5px 24px;
  padding: 0 24px;

  div {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.blocked {
      background-color: #e5e5e5;
      border-radius: 50%;
      cursor: default;
    }

    &.active {
      background-color: #08a8d2;
      color: #ffffff;
      border-radius: 50%;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
`

export const CalendarDisclaimer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;

    svg {
      font-size: 32px;
    }

    p {
      font-size: 12px;
      width: 100px;
      text-align: left;
    }
  }
`

export const DateCalendarContainer = styled.div<{ $fullWidth?: boolean }>`
  width: ${(props) => (props.$fullWidth ? '100%' : '300px')};

  .MuiFormControl-root {
    width: ${(props) => (props.$fullWidth ? '100%' : '300px')};
  }

  .MuiInputBase-root {
    color: #8e92bc;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    border-radius: 14px;

    .MuiInputBase-input {
      padding: 10px 14px;

      &.Mui-disabled {
        color: #0a0a18;
      }
    }

    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #dbdbdb;

      &.Mui-focused {
        border-color: #dbdbdb;
        border-width: 1px;
      }
    }

    &.Mui-disabled {
      color: #0a0a18;
      background: #f6f6f6;
      border: none;

      fieldset.MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  }
`
