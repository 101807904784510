import React, { ChangeEvent, useEffect, useState } from 'react'

import dayjs, { Dayjs } from 'dayjs'
import { GrClose } from 'react-icons/gr'

import { DateCalendar, Select } from '..'
import { useCupons, useProfessors, useStudents } from '../../context'
import { DarkGreyButton } from '../buttons'
import { Spacer } from '../global'
import { BonoModalProps, NewCupon } from '../types'

import Classes from './Classes'
import PriceRow from './PriceRow'
import { ModalBackground, Modal, TitleRow, ModalRow, ButtonRow } from './styles'

const INITIAL_STATE: NewCupon = {
  idStudent: '',
  amountOfClasses: 0,
  price: 0,
  initDate: '',
  dueDate: '',
  attendance: 0,
  payed: false,
  teacher: '',
}
const BonoModal = ({ modalStatus, setModalStatus, info }: BonoModalProps) => {
  const { studentsList } = useStudents()
  const { createCupon, error } = useCupons()
  const { professorsList } = useProfessors()

  const [newCupon, setNewCupon] = useState<NewCupon>(INITIAL_STATE)
  const [studentWithActiveCupon, setStudentWidthActiveCupon] = useState(false)

  const handleChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { name, value } = e.target

    setNewCupon({
      ...newCupon,
      [name]: value,
    })

    if (name === 'idStudent') {
      studentsList?.map((student) => {
        if (student.uid === value && student.hasActiveBonus) {
          setStudentWidthActiveCupon(true)
        }
      })
    }
  }

  const handleMenuChange = (filterName: string, value: number | boolean) => {
    setNewCupon({
      ...newCupon,
      [filterName]: value,
    })
  }

  const handleDate = (date: Dayjs | null | string) => {
    if (date) {
      if (newCupon.amountOfClasses !== 1) {
        const endDate = dayjs(date).add(30, 'day')

        setNewCupon({
          ...newCupon,
          initDate: dayjs(date).format('YYYY-MM-DD'),
          dueDate: dayjs(endDate).format('YYYY-MM-DD'),
        })
      } else {
        setNewCupon({
          ...newCupon,
          initDate: dayjs(date).format('YYYY-MM-DD'),
          dueDate: dayjs(date).format('YYYY-MM-DD'),
        })
      }
    }
  }

  useEffect(() => {
    setNewCupon({ ...newCupon, initDate: '', dueDate: '' })
  }, [newCupon.amountOfClasses])

  const handleSave = () => {
    if (
      newCupon.idStudent !== '' &&
      newCupon.amountOfClasses !== 0 &&
      newCupon.price !== 0 &&
      newCupon.initDate !== '' &&
      newCupon.dueDate !== ''
    ) {
      createCupon(newCupon)

      if (!error) {
        setNewCupon(INITIAL_STATE)
      }
    }
  }

  return (
    <ModalBackground>
      <Modal>
        <TitleRow>
          <div className="title">{info ? 'Editar Bono' : 'Agregar Bono'}</div>
          <div
            className="close"
            onClick={() => {
              setModalStatus(!modalStatus)
            }}
          >
            <GrClose />
          </div>
        </TitleRow>
        {studentsList && (
          <ModalRow>
            <Spacer height={20} />
            <div className="title">Alumno</div>
            <Spacer height={13} />

            <Select
              list={studentsList}
              value={newCupon.idStudent}
              name="idStudent"
              defaultValue="Selecciona un Alumno"
              handleSelectChange={handleChange}
              showActiveBonus={true}
            />
          </ModalRow>
        )}
        <ModalRow>
          <div className="title">Tipo de Bono</div>
          <div className="menu">
            <Classes
              amountOfClasses={newCupon.amountOfClasses}
              handleMenuChange={handleMenuChange}
              studentHasActiveBonus={studentWithActiveCupon}
            />
          </div>
        </ModalRow>
        {newCupon.amountOfClasses === 1 && professorsList && (
          <ModalRow>
            <div className="title">Selecciona un profesor</div>
            <Spacer height={13} />
            <Select
              list={professorsList}
              value={newCupon.teacher}
              name="teacher"
              defaultValue="Selecciona un profesor"
              handleSelectChange={handleChange}
            />
          </ModalRow>
        )}
        <ModalRow className="price-row">
          <PriceRow
            price={newCupon.price}
            hasPayed={newCupon.payed}
            handleInputChange={handleChange}
            handleMenuChange={handleMenuChange}
          />
        </ModalRow>
        <ModalRow>
          <div className="title">Fecha de inicio</div>
          <Spacer height={18} />
          <div className="date-row">
            <DateCalendar
              referenceDate={newCupon.initDate}
              handleDate={handleDate}
              defaultValue={dayjs()}
              fullWidth={false}
            />

            {newCupon?.dueDate && (
              <div className="disclaimer">
                VENCE {dayjs(newCupon.dueDate).format('DD-MM-YYYY')}{' '}
              </div>
            )}
          </div>
          {newCupon.amountOfClasses > 1 && (
            <div className="subtitle">Duración 30 días desde el inicio.</div>
          )}
        </ModalRow>
        <ButtonRow>
          <DarkGreyButton
            isDisabled={
              newCupon.idStudent === '' ||
              newCupon.amountOfClasses === 0 ||
              newCupon.price === 0 ||
              newCupon.initDate === '' ||
              newCupon.dueDate === '' ||
              (newCupon.amountOfClasses === 1 && newCupon.teacher === '')
            }
            onClick={handleSave}
          >
            Guardar
          </DarkGreyButton>
        </ButtonRow>
      </Modal>
    </ModalBackground>
  )
}

export default BonoModal
