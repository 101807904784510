import React, { useState, ChangeEvent, useEffect } from 'react'

import dayjs, { Dayjs } from 'dayjs'
import { RiEdit2Line, RiSaveLine, RiDeleteBin5Line } from 'react-icons/ri'

import {
  useCupons,
  useProfessors,
  useStudents,
  useUser,
} from '../../../context'
import DateCalendar from '../../calendars/DateCalendar'
import { Spacer } from '../../global'
import { transformDate } from '../../helpers/functions'
import { Input } from '../../inputs'
import { Cupon } from '../../types'
import { TableContainer } from '../styles'

const ActiveBonus = ({
  couponList,
  hasActive,
}: {
  couponList: Cupon[]
  hasActive: boolean
}) => {
  const { onEditionMode, setOnEditionMode } = useUser()
  const { activeCupon, setActiveCupon, saveCupon, deleteCupon } = useCupons()
  const { professorsList } = useProfessors()
  const { activeStudent } = useStudents()

  const [activeCupons, setActiveCupons] = useState<Cupon[] | null>(null)
  const [activeItem, setActiveItem] = useState<string | null>(null)
  const [hasActiveCupons, setHasActiveCupons] = useState<boolean>(hasActive)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    activeCupon &&
      setActiveCupon({
        ...activeCupon,
        [name]: Number(value),
      })
  }

  const handleDateChange = (date: Dayjs | null) => {
    activeCupon &&
      setActiveCupon({
        ...activeCupon,
        dueDate: dayjs(date).format('YYYY-MM-DD'),
      })
  }

  const handleSubmit = (cuponId: string) => {
    setOnEditionMode(false)

    if (activeCupon) {
      saveCupon(activeCupon, cuponId, hasActiveCupons)
    }
  }

  const filterProfessor = (id: string) => {
    const _professor =
      professorsList && professorsList.find((professor) => professor.uid === id)
    const name = _professor?.name || ''
    const lastname = _professor?.lastname || ''
    return `${name} ${lastname}`
  }

  const handleDelete = (couponId: string) => {
    if (activeStudent) {
      deleteCupon(couponId, activeStudent.uid)
    }
  }

  const filterByActive = () => {
    const currentDate = dayjs()

    const filteredCoupons = couponList.filter((coupon) => {
      const couponDate = dayjs(coupon.dueDate)
      return (
        couponDate.isSame(currentDate, 'day') ||
        (couponDate.isAfter(currentDate, 'day') &&
          coupon.amountOfClasses > coupon.attendance)
      )
    })

    setActiveCupons(filteredCoupons)
  }

  useEffect(() => {
    filterByActive()
  }, [couponList])

  useEffect(() => {
    if (activeCupon) {
      if (
        dayjs(activeCupon.dueDate).isBefore(dayjs()) ||
        activeCupon.amountOfClasses === activeCupon.attendance
      ) {
        setHasActiveCupons(false)
      } else {
        setHasActiveCupons(true)
      }
    }
  }, [activeCupon])

  return (
    activeCupons && (
      <TableContainer>
        <Spacer height={18} />
        <div className="t-row t-head coupon">
          <div>Clases</div>
          <div>Precio</div>
          <div>Pago</div>
          <div>Vencimiento</div>
          <div>Profesor</div>
          <div>Asistencia</div>
          <div></div>
        </div>
        {activeCupons.map((coupon, i) => {
          return (
            <div
              className={`t-row coupon ${activeItem === coupon.uid && 'active'}`}
              onClick={() => setActiveItem(coupon.uid)}
              key={i}
            >
              <div>{coupon.amountOfClasses} Clases</div>
              <div>{coupon.price}</div>
              <div>{coupon.payed ? 'SI' : 'NO'}</div>
              <div className="input-date">
                {onEditionMode && activeCupon?.uid === coupon.uid ? (
                  <DateCalendar
                    referenceDate={activeCupon.dueDate}
                    defaultValue={dayjs(coupon.dueDate)}
                    handleDate={handleDateChange}
                  />
                ) : (
                  <>{transformDate(coupon.dueDate)}</>
                )}
              </div>

              <div className="teacher">
                {coupon.teacher && filterProfessor(coupon.teacher)}
              </div>

              <div className="general">
                {onEditionMode && activeCupon?.uid === coupon.uid ? (
                  <Input
                    type="number"
                    width="80%"
                    name="attendance"
                    id="attendance"
                    defaultValue={coupon.attendance}
                    inputClass="edit-bonus-input"
                    onChange={handleChange}
                  />
                ) : (
                  coupon.attendance
                )}
              </div>

              <div className="t-actions">
                {onEditionMode && activeCupon?.uid === coupon.uid ? (
                  <div onClick={() => handleSubmit(coupon.uid)}>
                    <RiSaveLine />
                  </div>
                ) : (
                  <div
                    className="t-actions-edit"
                    onClick={() => {
                      setOnEditionMode(true)
                      setActiveCupon(coupon)
                    }}
                  >
                    <RiEdit2Line />
                  </div>
                )}

                <div
                  className="t-actions-delete"
                  onClick={() => handleDelete(coupon.uid)}
                >
                  <RiDeleteBin5Line />
                </div>
              </div>
            </div>
          )
        })}
        <Spacer height={26} />
      </TableContainer>
    )
  )
}

export default ActiveBonus
