import styled from 'styled-components'
import '@fontsource/plus-jakarta-sans'

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`

export const Modal = styled.div`
  width: 543px;
  background-color: #f6f6f6;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 0px rgba(84, 87, 122, 0.1);
`

export const TitleRow = styled.div`
  padding: 22px 31px 30px 46px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 0px 4px 0px rgba(142, 146, 188, 0.1);
  margin-bottom: 13px;

  .title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.4px;
    align-self: flex-end;
  }

  .close {
    cursor: pointer;
  }
  svg {
    font-size: 18px;
  }
`
export const ModalRow = styled.div`
  padding: 0 24px 30px 24px;
  display: flex;
  flex-direction: column;
  color: #0a0a18;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
  justify-content: flex-start;
  text-align: left;

  &.price-row {
    flex-direction: row;
    gap: 50px;
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.28px;
  }

  .menu {
    display: inline-flex;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 30px;
    margin-top: 18px;

    .menu-item {
      color: #0a0a18;
      border-radius: 10px;
      background: #f8f8f8;
      border: 1px solid #f8f8f8;
      text-decoration: none;
      padding: 10px 20px;
      gap: 3px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      &.active {
        border: 1px solid #08a8d2;
        background: #bdddeb;
      }
      &:hover,
      &:focus {
        border: 1px solid #08a8d2;
        background: #bdddeb;
      }
    }
  }

  .price {
    margin-top: 8px;
    border-radius: 10px;
    border: 1px solid #f6f6f6;
    background: #fdfdfd;
    color: #8e92bc;
    margin-bottom: 38px;
    width: 104px;
    padding: 8px 12px;
  }

  .subtitle {
    margin-top: 8px;
    margin-top: 13px;
    color: #8e92bc;
    margin-bottom: 8px;
  }

  /* .container-date {
    padding: 8px 26px;
    border-radius: 10px;
    border: 1px solid #fdfdfd;
    background: #fdfdfd;
    width: 256px;
    display: flex;
    justify-content: space-between;
    color: #8e92bc;
    align-items: center;

    p {
      margin: 0;
    }
  } */
  /* .date {
    color: #8e92bc;
    width: 106px;
    border: none;
    width: 256px;
  } */

  .date-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .disclaimer {
    color: #ec8f87;
    font-size: 14px;
    width: calc(100% - 256px);
  }
`

export const ButtonRow = styled.div`
  padding: 18px 46px;
  box-shadow: 2px 0px 4px 0px rgba(142, 146, 188, 0.1);

  .button {
    display: flex;
    width: 162px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #313131;
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.28px;
  }
`
