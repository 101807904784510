import React from 'react'

import { AiOutlinePlus, AiOutlineLine } from 'react-icons/ai'

import { CardRutine } from '../../../components'
import { LightGreyButton } from '../../buttons'
import { CarrouselProps } from '../../types'
import {
  CarouselContainer,
  CarouselTitle,
  CoursesContainer,
  PlusButton,
} from '../styles'

const CarouselCourses = ({
  title,
  items,
  setShowCourseForm,
  showCoursesDetails,
  displayCoursesEdition,
}: CarrouselProps) => {
  return (
    <CarouselContainer>
      <CarouselTitle>
        <div className="title">{title}</div>
      </CarouselTitle>

      <div className="d-flex align-items-center">
        <CoursesContainer $showCoursesDetails={showCoursesDetails}>
          {items.map((item: CarrouselProps['items'], i: number) => {
            return (
              <CardRutine
                image={item.image}
                title={item.title}
                subtitle={item.subtitle}
                showCoursesDetails={showCoursesDetails}
                key={i}
              />
            )
          })}
        </CoursesContainer>
        <PlusButton onClick={() => displayCoursesEdition()}>
          {!showCoursesDetails ? <AiOutlinePlus /> : <AiOutlineLine />}
        </PlusButton>
      </div>
      {showCoursesDetails && (
        <div>
          <LightGreyButton
            fontSize={12}
            fontWeight={400}
            onClick={() => setShowCourseForm(true)}
          >
            <AiOutlinePlus className="icon-plus" /> Agregar Curso
          </LightGreyButton>
        </div>
      )}
    </CarouselContainer>
  )
}

export default CarouselCourses
