import { CourseProps } from '../../components/types'

export const courses: CourseProps[] = [
  {
    id: 1,
    title: 'Desarollo de la voz',
    duration: 2,
    inizializationDate: new Date('2023-12-25').toString(),
    quotes: [
      { amount: 200, quantity: 7, quoteNumber: 1 },
      { amount: 200, quantity: 7, quoteNumber: 2 },
      { amount: 200, quantity: 7, quoteNumber: 3 },
      { amount: 200, quantity: 7, quoteNumber: 4 },
    ],
    payments: [
      { totalQuotas: 1, totalPrice: 1000 },
      { totalQuotas: 3, totalPrice: 333 },
      { totalQuotas: 7, totalPrice: 120 },
    ],
    price: 0.0,
    description:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum ipsum odit laborum tenetur ratione cupiditate laboriosam, quasi totam, expedita magni porro eos. Maiores magnam sunt eos facilis suscipit numquam explicabo.',
    studentsIds: [{ id: 1 }, { id: 3 }, { id: 4 }],
    professorsIds: [{ id: 2 }],
    material: [
      {
        id: 1,
        title: 'titulo clase',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: false,
        isActive: false,
      },
      {
        id: 2,
        title: 'titulo clase dos',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: false,
        isActive: false,
      },
      {
        id: 3,
        title: 'titulo clase tres',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: false,
        isActive: false,
      },
      {
        id: 4,
        title: 'titulo clase 4',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: false,
        isActive: false,
      },
      {
        id: 5,
        title: 'titulo clase 5',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: false,
        isActive: false,
      },
      {
        id: 6,
        title: 'titulo clase 6',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: true,
        isActive: true,
      },
      {
        id: 7,
        title: 'titulo clase 7',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: false,
        isActive: true,
      },
      {
        id: 8,
        title: 'titulo clase 8',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: false,
        isActive: true,
      },
      {
        id: 9,
        title: 'titulo clase 9',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: false,
        isActive: true,
      },
      {
        id: 10,
        title: 'titulo clase 10',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: false,
        isActive: true,
      },
      {
        id: 11,
        title: 'titulo clase 11',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: false,
        isActive: true,
      },
      {
        id: 12,
        title: 'titulo clase 12',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: false,
        isActive: true,
      },
    ],
    availability: true,
    level: 'principiante',
  },
  {
    id: 3,
    title: 'Desarrolla tu sensibilidad musical',
    duration: 8,
    inizializationDate: new Date('2024-1-25').toString(),
    quotes: [
      { amount: 200, quantity: 7, quoteNumber: 1 },
      { amount: 200, quantity: 7, quoteNumber: 2 },
      { amount: 200, quantity: 7, quoteNumber: 3 },
      { amount: 200, quantity: 7, quoteNumber: 4 },
    ],
    payments: [
      { totalQuotas: 1, totalPrice: 1000 },
      { totalQuotas: 3, totalPrice: 333 },
      { totalQuotas: 7, totalPrice: 120 },
    ],
    price: 0.0,
    description:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum ipsum odit laborum tenetur ratione cupiditate laboriosam, quasi totam, expedita magni porro eos. Maiores magnam sunt eos facilis suscipit numquam explicabo.',
    studentsIds: [{ id: 1 }, { id: 3 }, { id: 4 }],
    professorsIds: [{ id: 2 }],
    material: [
      {
        id: 1,
        title: 'titulo clase',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: true,
        isActive: true,
      },
      {
        id: 2,
        title: 'titulo clase dos',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: false,
        isActive: true,
      },
      {
        id: 3,
        title: 'titulo clase tres',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: false,
        isActive: true,
      },
    ],
    availability: false,
    level: 'intermedio',
  },
  {
    id: 2,
    title: 'Sarasa',
    duration: 8,
    inizializationDate: new Date('2024-1-25').toString(),
    quotes: [
      { amount: 200, quantity: 7, quoteNumber: 1 },
      { amount: 200, quantity: 7, quoteNumber: 2 },
      { amount: 200, quantity: 7, quoteNumber: 3 },
      { amount: 200, quantity: 7, quoteNumber: 4 },
    ],
    payments: [
      { totalQuotas: 1, totalPrice: 1000 },
      { totalQuotas: 3, totalPrice: 333 },
      { totalQuotas: 7, totalPrice: 120 },
    ],
    price: 0.0,
    description:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nostrum ipsum odit laborum tenetur ratione cupiditate laboriosam, quasi totam, expedita magni porro eos. Maiores magnam sunt eos facilis suscipit numquam explicabo.',
    studentsIds: [{ id: 1 }, { id: 3 }, { id: 4 }],
    professorsIds: [{ id: 2 }],
    material: [
      {
        id: 1,
        title: 'titulo clase',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: true,
        isActive: true,
      },
      {
        id: 2,
        title: 'titulo clase dos',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: false,
        isActive: true,
      },
      {
        id: 3,
        title: 'titulo clase tres',
        content: 'any',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        isVisible: false,
        isActive: true,
      },
    ],
    availability: false,
    level: 'principiante',
  },
]
