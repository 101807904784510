import React from 'react'

import { Spacer } from '../global'
import { Profile } from '../profile'
import { Profesor, Student } from '../types'

const StuProfile = ({
  activeStudent,
  selectStudent,
}: {
  activeStudent: Student
  selectStudent: (student: Profesor | Student) => void
}) => {
  return (
    <>
      <Spacer height={32} />
      <Profile
        type="student"
        // editing={onEditionMode}
        editedProfile={activeStudent}
        setEditedProfile={selectStudent}
      />
    </>
  )
}

export default StuProfile
