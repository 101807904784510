export const mock = {
  amoutOfClasses: 3,
  coursesComplete: 4,
  sentTask: 5,
  ranking: 6,
  percentageComparison: 45,
}

const objetoMockProgress = [
  {
    amount: mock.amoutOfClasses.toString(),
    amountTitle: 'Clases',
    status: 'Consecutivas',
  },
  {
    amount: mock.coursesComplete.toString(),
    amountTitle: 'Curso',
    status: 'Completado',
  },
  {
    amount: mock.sentTask.toString(),
    amountTitle: 'Tarea',
    status: 'Enviada',
  },
  {
    amount: mock.ranking.toString(),
    amountTitle: 'Puntos',
    status: 'Diga Ranking!',
  },
]

export default objetoMockProgress
