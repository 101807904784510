import React, { useEffect, useState } from 'react'

import {
  arrayUnion,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { GrAdd } from 'react-icons/gr'

import { useUser } from '../../context'
import { firestore } from '../../firebase/firebaseConfig'
import { capitalizeFirstLetter } from '../helpers/functions'
import { Input } from '../inputs'

import {
  NavbarContainer,
  NavbarLinkContainer,
  NavbarLink,
  NewItemRow,
} from './styles'

interface InlineMenuProps {
  menuName: string
  userType?: string | null
  // menuItems: string[]
  activeFilter: string | string[] | null
  isDisabled?: boolean
  setActiveFilter: (
    value: string | string[] | null,
    filterName?: string,
  ) => void
}

const InlineMenu = ({
  menuName,
  userType,
  // menuItems,
  activeFilter,
  isDisabled,
  setActiveFilter,
}: InlineMenuProps) => {
  const { onEditionMode } = useUser()

  const [addItem, setAddItem] = useState<boolean>(false)
  const [newItem, setNewItem] = useState('')
  const [menuItems, setMenuItems] = useState([])

  const isActive = (item: string) => {
    if (Array.isArray(activeFilter)) {
      return activeFilter.includes(item)
    }
    return activeFilter === item
  }

  const handleClickButton = (item: string) => {
    if (Array.isArray(activeFilter)) {
      if (activeFilter.includes(item)) {
        setActiveFilter(
          activeFilter.filter((filterItem) => filterItem !== item),
        )
      } else {
        setActiveFilter([...activeFilter, item])
      }
    } else {
      setActiveFilter(item)
    }
  }

  const checkAndAddListener = async () => {
    if (menuName) {
      try {
        const docRef = doc(firestore, 'lists', menuName)

        const docSnapshot = await getDoc(docRef)

        if (docSnapshot.exists()) {
          listenToDocumentChanges(menuName)
        } else {
          await setDoc(docRef, { items: [] })

          listenToDocumentChanges(menuName)
        }
      } catch (error) {
        console.error('Error accediendo o creando el documento:', error)
      }
    }
  }

  const addItemToList = async () => {
    if (userType === 'admin' && menuName && addItem) {
      const docRef = doc(firestore, 'lists', menuName)

      try {
        const docSnapshot = await getDoc(docRef)

        if (docSnapshot.exists()) {
          await updateDoc(docRef, {
            items: arrayUnion(newItem),
          })
        } else {
          await setDoc(docRef, {
            items: [newItem],
          })
        }
        setNewItem('')
      } catch (error) {
        console.error('Error al verificar o actualizar el documento:', error)
      }
    } else {
      setAddItem(true)
    }
  }

  const listenToDocumentChanges = (documentName: string) => {
    const docRef = doc(firestore, 'lists', documentName)

    const unsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data()
          setMenuItems(data.items || [])
        }
      },
      (error) => {
        console.error('Error al escuchar cambios en el documento:', error)
      },
    )

    return unsubscribe
  }

  useEffect(() => {
    if (menuName) {
      checkAndAddListener()
    }
  }, [menuName])

  return (
    <NavbarContainer>
      <NavbarLinkContainer>
        {menuItems.length > 0 &&
          menuItems.map((item: string | { name: string; uid: string }, i) => {
            const _item = typeof item === 'string' ? item : item.name
            return (
              <NavbarLink
                key={i}
                className={isActive(_item) ? 'active' : ''}
                onClick={() => handleClickButton(_item)}
                disabled={isDisabled}
              >
                {capitalizeFirstLetter(_item)}
              </NavbarLink>
            )
          })}
        {onEditionMode && (
          <NewItemRow>
            {addItem && (
              <Input
                name="newItem"
                id="newItem"
                value={newItem}
                onChange={(e) => setNewItem(e.target.value)}
                width="300px"
              />
            )}
            {userType === 'admin' && menuName !== 'professorsNames' && (
              <NavbarLink onClick={addItemToList}>
                <GrAdd /> Agregar Item
              </NavbarLink>
            )}
          </NewItemRow>
        )}
      </NavbarLinkContainer>
    </NavbarContainer>
  )
}

export default InlineMenu
