import React, { Dispatch, SetStateAction } from 'react'

import styled from 'styled-components'

import { DarkGreyButton, Modal, SectionSubtitle } from '../..'
import { Spacer } from '../../global'

const ContainerButtons = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;

  .delete-button {
    padding: 10px 20px;
    border: 0.5px solid #313131;
  }
`

const ErrorNewUser = ({
  setOpenModal,
  errorMessage,
  setError,
}: {
  setOpenModal: Dispatch<SetStateAction<boolean>>
  errorMessage: string
  setError: Dispatch<SetStateAction<string | null>>
}) => {
  const handleClose = () => {
    setError(null)
    setOpenModal(false)
  }
  return (
    <Modal onClose={handleClose}>
      <Spacer height={30} />
      <SectionSubtitle fontSize={16} fontWeight={500} textalign="center">
        <>
          {errorMessage} <br />
          Intentalo de nuevo!
        </>
      </SectionSubtitle>

      <Spacer height={50} />
      <ContainerButtons>
        <DarkGreyButton onClick={handleClose}>Editar</DarkGreyButton>
      </ContainerButtons>
    </Modal>
  )
}

export default ErrorNewUser
