import styled from 'styled-components'

import '@fontsource/plus-jakarta-sans'

export const InputContainer = styled.input<{
  $width?: string | number
  $height?: number
}>`
  background: #f6f6f6;
  padding: 10px 20px;
  border-radius: 10px;
  color: #0a0a18;
  font-weight: 500;
  font-size: 14px;
  outline: #8e92bc;
  width: ${(props) => (props.$width ? props.$width : '100%')};
  border: none;
  outline: none;
  height: ${(props) => (props.$height ? `${props.$height}px` : 'auto')};
  &::placeholder {
    color: #8e92bc;
    opacity: 1;

    font-size: 10px;
  }

  &:focus {
    outline: none;
    border: 0.5px solid #08a8d2;
  }

  &.profession-data {
    text-align: center;
  }

  &.profile-input {
    color: #8e92bc;
    background: transparent;
    border: 1px solid #dbdbdb;

    &:disabled {
      color: #0a0a18;
      background: #f6f6f6;
      border: none;
    }
  }
`
