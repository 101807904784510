import styled from 'styled-components'

import '@fontsource/plus-jakarta-sans'

export const FormContainer = styled.div`
  padding: 20px 24px;
  background-color: #fdfdfd;

  text-align: left;
  width: 100%;

  p {
    padding: 0;
  }

  .more-weeks svg {
    font-size: 20px;
    color: #08a8d2;
    margin-right: 8px;
  }
`

export const PhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const SectionContainer = styled.div<{ direction: string }>`
  display: flex;
  flex-direction: ${(props: { direction: string }) => props.direction};
  justify-content: space-between;
`

export const QuotesContainers = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .quote-input {
    background: #f6f6f6;
    padding: 8px 16px;
    border-radius: 10px;
    color: #030410;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    outline: #8e92bc;
    width: 104px;
    height: 37px;

    ::placeholder {
      color: #8e92bc;
      opacity: 1;
    }
  }

  .quotes-input {
    background: #f6f6f6;
    border-radius: 10px;
    padding: 8px 12px;
    width: 104px;
    height: 37px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 8px;

    &.active {
      border: 0.5px solid #8e92bc;
    }

    input[type='number'] {
      border-radius: 0;
      width: 45px;
      color: #030410;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      outline: transparent;
      border-width: 0px;
      padding: 0;
      &::placeholder {
        color: #8e92bc;
        opacity: 1;
      }
    }
    &:focus {
      border-width: 0.5px;
      outline: #8e92bc;
    }
  }

  .unit {
    position: absolute;
    right: 30px;
    top: 11px;
  }
`

export const ListContainer = styled.div`
  background-color: #f6f6f6;
  border-radius: 10px;
  width: 152px;
  height: fit-content;

  .title {
    padding: 4px 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15.12px;

    svg {
      color: #08a8d2;
      margin-right: 3px;
    }
  }
`

export const StudentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  width: calc(100% - 250px);
  padding-left: 20px;
  gap: 10px;
`
