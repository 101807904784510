import styled from 'styled-components'
import '@fontsource/plus-jakarta-sans'

export const CheckboxContainer = styled.div`
  label {
    background-color: transparent;
    border: 4px solid #fdfdfd;
    border-radius: 50%;
    cursor: pointer;
    right: 8px;
    position: absolute;
    top: 8px;
    height: 20px;
    width: 20px;
    filter: drop-shadow(0px 0px 25px #54577a);
  }

  input[type='checkbox'] {
    visibility: hidden;
  }

  input[type='checkbox']:checked + label {
    background-color: transparent;
    border: 4px solid #08a8d2;
  }

  input[type='checkbox']:checked + label:after {
    opacity: 1;
  }
`
