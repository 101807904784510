import dayjs from 'dayjs'

export const capitalizeFirstLetter = (str: string | undefined) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
}

export const transformDate = (date: string) => {
  return dayjs(date).format('DD-MM-YYYY')
}
