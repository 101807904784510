import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { SectionTitle, SectionSubtitle, Input, InputMoney } from '../../..'
import { Spacer } from '../../../global'
import { QuotesProps, ValueSectionProps } from '../../../types'
import { SectionContainer, QuotesContainers } from '../styles'

const Quotas = ({
  times,
  quotasValue,
  setQuotasValue,
}: {
  times: number
  quotasValue: QuotesProps[]
  setQuotasValue: Dispatch<SetStateAction<QuotesProps[]>>
}) => {
  return (
    <div style={{ width: '304px', justifyContent: 'space-around' }}>
      {Array.from({ length: times }, (_, i) => (
        <QuotesContainers key={i}>
          <SectionSubtitle
            id={`quote-${i}`}
            classSub="inactive"
            fontWeight={500}
          >
            {i + 1} Cuotas
          </SectionSubtitle>
          <InputMoney
            number={i}
            quotasValue={quotasValue}
            setQuotasValue={setQuotasValue}
          />
        </QuotesContainers>
      ))}
    </div>
  )
}

const ValueSection = ({
  formQuotas,
  handleQuotas,
  setFormQuotas,
}: ValueSectionProps) => {
  const [quotasValue, setQuotasValue] = useState<QuotesProps[]>([])

  useEffect(() => {
    setFormQuotas({ ...formQuotas, quotasValue })
  }, [quotasValue, setFormQuotas])

  return (
    <SectionContainer direction="column">
      <div>
        <SectionTitle text="Valor del curso" />
      </div>
      <Spacer height={32} />
      <div className="d-flex justify-content-start align-items-center">
        <SectionSubtitle fontWeight={500}>Valor:</SectionSubtitle>
        <div style={{ width: '8px' }}></div>
        <Input
          type="number"
          name="totalValue"
          id="course-title"
          width="104px"
          onChange={(e) => handleQuotas(e)}
        />
      </div>
      <Spacer height={32} />
      <div>
        <SectionSubtitle>Pago en cuotas</SectionSubtitle>
        <Spacer height={24} />
        <div className="d-flex align-items-start" style={{ gap: '28px' }}>
          <QuotesContainers>
            <SectionSubtitle fontWeight={500}>Coutas:</SectionSubtitle>
            <div style={{ width: '8px' }}></div>
            <Input
              inputClass="quote-input"
              type="number"
              name="amountQuotas"
              id="number-quotes"
              onChange={(e) => handleQuotas(e)}
            />
          </QuotesContainers>

          <Quotas
            times={formQuotas.amountQuotas || 3}
            quotasValue={quotasValue}
            setQuotasValue={setQuotasValue}
          />
        </div>
      </div>
    </SectionContainer>
  )
}

export default ValueSection
