import styled from 'styled-components'
import '@fontsource/plus-jakarta-sans'

export const ContentRoutineSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: 768px) {
    gap: 19px;
    justify-content: center;
  }
`

//Card
export const ContentCardRoutine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  width: 304px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const ContentImage = styled.div<{ img: string }>`
  display: flex;
  flex-direction: column;
  background-image: url(${(props) => props.img});
  width: 100%;
  height: 168px;
  padding: 10px;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: flex-end;
  background-position: center;
  background-size: cover;
`

export const ContentTitle = styled.div<{
  userType: string
}>`
  display: inline-flex;
  border-radius: 10px;
  padding: 4px 8px;
  background: ${(props) =>
    props.userType === 'student' ? '#ffdc50' : '#55B5DF'};

  p {
    color: ${(props) => (props.userType === 'student' ? '#0a0a18' : '#FDFDFD')};
    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 2px;
  }
`

export const ContentDescription = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding-left: 5px;
  p {
    margin-top: 4px;
    color: #313131;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
    margin-bottom: 5px;
  }
`
