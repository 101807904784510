import styled, { createGlobalStyle } from 'styled-components'
import '@fontsource/plus-jakarta-sans'

export const Spacer = styled.div<{ height: number }>`
  height: ${(props) => `${props.height}px`};
  width: 100%;
`

export const Divider = styled.div<{
  $height?: string
  $width?: string
  $color?: string
  $position?: string
}>`
  height: ${(props) => (props.$height ? `${props.$height}` : '1px')};
  width: ${(props) => (props.$width ? `${props.$width}` : '100%')};
  background: ${(props) => (props.$color ? `${props.$color}` : '#8e92bc29')};
  align-self: ${(props) =>
    props.$position ? `${props.$position}` : 'flex-start'};
`

export default createGlobalStyle`
   *{
       margin: 0;
       padding: 0;
       outline:0;
       box-sizing:border-box;
       font-family: "Plus Jakarta Sans";
   }
   #root{
       margin:0 auto;
   }
`
