import styled from 'styled-components'
import '@fontsource/plus-jakarta-sans'

export const SearchContainer = styled.div<{ width?: string }>`
  padding: 8px 0px;
  position: relative;
  width: 100%;

  .search-input {
    border: 1px solid #f6f6f6;
    background-color: transparent;
    padding: 0.5px 12px;
    border-radius: 10px;
    height: 35px;
    width: ${(props) => (props.width ? props.width : '100%')};
    font-size: 12px;
    font-weight: 400;
  }

  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  span {
    position: absolute;
    top: 15px;
    right: 10px;
    cursor: pointer;

    svg {
      font-size: 20px;
    }
  }

  &.person-search {
    input {
      background: #fff;
    }
  }
`
