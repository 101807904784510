import React from 'react'

import dayjs from 'dayjs'

import { useStudents } from '../../../context'
import { Spacer } from '../../global'
import { transformDate } from '../../helpers/functions'
import { Cupon, listHeads } from '../../types'
import { TableContainer } from '../styles'

const AdminLastClassBonusList = ({ bonus }: { bonus: Cupon[] }) => {
  const { studentsList } = useStudents()

  const filterStudent = (id: string) => {
    const student =
      studentsList && studentsList.find((student) => student.uid === id)
    const name = student?.name || ''
    const lastname = student?.lastname || ''
    return `${name} ${lastname}`
  }
  return (
    <TableContainer>
      <div className="t-row t-head bonus-list debt">
        {listHeads.lastClass.map((item, i) => {
          return <div key={i}>{item}</div>
        })}
      </div>
      <Spacer height={10} />
      {bonus.map((cupon, i) => {
        return (
          <div className="t-row bonus-list" key={i}>
            <div className="with-image">
              <div
                className="profile-image"
                style={{ backgroundImage: `url(/assets/profile.png)` }}
              ></div>
              {filterStudent(cupon.idStudent)}
            </div>
            <div>{cupon.amountOfClasses} clases</div>
            <div style={{ color: '#08A8D2', fontWeight: 600 }}>
              {cupon.attendance} clases
            </div>
            <div
              style={{
                color: dayjs(cupon.dueDate).isBefore(dayjs()) ? '#ec8f87' : '',
              }}
            >
              {dayjs(cupon.dueDate).isBefore(dayjs()) && 'Vencido el: '}
              {transformDate(cupon.dueDate)}
            </div>
          </div>
        )
      })}
    </TableContainer>
  )
}

export default AdminLastClassBonusList
