import React from 'react'

import { IconType } from 'react-icons'
import styled from 'styled-components'

const IconButtons = styled.button<{ $position: 'right' | 'left' }>`
  display: flex;
  flex-direction: ${(props) =>
    props.$position === 'right' ? 'column' : 'column-reverse'};
  border: none;
  background: transparent;

  &:focus {
    outlone: none;
  }
`

const IconButton = ({
  Icon,
  iconColor = '#000',
  fontSize,
  position = 'right',
  buttonClass,
  text,
  onClick,
}: {
  Icon: IconType
  iconColor?: string
  fontSize: number
  position?: 'right' | 'left'
  buttonClass?: string
  text?: string
  onClick: () => void
}) => {
  return (
    <IconButtons className={buttonClass} onClick={onClick} $position={position}>
      <p>{text && text}</p>
      <Icon fontSize={fontSize} color={iconColor} />
    </IconButtons>
  )
}

export default IconButton
