import Cookies from 'js-cookie'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setCookie = (key: string, value: string, options?: any) => {
  return Cookies.set(key, value, options)
}

export const getCookie = (key: string) => {
  return Cookies.get(key)
}

export const removeCookie = (key: string) => {
  return Cookies.remove(key)
}
