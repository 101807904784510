import styled from 'styled-components'

export const ContentSection = styled.div<{ isVisible?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 8px 32px 32px;
  border-radius: 10px;
  background: #fdfdfd;
  box-shadow: 4px 0px 8px 0px rgba(142, 146, 188, 0.05);

  .content-childrens {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .content-children {
    display: flex;
    width: 80%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .edit-profile-section {
    display: flex;
    width: 100%;

    .content-children {
      width: 100%;
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    padding: 8px 15px 22px;
  }
`

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  .children-2-mobile {
    @media (max-width: 768px) {
      display: flex;
      align-items: flex-end;
    }
    @media (min-width: 769px) {
      display: none;
    }
  }
`

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    color: #0a0a18;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: -0.4px;
    margin-bottom: 7px;
  }
  h3 {
    color: #313131;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
  }
`

export const ContentChildren2 = styled.div`
  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 769px) {
    display: flex;
    align-items: flex-end;
  }
`
