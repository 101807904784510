import React, { Dispatch, SetStateAction } from 'react'

import { HiOutlinePlus } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

import { useStudents, useUser } from '../../context'
import { NewUserButton } from '../../pages/administration/styles'
import { Spacer } from '../global'
import Search from '../search/Search'
import { SectionSubtitle } from '../texts'
import { Student, Profesor } from '../types'

import ListWithImage from './listWithImage/ListWithImage'

const ListWithSearch = ({
  type,
  searchValue,
  filteredStudents,
  setSearchValue,
  selectStudent,
}: {
  type: string
  searchValue: string
  filteredStudents: Student[] | null
  setSearchValue: Dispatch<SetStateAction<string>>
  selectStudent: (student: Profesor | Student) => void
}) => {
  const navigate = useNavigate()
  const { onEditionMode } = useUser()
  const { activeStudent, studentsList } = useStudents()

  return (
    <div className="list">
      <Spacer height={15} />
      <div style={{ padding: '8px 24px', width: '100%' }}>
        <SectionSubtitle fontWeight={600} fontSize={16} lineHeight={24}>
          Seleccione {type}
        </SectionSubtitle>
        <Spacer height={10} />
        <Search searchValue={searchValue} setSearchValue={setSearchValue} />
      </div>
      <Spacer height={10} />
      <ListWithImage
        disabled={onEditionMode}
        list={filteredStudents ?? studentsList}
        onClick={selectStudent}
        activeId={activeStudent && activeStudent.uid}
      />
      <NewUserButton $hasSeparation={true}>
        <button onClick={() => navigate('/nuevo-usuario?user=student')}>
          <HiOutlinePlus />
        </button>
        <p>Añadir {type.charAt(0).toUpperCase() + type.slice(1)}</p>
      </NewUserButton>
    </div>
  )
}

export default ListWithSearch
