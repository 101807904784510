import React, { useEffect, useState } from 'react'

import dayjs, { Dayjs } from 'dayjs'
import { AiOutlineDelete } from 'react-icons/ai'
import { GoDesktopDownload } from 'react-icons/go'

import { SectionSubtitle } from '..'
import { useProfessors, useStorage } from '../../context'
import { Divider, Spacer } from '../global'
import InvoiceDeleteModal from '../modal/invoiceDeleteModal/InvoiceDeleteModal'

import { FileRow, FilesContainer } from './styles'

const InvoicesList = ({ searchDate }: { searchDate: Dayjs | null }) => {
  const { invoicesList, deleteFile } = useStorage()
  const { activeProfessor } = useProfessors()
  const [openModal, setOpenModal] = useState<boolean>(false)

  const [filterByDate, setFilterByDate] = useState<
    | {
        year: number
        months: {
          month: string
          url: string
          path: string
        }[]
      }[]
    | null
  >(null)
  const [errorNomina, setErrorNomina] = useState<string | null>(null)

  const deleteItem = async (path: string) => {
    if (activeProfessor) {
      deleteFile(path, activeProfessor.uid)
    }
  }

  const filterDate = () => {
    if (searchDate && invoicesList) {
      const _data = invoicesList
        .map((invoice) => {
          if (invoice.year === dayjs(searchDate).year()) {
            const filteredMonths = invoice.months.filter((itemMonth) => {
              return itemMonth.month === dayjs(searchDate).format('MMMM')
            })
            if (filteredMonths.length > 0) {
              return { year: invoice.year, months: filteredMonths }
            }
          }
          return null
        })
        .filter(Boolean) as {
        year: number
        months: { month: string; url: string; path: string }[]
      }[]

      if (_data.length > 0) {
        setFilterByDate(_data)
        setErrorNomina(null)
      } else {
        setFilterByDate(null)
        setErrorNomina(
          `No hay nominas cargadas para ${dayjs(searchDate).format('MMMM')} ${dayjs(searchDate).year()}`,
        )
      }
    } else {
      setFilterByDate(invoicesList)
    }
  }

  useEffect(() => {
    filterDate()
  }, [searchDate])

  return (
    <FilesContainer>
      {/* <SectionSubtitle fontSize={14} fontWeight={500}>
        Todas las nómina
      </SectionSubtitle> */}
      {errorNomina && <p>{errorNomina}</p>}

      {filterByDate &&
        filterByDate.map((item, i) => {
          return (
            <div key={i} className="years-section">
              <SectionSubtitle
                fontSize={14}
                fontWeight={500}
                classSub="year-title"
              >
                <>{item.year}</>
              </SectionSubtitle>
              <Spacer height={5} />
              <Divider $color="#84869729" $position="flex-start" />
              {item.months.map((invoice, i: number) => (
                <FileRow key={i}>
                  <a href={invoice.url} target="_blank">
                    <div className="file-name">
                      <img src="/assets/icons/pdf.svg" alt="" />
                      <p>Nomina - {invoice.month}</p>
                    </div>

                    <div className="download-icon">
                      <GoDesktopDownload />
                    </div>
                  </a>
                  <div
                    className="delete-icon"
                    onClick={() => setOpenModal(true)}
                  >
                    <AiOutlineDelete />
                  </div>

                  {openModal && (
                    <InvoiceDeleteModal
                      key={i}
                      setOpenModal={setOpenModal}
                      invoice={invoice}
                      year={item.year}
                      deleteFile={deleteItem}
                    />
                  )}
                </FileRow>
              ))}
              <Spacer height={20} />
            </div>
          )
        })}
    </FilesContainer>
  )
}

export default InvoicesList
