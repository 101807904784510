import styled from 'styled-components'

import '@fontsource/plus-jakarta-sans'

export const FormularioRecoverPassword = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .holaSvg {
    background-image: url('/assets/icons/book-square.svg');
    background-size: cover;
    width: 198px;
    height: 108px;
    position: absolute;
    z-index: 1;
    top: 0;
  }

  .containerInputPassword {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    label {
      margin-bottom: 15px;
    }
    input {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.12px;
    }
    input:focus {
      border: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #f6f6f6 inset !important;
      -webkit-text-fill-color: black !important;
    }

    input:-moz-ui-invalid {
      box-shadow: none !important;
      background-color: #f6f6f6 !important;
    }

    input:autofill {
      background-color: #f6f6f6 !important;
    }
  }
`

export const TitleForm = styled.p`
  color: var(--Oscuro, #0a0a18);
  text-shadow: 4px 4px 24px rgba(49, 49, 49, 0.15);

  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 60px */
  letter-spacing: -1.2px;
  position: absolute;
  z-index: 1;
  top: 93px;
`

export const FormularioRectangulo = styled.div`
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  padding: 30px;
  border-radius: 10px;
  border: 0.5px solid #dbdbdb;
  box-shadow: 0px 4px 8px 0px rgba(142, 146, 188, 0.05);

  .messageError {
    display: flex;
    color: #ec8f87;

    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
    margin-top: 5px;
    margin-left: 5px;
  }
`
