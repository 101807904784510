import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  ChangeEvent,
} from 'react'

import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'

import { SectionSubtitle } from '../../../../..'
import { WeeklyTheme } from '../../../../../types'
import { ContentLink, ContentName } from '../styles'

const InputContent = ({
  content,
  weekInformation,
  showContentAndTask,
  saveContent,
  setShowContentAndTask,
}: {
  content: {
    contentTitle: string
    contentLink: string
    contentDescription: string
  }
  weekInformation: WeeklyTheme
  showContentAndTask: boolean
  saveContent: Dispatch<
    SetStateAction<{
      contentTitle: string
      contentLink: string
      contentDescription: string
    }>
  >
  setShowContentAndTask: Dispatch<SetStateAction<boolean>>
}) => {
  const [showArrow, setShowArrow] = useState(false)

  useEffect(() => {
    if (content.contentTitle) {
      setShowArrow(true)
    } else {
      setShowArrow(false)
    }
  }, [content.contentTitle])

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement> | undefined,
  ) => {
    if (!event) {
      return
    }

    const { name, value } = event.target

    saveContent((prevContent) => ({
      ...prevContent,
      id: weekInformation.content.length,
      [name]: value,
    }))
  }

  return (
    <ContentLink>
      <div className="content">
        <ContentName>
          <p>1.{weekInformation.content.length}</p>
          <input type="text" name="contentTitle" onChange={handleInputChange} />
          {showArrow && (
            <div
              className="arrow"
              onClick={() => setShowContentAndTask(!showContentAndTask)}
            >
              {showContentAndTask ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
          )}
        </ContentName>
      </div>

      {showContentAndTask && (
        <>
          <div className="">
            <div className="space-34"></div>
            <SectionSubtitle fontSize={16} fontWeight={500}>
              Contenido
            </SectionSubtitle>
            <div className="space-16"></div>
            <input
              className="input-link"
              name="contentLink"
              type="text"
              placeholder="Insertar link de YouTube...."
              style={{ height: '40px' }}
              onChange={handleInputChange}
            />
            <div className="disclaimer">
              <p>
                O <span>subir archivo</span>
              </p>
            </div>
          </div>
          <div className="">
            <SectionSubtitle fontSize={16} fontWeight={500}>
              Descripción
            </SectionSubtitle>
            <div className="space-16"></div>
            <input
              type="text"
              name="contentDescription"
              placeholder="Escribe una descripción para la clase de la semana......"
              className="input-link"
              style={{ height: '144px' }}
              onChange={handleInputChange}
            />
          </div>
        </>
      )}
    </ContentLink>
  )
}

export default InputContent
