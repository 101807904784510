import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'

import { Invoice } from '../components/types'
import { storage } from '../firebase/firebaseConfig'
import { updateDocument } from '../firebase/utils'

interface FirebaseStorageProps {
  uploadFile: (
    file: File,
    userId: string,
    year: number,
    month: string,
  ) => Promise<void>
  downloadFile: (
    fileName: string,
    userId: string,
  ) => Promise<string | null | undefined>
  deleteFile: (path: string, userId: string) => Promise<void>
  invoicesList: Invoice[] | null
  getInvoiceList: (userId: string) => Promise<void>
  error: string | null
  loadingStorage: boolean
  progressBar: number | null
  setProgessBar: Dispatch<SetStateAction<number | null>>
  uploadProfileImage: (
    userUID: string,
    blob: Blob,
    userRole: string,
  ) => Promise<void>
}

const StorageContext = createContext<FirebaseStorageProps>({
  uploadFile: () => Promise.resolve(),
  downloadFile: () => Promise.resolve(null),
  deleteFile: () => Promise.resolve(),
  invoicesList: null,
  getInvoiceList: () => Promise.resolve(),
  error: null,
  loadingStorage: false,
  progressBar: null,
  setProgessBar: () => null,
  uploadProfileImage: () => Promise.resolve(),
})

export const useStorage = () => useContext(StorageContext)

export const StorageProvider = ({ children }: { children: ReactNode }) => {
  const [loadingStorage, setLoadingStorage] = useState<boolean>(false)
  const [progressBar, setProgessBar] = useState<null | number>(null)
  const [error, setError] = useState<null | string>(null)
  const [invoicesList, setInvoicesList] = useState<Invoice[] | null>(null)

  const downloadFile = async (fileName: string, userId: string) => {
    try {
      const fileRef = ref(storage, `${userId}/${fileName}`)

      const downloadUrl = await getDownloadURL(fileRef)
      return downloadUrl
    } catch (error) {
      console.error('Error al descargar el archivo:', error)
      setError(`Error al descargar el archivo: ${error}`)
    }
  }

  const uploadFile = async (
    file: File,
    userId: string,
    year: number,
    month: string,
  ) => {
    const fileRef = ref(storage, `${userId}/nominas/${year}/${month}`)

    // const uploadedFile = await uploadBytes(fileRef, file)
    const uploadTask = uploadBytesResumable(fileRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setProgessBar(progress)

        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused')
            break
          case 'running':
            console.log('Upload is running')
            break
        }
      },
      (error) => {
        console.error('Error al subir el archivo:', error)
        setError(`Error al subir el archivo:', ${error}`)
        return `Error al subir el archivo:', ${error}`
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        // setError(`La nomina se ha cargado con exito'`)
        getInvoiceList(userId)
      },
    )

    // if (uploadedFile.metadata.fullPath) {
    //   const downloadUrl = await getDownloadURL(fileRef)
    //   console.log(uploadedFile.metadata.fullPath, downloadUrl, 'downloadUrl')
    // }
    // const downloadUrl = await getDownloadURL(fileRef)

    // const newInvoice = { month: month, downloadUrl: downloadUrl }
    // const invoices = {`invoices.${year}`: arrayUnion(newInvoice)}

    // updateDocument('proffesors', userId, invoices)

    // return downloadUrl
  }

  const deleteFile = async (path: string, userId: string) => {
    const fileRef = ref(storage, path)

    deleteObject(fileRef)
      .then(() => {
        getInvoiceList(userId)
      })
      .catch((error) => {
        console.error('Error al al borrar el archivo:', error)
        setError(`Error al al borrar el archivo:', ${error}`)
      })
  }

  const getInvoiceList = async (userId: string) => {
    const nominasRef = ref(storage, `${userId}/nominas`)
    setLoadingStorage(true)
    // Recorre las carpetas de años en "nominas"
    try {
      const yearFolders = await listAll(nominasRef)
      const newFilesByYear:
        | {
            year: number
            months: { month: string; url: string; path: string }[]
          }[]
        | null = []

      for (const yearFolder of yearFolders.prefixes) {
        const year = Number(yearFolder.name)
        const files = await listAll(yearFolder)

        const months = await Promise.all(
          files.items.map(async (file) => ({
            month: file.name,
            url: await getDownloadURL(file),
            path: file.fullPath,
          })),
        )

        newFilesByYear.push({ year, months })
      }
      setInvoicesList(newFilesByYear)
    } catch (error) {
      console.error('Error al obtener los archivos:', error)
      setError(`Error al obtener los archivos:', ${error}`)
    }
    setLoadingStorage(false)
  }

  const uploadProfileImage = async (
    userId: string,
    blob: Blob,
    userRole: string,
  ) => {
    setLoadingStorage(true)
    const storageRef = ref(storage, `${userId}/profile-image.jpeg`) // Asigna un nombre único a cada imagen
    const uploadTask = uploadBytesResumable(storageRef, blob)

    uploadTask.on(
      'state_changed',
      () => {
        // You can add progress logic here if needed
      },
      (error) => {
        console.error('Upload failed', error)
        setError(`Error al subir el archivo:', ${error}`)
        setLoadingStorage(false)
        return
      },
      async () => {
        try {
          const imageUrl = await getDownloadURL(uploadTask.snapshot.ref) // Obtiene la URL de la imagen

          await updateDocument(userRole, userId, { image: imageUrl })

          setError(null) // Restablece cualquier error anterior

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          console.error('Error al obtener la URL de descarga', err)
          setError(`Error al obtener la URL: ${err.message}`)
        } finally {
          setLoadingStorage(false) // Asegúrate de que se indique que la carga ha terminado
        }
      },
    )
  }

  return (
    <StorageContext.Provider
      value={{
        uploadFile,
        downloadFile,
        deleteFile,
        getInvoiceList,
        invoicesList,
        error,
        loadingStorage,
        progressBar,
        setProgessBar,
        uploadProfileImage,
      }}
    >
      {children}
    </StorageContext.Provider>
  )
}
