import React, { useState } from 'react'

import { IoPersonOutline, IoLogOutOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router'

import { useUser } from '../../context'
import { InlineMenuHeader } from '../InlineMenu'
import { Spacer } from '../global'
import { HeaderProps } from '../types'

import {
  HeaderContainer,
  HeaderRow,
  HeaderTitle,
  HeaderNotificationsContainer,
  HeaderNotifications,
  HeaderProfile,
  ProfileContainer,
} from './styled'

const Header = ({
  title,
  profileImage,
  modalStatus,
  setModalStatus,
}: HeaderProps) => {
  const navigate = useNavigate()

  const { signOut } = useUser()
  const [profileOptions, setProfileOptions] = useState(false)

  const handleEditProfileClick = () => {
    navigate('/profile', { state: { isEditMode: false } })
  }
  return (
    <HeaderContainer>
      <HeaderRow>
        <HeaderTitle>{title}</HeaderTitle>
        <HeaderNotificationsContainer>
          <HeaderNotifications>
            <img src="/assets/icons/Notification.svg" alt="notifications" />
          </HeaderNotifications>

          <HeaderProfile
            style={{
              backgroundImage: profileImage
                ? `url(${profileImage})`
                : `url('/assets/profile.png')`,
            }}
            onClick={() => setProfileOptions(!profileOptions)}
          />

          {profileOptions && (
            <ProfileContainer>
              <div className="list">
                <div onClick={handleEditProfileClick}>
                  <IoPersonOutline /> Perfil
                </div>
                <div onClick={signOut}>
                  <IoLogOutOutline /> Cerrar Sesión
                </div>
              </div>
            </ProfileContainer>
          )}
        </HeaderNotificationsContainer>
      </HeaderRow>
      <Spacer height={60} />
      <HeaderRow>
        <InlineMenuHeader
          userType="admin"
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
        />
      </HeaderRow>
    </HeaderContainer>
  )
}

export default Header
