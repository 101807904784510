import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import Layout from '../components/Layout/Layout'
import { useUser } from '../context'

import { PublicRoutes, PrivateRoutesAdmin } from './'

export const AppRouter = () => {
  const { currentUser, userRole } = useUser()

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {userRole && currentUser ? (
            <Route path="/*" element={<PrivateRoutesAdmin />} />
          ) : (
            <Route path="/*" element={<PublicRoutes />} />
          )}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}
