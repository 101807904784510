import '@fontsource/plus-jakarta-sans'
import styled from 'styled-components'

export const TableContainer = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;

  .t-row {
    padding: 6px 17px;
    box-shadow: 0px 8px 8px 0px rgb(74 77 102 / 5%);
    display: flex;
    margin-bottom: 10px;
    gap: 18px;
    height: 48px;
    border-radius: 10px;

    div {
      color: #8e92bc;
      flex: 1 0 21%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &.t-head {
      border-bottom: 1px solid #8e92bc38;
      border-radius: 0;

      div {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: #313131;
        box-shadow: none;

        @media screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }

    &.coupon div {
      flex: 1 0 10%;
      justify-content: space-evenly;

      .edit-bonus-input {
        margin-left: 20%;
        text-align: center;
      }
    }

    &.active {
      border: 1px solid #d3d4e4;
      border-radius: 10px;
      div {
        color: #313131;
      }
    }

    .red {
      color: #ec8f87 !important;
    }

    .yellow {
      color: #ffdc50;
    }

    .photo {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 4px;
    }

    .t-student {
      span {
        display: flex;
        align-items: center;
      }

      &.with-image {
        color: #313131;
        flex: none;
        width: 200px;
        justify-content: flex-start;
        .profile-image {
          border-radius: 50%;
          width: 24px;
          height: 24px;
          background-position: center;
          flex: none;
          background-size: cover;
          margin-right: 10px;
        }
      }
    }

    .t-professors {
      color: #313131;
    }
    .t-actions {
      display: flex;
      justify-content: space-between;

      div {
        cursor: pointer;
      }

      svg {
        font-size: 20px;
        color: #8e92bc;
      }
    }

    .t-course-actions {
      label {
        input {
          margin: 0 5px;
        }
      }
    }

    &.bonus-list {
      &.t-head {
        box-shadow: none;
        border-bottom: 1px solid #f6f6f6;
        height: 57px;
        &.debt div:first-child {
          justify-content: flex-start;
        }
      }

      div {
        justify-content: center;
        flex: 1;
      }

      .with-image {
        color: #313131;
        justify-content: flex-start;
        .profile-image {
          border-radius: 50%;
          width: 24px;
          height: 24px;
          background-position: center;
          flex: none;
          background-size: cover;
          margin-right: 10px;
        }
      }
    }

    .input-date {
      .MuiDateCalendar-root {
        width: 200px;
      }
    }
  }
`

export const MarksContainer = styled.div`
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 8px;

  .MuiInputBase-root {
    color: #8e92bc;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    border-radius: 14px;

    .MuiInputBase-input {
      padding: 10px 14px;

      &.Mui-disabled {
        color: #0a0a18;
      }
    }

    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #dbdbdb;

      &.Mui-focused {
        border-color: #dbdbdb;
        border-width: 1px;
      }
    }
  }
`

export const LightBlueMark = styled.div`
  color: #313131;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
  background: #bdddeb;
  box-shadow: 0px 4px 8px 0px rgba(142, 146, 188, 0.05);
  padding: 2px 8px;
  border-radius: 8px;
  align-items: center;
`
