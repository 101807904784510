import React, { useState, useEffect } from 'react'

import dayjs from 'dayjs'

import { useCupons } from '../../context'
import { SectionSubtitle } from '../texts'
import { CourseProps, Profesor, Student, Cupon } from '../types'

import { DetailsContainer, PhotoContainer, PersonData } from './styles'

const DetailPerson = ({ person }: { person: Profesor | Student }) => {
  const { studentCupons } = useCupons()

  const [activeBonos, setActiveBonos] = useState<Cupon[] | null>(null)
  const [activeBono, setActiveBono] = useState<Cupon | null>(null)
  const [activeCourse, setActiveCourse] = useState<Array<CourseProps> | null>(
    null,
  )

  const hasActiveBono = () => {
    const now = dayjs()
    const _activeBonos: Cupon[] = studentCupons?.filter((bono) =>
      dayjs(bono.dueDate).isAfter(now),
    )
    const _activeBono = _activeBonos.sort((a, b) =>
      dayjs(a.dueDate).diff(dayjs(b.dueDate)),
    )
    setActiveBono(_activeBono[0])
    setActiveBonos(_activeBonos)
  }
  const addWeeksToDate = (dateObj: string, numberOfWeeks: number) => {
    const date = new Date(dateObj)
    date.setDate(date.getDate() + numberOfWeeks * 7)
    return date
  }
  const hasActiveCourse = () => {
    person.courses?.map((item) => {
      if (
        new Date(
          addWeeksToDate(item.inizializationDate, item.duration),
        ).getTime() > new Date().getTime()
      ) {
        activeCourse && setActiveCourse([...activeCourse, item])
      }
    })
  }

  useEffect(() => {
    if (studentCupons) {
      hasActiveBono()
    }
    hasActiveCourse()
  }, [])

  return (
    <DetailsContainer>
      <PhotoContainer
        $image={
          person.image && person.image.length > 0
            ? person.image
            : '/assets/profile.png'
        }
      />

      <PersonData>
        <p className="name">
          {person.name} {person.lastname}
        </p>
        <p className="type">
          {person.role === 'student' ? (
            <>
              Alumno: <span>{person.experienceType ?? 'sin información'}</span>
            </>
          ) : (
            <>
              {Array.isArray(person.experienceType) &&
                person.experienceType.map((_exp, i) => {
                  const isLast = i === person.experienceType.length - 1

                  return (
                    <SectionSubtitle
                      key={i}
                      fontSize={12}
                      fontWeight={400}
                      classSub="prof-exp"
                    >
                      <>
                        {_exp}
                        {!isLast && ' -'}
                      </>
                    </SectionSubtitle>
                  )
                })}
            </>
          )}
        </p>
        <div className="bonos">
          <>
            {person.role === 'student' && studentCupons && (
              <>
                <div className="bono">Bonos: {studentCupons?.length} </div>
                {activeBonos && (
                  <>
                    <div className="bono">
                      Bonos activos: {activeBonos.length}
                    </div>
                    <div className="bono">
                      Clases: {activeBono?.attendance}/
                      {activeBono?.amountOfClasses}
                    </div>
                  </>
                )}
              </>
            )}
            {person.role === 'student' && person.courses && (
              <div className="bono">
                Curso: {activeCourse?.length} /{' '}
                {person.courses !== undefined && person.courses?.length}
              </div>
            )}

            {person.role === 'professor' &&
              person.activities?.map((item, i) => {
                return (
                  <div key={i} className="bono">
                    {item}
                  </div>
                )
              })}
          </>
        </div>
        {studentCupons && person.role === 'student' ? (
          <p className="type">
            Bono activo hasta el{' '}
            {activeBono?.dueDate &&
              new Date(activeBono.dueDate).toLocaleDateString('es-es', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
          </p>
        ) : (
          <p></p>
        )}
      </PersonData>
    </DetailsContainer>
  )
}

export default DetailPerson
