import styled from 'styled-components'

export const ToggleContainer = styled.div<{ active: number }>`
  display: flex;
  position: relative;
  border-radius: 20px;
  background: #f6f6f6;
  width: 320px;
  height: 40px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #313131;
    border-radius: 16px;
    transition: transform 0.4s ease;
    transform: translateX(${(props) => (props.active === 0 ? '0%' : '100%')});
  }
`

export const Button = styled.button<{ isActive: boolean }>`
  display: flex;
  width: 159px;
  height: 40px;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex: 1;
  border: none;
  border-radius: 16px;
  background: transparent;

  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: ${(props) =>
    props.isActive ? '#f6f6f6' : '#8e92bc'}; // Cambiar color de texto
  transition: color 0.4s ease;
  z-index: 2;

  &:focus {
    outline: none;
  }
`
