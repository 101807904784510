import styled from 'styled-components'
import '@fontsource/plus-jakarta-sans'

export const BonusFilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  background-color: #fdfdfd;
  padding: 32px 64px 24px 24px;
  border-radius: 10px;
`
