import React, { Dispatch, SetStateAction } from 'react'

import { Dayjs } from 'dayjs'
import { RiSearchLine } from 'react-icons/ri'

import Input from '../inputs/Input/Input'

import { SearchContainer } from './styles'

interface inputData {
  width?: string
  type?: string
  hasIcon?: boolean
  placeholder?: string
  min?: string | Dayjs | undefined
  max?: string | Dayjs | undefined
  classSearch?: string
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
}

const Search = ({
  width,
  type,
  hasIcon = true,
  placeholder,
  min,
  max,
  classSearch,
  searchValue,
  setSearchValue,
}: inputData) => {
  return (
    <SearchContainer className={classSearch} width={width}>
      <Input
        value={searchValue}
        type={type ? type : 'text'}
        name="search"
        id="search"
        onChange={(e) => setSearchValue(e.target.value)}
        inputClass="search-input"
        width={width}
        min={min && min}
        max={max && max}
        placeholder={placeholder}
      />
      {hasIcon && (
        <span>
          <RiSearchLine />
        </span>
      )}
    </SearchContainer>
  )
}

export default Search
