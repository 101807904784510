import React, { useRef, DragEvent, useState } from 'react'

import imageCompression from 'browser-image-compression'
import dayjs from 'dayjs'
import { MdOutlineAddAPhoto } from 'react-icons/md'
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { useStorage } from '../../context'
import Spinner from '../Spinner/Spinner'
import { LightGreyButton } from '../buttons'
import { Spacer } from '../global'
import Modal from '../modal/Modal'
import { SectionSubtitle } from '../texts'
import { Profesor, Student } from '../types'

import { DragAndDropContainer, EditImageModal, Image } from './styles'

const DragAndDrop = ({
  type,
  image,
  editedProfile,
  handleImageChange,
  onEditionMode,
}: {
  type: string
  image: string
  editedProfile: Student | Profesor
  handleImageChange: (
    filterName: string,
    value: string | string[] | null,
  ) => void
  onEditionMode: boolean
}) => {
  const now = dayjs().format()
  const { uploadProfileImage, error, loadingStorage } = useStorage()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [dragging, setDragging] = useState(false)
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const [crop, setCrop] = useState<Crop>()
  const [croppedImage, setCroppedImage] = useState<string | null>(null)
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null)
  const [openEditionModal, setOpenEditionModal] = useState<boolean>(false)

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragging(true)
  }

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragging(false)
  }

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleFiles = (file: File | undefined) => {
    if (file) {
      const reader = new FileReader()

      reader.onload = (e) => {
        if (e.target) {
          const imageSrc = e.target?.result as string
          setSelectedImage(imageSrc)
          setOpenEditionModal(true)
        }
      }

      reader.readAsDataURL(file)
    }
  }

  const handleImageLoaded = (image: HTMLImageElement) => {
    setImageRef(image)
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragging(false)
    const file = e.dataTransfer.files?.[0]
    handleFiles(file)
  }

  const getCroppedImg = async (crop: PixelCrop) => {
    if (imageRef && crop.width && crop.height) {
      const canvas = document.createElement('canvas')
      const scaleX = imageRef.naturalWidth / imageRef.width
      const scaleY = imageRef.naturalHeight / imageRef.height
      canvas.width = crop.width
      canvas.height = crop.height
      const ctx = canvas.getContext('2d')

      if (ctx) {
        ctx.drawImage(
          imageRef,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height,
        )

        const base64Image = canvas.toDataURL('image/jpeg')
        const compressedImage = await imageCompression.canvasToFile(
          canvas,
          'image/jpeg',
          now,
          0.7,
        )
        if (compressedImage.size > 500 * 1024) {
          const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 500,
            useWebWorker: true,
          }
          const compressedFile = await imageCompression(
            compressedImage,
            options,
          )
          const reader = new FileReader()
          reader.readAsDataURL(compressedFile)
          reader.onloadend = () => {
            setCroppedImage(reader.result as string)
          }
        } else {
          setCroppedImage(base64Image)
        }
      }
    }
  }

  const handleUpload = async () => {
    const imageToUpload = croppedImage || selectedImage
    const { role } = editedProfile
    if (imageToUpload) {
      const blob = await fetch(imageToUpload).then((res) => res.blob())
      const _role =
        role === 'professor'
          ? 'professors'
          : role === 'student'
            ? 'students'
            : role
      await uploadProfileImage(editedProfile.uid, blob, _role)
      handleImageChange('image', imageToUpload)
    }

    setOpenEditionModal(false)
    setSelectedImage(null)
    setCroppedImage(null)
    setImageRef(null)
  }

  return (
    <DragAndDropContainer
      className={`drop-zone ${dragging ? 'dragging' : ''}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {type === 'image' && image && (
        <Image $image={image} onClick={handleButtonClick}>
          {onEditionMode && (
            <div className="overlay">
              <p>
                <MdOutlineAddAPhoto /> <br />
                Cambiar Imagen
              </p>
            </div>
          )}
        </Image>
      )}

      <input
        type="file"
        ref={onEditionMode ? fileInputRef : null}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={(e) => handleFiles(e.target.files?.[0])}
      />

      {openEditionModal && selectedImage !== null && (
        <Modal onClose={() => setOpenEditionModal(false)}>
          {loadingStorage ? (
            <Spinner />
          ) : (
            <EditImageModal>
              <Spacer height={20} />
              {error ? (
                <SectionSubtitle> {error} </SectionSubtitle>
              ) : (
                <>
                  <SectionSubtitle fontSize={16} textalign="center">
                    Quieres recortar la imagen?
                  </SectionSubtitle>
                  <Spacer height={20} />
                  <ReactCrop
                    crop={crop}
                    onChange={(newCrop) => setCrop(newCrop)}
                    onComplete={getCroppedImg}
                  >
                    <img
                      src={selectedImage}
                      alt="Cropped"
                      onLoad={(e) => handleImageLoaded(e.currentTarget)}
                    />
                  </ReactCrop>
                  <Spacer height={20} />

                  <LightGreyButton
                    onClick={handleUpload}
                    fontSize={16}
                    fontWeight={400}
                  >
                    Aceptar
                  </LightGreyButton>
                </>
              )}
            </EditImageModal>
          )}
        </Modal>
      )}
    </DragAndDropContainer>
  )
}

export default DragAndDrop
