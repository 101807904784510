import React, { Dispatch, SetStateAction } from 'react'

import { FaCircle } from 'react-icons/fa6'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

import { useStorage } from '../../context'
import IconButton from '../IconButton/IconButton'
import { SectionSubtitle } from '../texts'

import {
  CalendarHeaderYear,
  CalendarBodyMonths,
  CalendarContainer,
  CalendarRow,
  CalendarDisclaimer,
} from './styles'

const YearMonthCalendar = ({
  date,
  setDate,
}: {
  date: { month: string; year: number }
  setDate: Dispatch<SetStateAction<{ month: string; year: number }>>
}) => {
  const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ]
  const { invoicesList } = useStorage()
  const selectNextYear = () => {
    const newYear = Number(date.year) + 1

    setDate({
      ...date,
      year: newYear,
    })
  }

  const selectPreviousYear = () => {
    const newYear = Number(date.year) - 1
    setDate({
      ...date,
      year: newYear,
    })
  }

  const hasInvoice = (_month: string, _year: number) => {
    const foundInvoice = invoicesList?.find((item) => item.year === _year)
    if (foundInvoice) {
      const foundMonth = foundInvoice.months.find(
        (month) => month.month === _month,
      )
      if (foundMonth) {
        return true
      }
    }
  }

  return (
    <CalendarContainer>
      <div style={{ padding: '18px 24px 10px 24px' }}>
        <SectionSubtitle fontSize={14} fontWeight={500} lineHeight={20}>
          <>
            <span style={{ textTransform: 'capitalize' }}>{date.month} </span>
            de {date.year}
          </>
        </SectionSubtitle>
      </div>
      <CalendarRow>
        <div>
          <CalendarHeaderYear>
            <IconButton
              fontSize={20}
              Icon={MdKeyboardArrowLeft}
              iconColor="#8E92BC"
              onClick={selectPreviousYear}
            ></IconButton>
            <SectionSubtitle fontSize={12} lineHeight={16} fontWeight={400}>
              {date.year.toString()}
            </SectionSubtitle>
            <IconButton
              fontSize={20}
              Icon={MdKeyboardArrowRight}
              iconColor="#8E92BC"
              onClick={selectNextYear}
            ></IconButton>
          </CalendarHeaderYear>

          <CalendarBodyMonths>
            {months.map((item, i) => {
              return (
                <div
                  key={i}
                  className={`${hasInvoice(item, date.year) ? 'blocked' : ''} ${date.month === item ? 'active' : ''}`}
                  onClick={
                    hasInvoice(item, date.year)
                      ? undefined
                      : () => {
                          setDate({ month: item, year: date.year })
                        }
                  }
                >
                  <p> {item.slice(0, 3)} </p>
                </div>
              )
            })}
          </CalendarBodyMonths>
        </div>
        <CalendarDisclaimer>
          <div>
            <FaCircle color="#08a8d2" />
            <p>Mes activo</p>
          </div>
          <div>
            <FaCircle color="#e5e5e5" />
            <p>Mes con nomina</p>
          </div>
        </CalendarDisclaimer>
      </CalendarRow>
    </CalendarContainer>
  )
}

export default YearMonthCalendar
