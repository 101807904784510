import styled from 'styled-components'
import '@fontsource/plus-jakarta-sans'

export const ContentainerCourseInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: flex-start;
  width: 100%;
  @media (max-width: 768px) {
    gap: 19px;
    justify-content: center;
  }
`

//Card
export const ContentCardCourse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  width: 328px;
  height: 314px;
  border-radius: 10px;
  background-color: #fdfdfd;
  padding: 24px 24px 8px;

  @media (max-width: 768px) {
    width: 90%;
  }
`
export const ContentImage = styled.div<{ img: string }>`
  display: flex;
  flex-direction: column;
  background-image: url(${(props) => props.img});
  width: 100%;
  height: 125px;
  padding: 5px 8px;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: flex-end;
  background-position: center;
  background-size: cover;
`
export const ContentTitle = styled.div`
  padding: 0px 4px;
  margin-bottom: 9px;
  p {
    color: #0a0a18;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.32px;
  }
`
export const ContentLevel = styled.div<{ level: string }>`
  padding: 0px 4px;
  margin-bottom: 9px;
  p {
    color: ${(props) =>
      props.level.includes('Intermedio') ? '#ef914c' : '#34a853'};

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
  }
`

export const ContentAvailability = styled.div`
  display: inline-flex;
  border-radius: 10px;
  padding: 4px 19px;
  background-color: #ffdc50;

  p {
    color: #0a0a18;
    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 2px;
  }
`

export const ContentDescription = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding-left: 4px;
  margin-bottom: 18px;
  p {
    color: #8e92bc;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
  }
`
export const ContentBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  .first-content {
    display: flex;
    align-items: center;
  }
  svg {
    width: 24px;
    height: 24px;
    color: #8e92bc;
    margin-right: 8px;
  }
  .hours {
    color: #0a0a18;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
  }
  .name-button {
    font-size: 14px;
  }
`
