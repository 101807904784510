import React, { ChangeEvent, useState } from 'react'

import { Spacer } from '../../global'
import { Input } from '../../inputs'
import { SectionSubtitle } from '../../texts'
import { ChangeEmailContainer } from '../styles'

const ChangeEmailPart = ({
  currentEmail,
  credentials,
  handleChange,
  inputClass,
}: {
  currentEmail: string
  credentials: {
    currentPassword: string
    password: string
    confirmPassword: string
    email: string
    confirmEmail: string
  }
  handleChange: (value: string, filterName: string) => void
  inputClass?: string
}) => {
  const [error, setError] = useState<string | null>(null)

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target

    const emailPattern =
      /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i.test(
        value,
      )

    if (name === 'email' && !emailPattern) {
      setError('No es un email valido')
    }

    if (name === 'confirmEmail' && credentials.email !== value) {
      setError('Los emails no coinciden')
    }

    handleChange(value, name)
    setError(null)
  }

  return (
    <ChangeEmailContainer>
      <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={24}>
        Email
      </SectionSubtitle>
      <Spacer height={20} />
      <SectionSubtitle fontWeight={500} fontSize={12}>
        Email
      </SectionSubtitle>
      <Spacer height={10} />

      <Input
        id="email"
        name="email"
        type="email"
        placeholder={currentEmail}
        // value={credentials.email}
        onChange={handleEmailChange}
        inputClass={inputClass}
      />
      <Spacer height={20} />
      <SectionSubtitle fontWeight={500} fontSize={12}>
        Confirmar email
      </SectionSubtitle>
      <Spacer height={10} />
      <Input
        id="confirmEmail"
        name="confirmEmail"
        type="email"
        placeholder={currentEmail}
        // value={credentials.confirmEmail}
        onChange={handleEmailChange}
        inputClass={inputClass}
      />
      {error && (
        <SectionSubtitle fontSize={12}>
          <>{error}</>
        </SectionSubtitle>
      )}
    </ChangeEmailContainer>
  )
}

export default ChangeEmailPart
