import React, { ReactElement } from 'react'

import { SectionSubtitles } from './styles'

const SectionSubtitle = ({
  fontSize,
  fontWeight,
  id,
  classSub,
  lineHeight,
  textalign,
  children,
  onClick,
}: {
  fontSize?: number
  fontWeight?: number
  id?: string
  classSub?: string
  lineHeight?: number
  textalign?: string
  children?: ReactElement | string | string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <SectionSubtitles
      id={id}
      className={classSub}
      fontSize={fontSize}
      fontWeight={fontWeight}
      $lineHeight={lineHeight}
      $textalign={textalign}
      onClick={onClick ? handleClick : undefined}
    >
      {children}
    </SectionSubtitles>
  )
}

export default SectionSubtitle
