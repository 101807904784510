import React, { useState, Dispatch } from 'react'

import { AiOutlinePlus } from 'react-icons/ai'

import { useInputReset } from '../../../../../hooks'
import { DarkGreyButton, LightGreyButton } from '../../../../buttons'
import { Spacer } from '../../../../global'
import { SectionSubtitle } from '../../../../texts'
import { WeeklyTheme } from '../../../../types'

import DisplayWeekTask from './section-display/DisplayWeekTask'
import InputContent from './section-input/InputContent'
import InputHomework from './section-input/InputHomework'
import MainInput from './section-input/MainInput'
import { InputWeekContainer, ContentContainer } from './styles'

const InputWeek = ({
  weekNumber,
  weekTasks,
  weekInformation,
  handleWeekTask,
  weeksData,
  setWeeksData,
  setWeekInformation,
}: {
  weekNumber: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  weekTasks: any
  weekInformation: WeeklyTheme
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleWeekTask: (tasks: any) => void
  weeksData: WeeklyTheme[]
  setWeeksData: Dispatch<React.SetStateAction<WeeklyTheme[]>>
  setWeekInformation: (value: React.SetStateAction<WeeklyTheme>) => void
}) => {
  const { resetInput } = useInputReset()
  const [isFocused, setIsFocused] = useState(false)
  const [content, setContent] = useState<{
    contentTitle: string
    contentLink: string
    contentDescription: string
  }>({
    contentTitle: '',
    contentLink: '',
    contentDescription: '',
  })
  const [showHomework, setShowHomework] = useState(false)
  const [showContent, setShowContent] = useState(false)
  const [showContentAndTask, setShowContentAndTask] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleWeekContent = (content: any) => {
    setWeekInformation((prevWeekInformation) => ({
      ...prevWeekInformation,
      content: [...(prevWeekInformation.content || []), content],
    }))
    resetInput('contentTitle')
    resetInput('contentLink')
    resetInput('contentDescription')
  }

  const hideWeek = (id: number) => {
    // aca tengo que esconder la semana en el curso
    console.log(id)
  }
  const deleteWeek = (id: number) => {
    const _list = weeksData.filter((item) => item.id !== id)
    setWeeksData(_list)
  }

  return (
    <InputWeekContainer isFocused={isFocused}>
      <MainInput
        weeksData={weeksData}
        weekInformation={weekInformation}
        weekNumber={weekNumber}
        hideWeek={hideWeek}
        deleteWeek={deleteWeek}
        isFocused={isFocused}
        setIsFocused={setIsFocused}
        showContent={showContent}
        setWeekInformation={setWeekInformation}
        setShowContent={setShowContent}
      />
      {showContent && (
        <>
          <ContentContainer>
            <Spacer height={48} />
            <SectionSubtitle fontSize={16} fontWeight={600}>
              Contenido
            </SectionSubtitle>
            <div className="space-34"></div>
            <InputContent
              content={content}
              weekInformation={weekInformation}
              showContentAndTask={showContentAndTask}
              saveContent={setContent}
              setShowContentAndTask={setShowContentAndTask}
            />

            {showContentAndTask && (
              <>
                <div className="space-34"></div>
                {(weekTasks.length > 0 || showHomework) && (
                  <>
                    <SectionSubtitle fontSize={16} fontWeight={600}>
                      Tarea para el alumno
                    </SectionSubtitle>
                    <div className="space-34"></div>
                  </>
                )}
                {weekTasks.length > 0 && (
                  <DisplayWeekTask weekTasks={weekTasks} />
                )}
                {showHomework ? (
                  <InputHomework
                    showHomework={showHomework}
                    saveTask={handleWeekTask}
                    setShowHomework={setShowHomework}
                  />
                ) : (
                  <LightGreyButton
                    onClick={() => setShowHomework(!showHomework)}
                    fontSize={12}
                    fontWeight={500}
                  >
                    <AiOutlinePlus /> Tarea
                  </LightGreyButton>
                )}
                <div className="space-34"></div>
                {/* ESTO LO QUE HACE ES CORROBORAR QUE LOS CAMPOS NO ESTEN EMPTY - GUARDA EL CONTENIDO*/}

                <div
                  className="d-flex justify-content-center"
                  style={{ marginBottom: '40px' }}
                >
                  <DarkGreyButton
                    onClick={() => handleWeekContent(content)}
                    width={162}
                    isDisabled={
                      showHomework || content.contentTitle === undefined
                    }
                  >
                    Guardar Contenido
                  </DarkGreyButton>
                </div>
              </>
            )}
          </ContentContainer>
          {(!showContentAndTask || weekInformation.weekTitle === 0) && (
            <>
              <div className="space-34"></div>
              <LightGreyButton
                fontSize={12}
                fontWeight={500}
                onClick={() => saveContent}
              >
                <AiOutlinePlus /> Contenido
              </LightGreyButton>
            </>
          )}

          <div className="space-34"></div>
          <div className="space-34"></div>
        </>
      )}
    </InputWeekContainer>
  )
}

export default InputWeek
