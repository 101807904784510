import React from 'react'

import { Profesor, Student } from '../../types'

import { ListContainer, ItemWithImage, Photo } from './styles'

const ListWithImage = ({
  disabled,
  list,
  activeId,
  onClick,
  hasSeparation = true,
}: {
  disabled: boolean
  list: Array<Profesor | Student> | null
  activeId?: string | null
  onClick: (person: Student | Profesor) => void
  hasSeparation?: boolean
}) => {
  return (
    <ListContainer>
      {list &&
        list.map((item, i) => {
          return (
            <ItemWithImage
              className={`${disabled ? 'disabled' : ''} ${activeId === item.uid ? 'active' : ''}`}
              key={i}
              onClick={() => onClick(item)}
              $hasSeparation={hasSeparation}
            >
              <Photo
                $image={
                  item.image && item.image.length > 0
                    ? item.image
                    : '/assets/profile.png'
                }
              />

              <p className="name">
                {item.name} {item.lastname}
              </p>
            </ItemWithImage>
          )
        })}
    </ListContainer>
  )
}

export default ListWithImage
