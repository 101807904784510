import React, { Dispatch, SetStateAction, useState } from 'react'

import { useUser } from '../../../context'
import { DarkGreyButton, LightBlueButton } from '../../buttons'
import { Spacer } from '../../global'
import { SectionSubtitle } from '../../texts'
import { Student, Profesor } from '../../types'
import { ChangeCredentialsContainer } from '../styles'

import ChangeEmailPart from './ChangeEmailPart'
import ChangePasswordPart from './ChangePasswordPart'

const CredentialsSection = ({
  type,
  editedProfile,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleChangeEmail,
  showCredentials,
  setShowCredentials,
}: {
  type: string | null
  editedProfile: Student | Profesor | null
  handleChangeEmail: (value: string) => void
  showCredentials: boolean
  setShowCredentials: Dispatch<SetStateAction<boolean>>
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { changeEmail, changePassword } = useUser()

  const [credentials, setCredentials] = useState({
    currentPassword: '',
    password: '',
    confirmPassword: '',
    email: '',
    confirmEmail: '',
  })

  const handleCredentials = (value: string, filterName: string) => {
    setCredentials({ ...credentials, [filterName]: value })
  }

  const disabledButton =
    credentials.currentPassword === '' ||
    credentials.password === '' ||
    credentials.email === '' ||
    credentials.email !== credentials.confirmEmail ||
    credentials.password !== credentials.confirmPassword
      ? true
      : false

  const handleSaveCredentials = async () => {
    editedProfile && (await changeEmail(credentials.email, editedProfile.role))
    // await changePassword()

    //hacer la llamada a firebase para cambiar las credenciales
    // handleChangeEmail(credentials.email)
  }
  return (
    editedProfile && (
      <>
        {!showCredentials ? (
          <LightBlueButton
            onClick={() => setShowCredentials(!showCredentials)}
            buttonClass="change-credentials-button"
          >
            Cambiar credenciales de acceso
          </LightBlueButton>
        ) : (
          <>
            <Spacer height={40} />
            <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={21}>
              Credenciales de acceso
            </SectionSubtitle>

            <>
              <Spacer height={20} />
              <SectionSubtitle fontWeight={500} fontSize={14} lineHeight={21}>
                Estas por cambiar tus credenciales de acceso
              </SectionSubtitle>
            </>

            <Spacer height={20} />
            <ChangeCredentialsContainer>
              <ChangePasswordPart
                formType={type}
                credentials={credentials}
                handleChange={handleCredentials}
                inputClass="profile-input"
              />

              <ChangeEmailPart
                currentEmail={editedProfile.email}
                credentials={credentials}
                handleChange={handleCredentials}
                inputClass="profile-input"
              />
            </ChangeCredentialsContainer>
            <Spacer height={32} />

            {/* CAMBIAR PARA QUE ESTE SEPARADO ACTUALIZAR EL EMAIL DE LA CONTRASEÑA */}
            <div className="d-flex justify-content-center">
              <DarkGreyButton
                buttonClass="save-profile-button"
                onClick={handleSaveCredentials}
                isDisabled={disabledButton}
              >
                Actualizar credenciales
              </DarkGreyButton>
            </div>
          </>
        )}
      </>
    )
  )
}

export default CredentialsSection
