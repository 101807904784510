import React from 'react'

import { CardRoutineProps } from '../../types'
import {
  CardContainer,
  ImageRutine,
  CardRutineText,
  CardEdition,
  CardsContainer,
} from '../styles'

const CardRutine = ({
  image,
  title,
  subtitle,
  showCoursesDetails,
}: CardRoutineProps) => {
  return (
    <CardsContainer>
      <CardContainer $showCoursesDetails={showCoursesDetails}>
        {image && (
          <ImageRutine style={{ backgroundImage: 'url(' + image + ')' }} />
        )}
        <CardRutineText>
          <div className="title">{title}</div>
          {subtitle && <div className="description">{subtitle}</div>}
        </CardRutineText>
        {showCoursesDetails && (
          <CardEdition>
            <div className="editionButton">
              <img src="/assets/icons/layers.svg" alt="" />
            </div>
            <div className="editionButton">
              <img src="/assets/icons/edit.svg" alt="" />
            </div>
            <div className="editionButton">
              <img src="/assets/icons/delete.svg" alt="" />
            </div>
          </CardEdition>
        )}
      </CardContainer>
    </CardsContainer>
  )
}

export default CardRutine
