import React, { useRef, useState } from 'react'

import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'

import { Spacer } from '../../../../../global'
import { SectionSubtitle } from '../../../../../texts'
import { WeeklyTheme } from '../../../../../types'
import {
  ContentContainer,
  MainInputContainer,
  WeekNumber,
  WeekTitle,
  WeeksDisplayContainer,
} from '../styles'

import DisplayContentData from './DisplayContentData'
import DisplayWeekTask from './DisplayWeekTask'

const DisplayWeekData = ({ weeksData }: { weeksData: WeeklyTheme[] }) => {
  const [visibleWeeks, setVisibleWeeks] = useState<number[]>([])
  const mainInputRefs = useRef<Array<HTMLDivElement | null>>(
    Array(weeksData.length).fill(null),
  )

  const handleClick = (id: number) => {
    if (mainInputRefs.current[id]) {
      mainInputRefs.current[id]?.focus()
    }
  }

  const showContent = (id: number) => {
    setVisibleWeeks((prevVisibleWeeks) => {
      if (prevVisibleWeeks.includes(id)) {
        return prevVisibleWeeks.filter((weekId: number) => weekId !== id)
      } else {
        return [...prevVisibleWeeks, id]
      }
    })
  }

  return (
    <WeeksDisplayContainer>
      {weeksData.length > 0 &&
        weeksData.map((week, i) => {
          return (
            <>
              <MainInputContainer
                className={`week-${week.id} ${
                  visibleWeeks.includes(week.id) ? 'active' : 'inactive'
                }`}
                key={week.id}
                ref={(el) => (mainInputRefs.current[i] = el)}
                tabIndex={0}
                onClick={() => handleClick(week.id)}
              >
                <WeekNumber> Semana {week.id}</WeekNumber>
                <WeekTitle>{week.weekTitle}</WeekTitle>

                <div className="arrow" onClick={() => showContent(week.id)}>
                  {visibleWeeks.includes(week.id) ? (
                    <IoIosArrowUp />
                  ) : (
                    <IoIosArrowDown />
                  )}
                </div>
              </MainInputContainer>
              {visibleWeeks.includes(week.id) && (
                <>
                  {week.content.length > 0 && (
                    <ContentContainer>
                      <Spacer height={16} />
                      <SectionSubtitle fontSize={16} fontWeight={600}>
                        Contenido
                      </SectionSubtitle>
                      <Spacer height={24} />
                      <DisplayContentData content={week.content} />
                    </ContentContainer>
                  )}

                  {week.task && (
                    <ContentContainer>
                      <SectionSubtitle fontSize={16} fontWeight={600}>
                        Tarea para el alumno
                      </SectionSubtitle>
                      <Spacer height={16} />

                      <DisplayWeekTask weekTasks={week.content} />
                    </ContentContainer>
                  )}
                </>
              )}
            </>
          )
        })}
    </WeeksDisplayContainer>
  )
}

export default DisplayWeekData
