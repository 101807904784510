import styled from 'styled-components'

export const ContainerWelcome = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 0px 60px;
  height: 100%;
  @media (max-width: 768px) {
    padding: 0px 10px;
  }
`
export const ContainerTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`
export const ContainerBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`

export const Title = styled.h1`
  color: #313131;
  text-align: center;

  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 54px */
  letter-spacing: -1.08px;
  margin-bottom: 14px;
  margin-top: -20px;
  @media (max-width: 768px) {
    font-size: 26px;
    margin-bottom: 10px;
  }
`
export const SubTitle = styled.p`
  color: var(--Gris-5, #313131);
  text-align: center;
  /* Medium/Type@24 */

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */
  letter-spacing: -0.48px;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 22px;
  }
`
export const Description = styled.p`
  color: #0a0a18;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.28px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`
export const ContainerButtos = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
  .button-other-moment {
    display: flex;
    width: 168px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: var(--Fondo-3, #f6f6f6);
    @media (max-width: 768px) {
      width: 135px;
      height: 30px;
    }
  }
  .button-continue {
    display: flex;
    width: 168px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: rgba(49, 49, 49, 0.9);
    @media (max-width: 768px) {
      width: 135px;
      height: 30px;
    }
  }
  .other {
    color: var(--Gris-5, #313131);
    text-align: center;
    /* Semibold/Type@14 */

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
    @media (max-width: 768px) {
      font-size: 11px;
    }
  }
  .continue {
    color: #dbdbdb;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    letter-spacing: -0.38px;
    @media (max-width: 768px) {
      font-size: 11px;
    }
  }
`
