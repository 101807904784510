import { Navigate, Route, Routes } from 'react-router-dom'

import { Login, RecoverPassword, RegistrationFormPage } from '../pages'

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/login-admin" element={<Login />} />
      <Route path="/login-profesores" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/recuperar-contraseña" element={<RecoverPassword />} />

      <Route path="/sign-in" element={<RegistrationFormPage />} />

      {/* Ruta por defecto para rutas desconocidas */}
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  )
}
