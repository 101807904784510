import React from 'react'

import { LightGreyButton } from '../buttons'
import { Spacer } from '../global'
import { SectionSubtitle } from '../texts'
import { AdminNewUser } from '../types'

const NewUserSection = ({
  editedProfile,
  handleMenuChange,
}: {
  editedProfile: AdminNewUser

  handleMenuChange: (
    filterName: string,
    value: string | string[] | null,
  ) => void
}) => {
  return (
    editedProfile && (
      <>
        <Spacer height={40} />
        <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={21}>
          Rol
        </SectionSubtitle>
        <Spacer height={16} />
        <div className="buttons">
          <LightGreyButton
            fontSize={12}
            fontWeight={400}
            onClick={() => handleMenuChange('role', 'admin')}
            buttonClass={editedProfile.role === 'admin' ? 'active' : ''}
          >
            Administrador
          </LightGreyButton>
          <LightGreyButton
            fontSize={12}
            fontWeight={400}
            onClick={() => handleMenuChange('role', 'professor')}
            buttonClass={editedProfile.role === 'professor' ? 'active' : ''}
          >
            Profesor
          </LightGreyButton>
          <LightGreyButton
            fontSize={12}
            fontWeight={400}
            onClick={() => handleMenuChange('role', 'student')}
            buttonClass={editedProfile.role === 'student' ? 'active' : ''}
          >
            Alumno
          </LightGreyButton>
        </div>
      </>
    )
  )
}

export default NewUserSection
