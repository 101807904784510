import React from 'react'

import { SectionSubtitle } from '..'
import ActiveBonus from '../lists/students/ActiveBonus'
import PassBonus from '../lists/students/PassBonus'
import { Cupon, Student } from '../types'

const StuBonus = ({
  studentCupons,
  activeStudent,
}: {
  studentCupons: Cupon[]
  activeStudent: Student
}) => {
  return (
    <>
      <SectionSubtitle fontWeight={400} fontSize={14} lineHeight={22}>
        Bonos activos
      </SectionSubtitle>

      <ActiveBonus
        couponList={studentCupons}
        hasActive={activeStudent.hasActiveBonus}
      />

      <SectionSubtitle fontWeight={400} fontSize={14} lineHeight={22}>
        Bonos finalizados o vencidos
      </SectionSubtitle>

      <PassBonus
        couponList={studentCupons}
        hasActive={activeStudent.hasActiveBonus}
      />
    </>
  )
}

export default StuBonus
