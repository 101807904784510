import React, { SetStateAction, Dispatch } from 'react'

import { Input } from '../..'
import { QuotesProps } from '../../types'

import { CheckboxContainer } from './styles'

const InputMoney = ({
  number,
  quotasValue,
  setQuotasValue,
}: {
  number: number
  quotasValue: QuotesProps[]
  setQuotasValue: Dispatch<SetStateAction<QuotesProps[]>>
}) => {
  const handleBlur = () => {
    const elements = document.getElementsByClassName('quotes-input')

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('active')
    }
  }

  const handleFocus = (index: number) => {
    handleBlur()
    const element = document.getElementById(index.toString())
    element !== null && element.classList.add('active')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePriceChange = (e: any, index: number) => {
    const quoteNumber = index + 1
    const quoteValue = e.target.value
    const existingIndex = quotasValue.findIndex(
      (value) => value.quoteNumber === quoteNumber,
    )
    const checkbox = document.getElementById(
      `quotasValue-${index}`,
    ) as HTMLInputElement

    // const element = document.getElementById(`quote-${index}`) as HTMLElement

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setQuotasValue((prevState: any) => {
      if (existingIndex !== -1) {
        if (quoteValue.length === 0) {
          checkbox.checked = false
          // element.classList.add("inactive");
          return prevState.length < 1
            ? []
            : prevState.filter(
                (item: { quoteNumber: number }) =>
                  item.quoteNumber !== quoteNumber,
              )
        } else {
          const updatedValues = [...prevState]
          updatedValues[existingIndex] = {
            quoteNumber,
            quotePrice: quoteValue,
          }
          return updatedValues
        }
      } else {
        checkbox.checked = true
        // element.classList.remove("inactive");
        return [
          ...prevState,
          { quoteNumber: quoteNumber, quotePrice: quoteValue },
        ]
      }
    })
  }

  const handleCheckbox = (index: number) => {
    const quoteNumber = index + 1
    const checkbox = document.getElementById(
      `quotasValue-${index}`,
    ) as HTMLInputElement
    const input = document.getElementById(
      `quote-price${index}`,
    ) as HTMLInputElement

    if (!checkbox.checked) {
      checkbox.checked = false
      input.value = ''

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return setQuotasValue((prevState: any[]) => {
        return prevState.length < 1
          ? []
          : prevState.filter(
              (item: { quoteNumber: number }) =>
                item.quoteNumber !== quoteNumber,
            )
      })
    }
  }

  return (
    <div className="quotes-input" id={number.toString()}>
      <Input
        type="number"
        max={9999}
        name="quote-price"
        id={`quote-price${number}`}
        inputClass="quote-price"
        onChange={(e) => handlePriceChange(e, number)}
        onBlur={handleBlur}
        onFocus={() => handleFocus(number)}
      />

      <span className="unit">€</span>
      <CheckboxContainer>
        <Input
          type="checkbox"
          name={`quotasValue-${number}`}
          id={`quotasValue-${number}`}
          onChange={() => handleCheckbox(number)}
        />
        <label htmlFor={`quotasValue-${number}`}></label>
      </CheckboxContainer>
    </div>
  )
}

export default InputMoney
