import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const NavbarContainer = styled.nav<{ $isOpen: boolean }>`
  width: 100%;
  height: 100vh;
  background-color: #fdfdfd;
  display: flex;
  padding-top: 32px;
  padding-bottom: 73px;
  align-items: center;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    transform: translateX(${(props) => (props.$isOpen ? '0' : '-100%')});
  }
`

export const ToggleButton = styled.button`
  position: fixed;
  top: 15px;
  left: 15px;
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1001;

  @media (min-width: 769px) {
    display: none;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  width: 193px;
  height: 40px;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 60px;

  img {
    width: 40px;
    height: 40px;
  }

  p {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;

    @media screen and (max-width: 1024px) {
      font-size: 27px;
    }
  }
`

export const NavbarLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 192px;
  gap: 24px;
  justify-content: flex-start;

  .nav-link {
    width: 192px;
    padding: 10px 20px;
    display: flex;
    gap: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #8e92bc;
    border-radius: 10px;
    align-items: center;

    svg {
      font-size: 24px;
    }

    &.active {
      background: #f6f6f6;
      color: #0a0a18;

      svg {
        color: #08a8d2;
      }
    }

    &:hover {
      background: #f6f6f6;
    }
  }
`
export const NavbarLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover,
  &:focus {
    background: #f6f6f6;
  }
`
export const HelpContainer = styled.div``
