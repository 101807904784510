import React, { useState } from 'react'

import { LightBlueButton } from '../../../buttons'
import { DropdownList } from '../../../dropdowns'
import { PeopleSectionProps, Person } from '../../../types'
import { SectionContainer, ListContainer, StudentsContainer } from '../styles'

const AddPeopleSection = ({
  title,
  professorsList,
  peopleList,
  setList,
}: PeopleSectionProps) => {
  const [personSearch, setPersonSearch] = useState('')
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const addPerson = (professor: Person) => {
    // Implement the logic to add the professor to the studentsList
    if (!peopleList.some((item) => item.uid === professor.uid)) {
      setList([...peopleList, professor])
    }
  }

  const removePerson = (id: string) => {
    // Implement the logic to remove the professor from the studentsList
    const _list = peopleList.filter((item) => item.uid !== id)
    setList(_list)
  }
  return (
    <SectionContainer
      direction="row"
      style={{
        marginBottom: '10px',
        gap: '20px',
        justifyContent: 'flex-start',
      }}
    >
      <ListContainer>
        <DropdownList
          type="add"
          text={`Agregar ${title}`}
          hasSearch={true}
          list={professorsList}
          searchValue={personSearch}
          setSearchValue={setPersonSearch}
          onSelect={addPerson}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        />
      </ListContainer>

      {peopleList.length > 0 && (
        <StudentsContainer>
          {peopleList.map((person: Person) => {
            return (
              <LightBlueButton
                onClick={() => removePerson(person.uid)}
                fontSize={12}
                fontWeight={400}
                height={32}
                key={person.uid}
              >
                {person.name} {person.lastname}
                <span className="cross">x</span>
              </LightBlueButton>
            )
          })}
        </StudentsContainer>
      )}
    </SectionContainer>
  )
}

export default AddPeopleSection
