import React from 'react'

const Classes = ({
  amountOfClasses,
  handleMenuChange,
  studentHasActiveBonus,
}: {
  amountOfClasses: number
  handleMenuChange: (filterName: string, value: number | boolean) => void
  studentHasActiveBonus: boolean
}) => {
  return (
    <>
      <div
        className={amountOfClasses === 1 ? 'menu-item active' : 'menu-item'}
        onClick={() => handleMenuChange('amountOfClasses', 1)}
      >
        1 Clase
      </div>
      {!studentHasActiveBonus && (
        <>
          <div
            className={amountOfClasses === 2 ? 'menu-item active' : 'menu-item'}
            onClick={() => handleMenuChange('amountOfClasses', 2)}
          >
            2 Clases
          </div>

          <div
            className={amountOfClasses === 4 ? 'menu-item active' : 'menu-item'}
            onClick={() => handleMenuChange('amountOfClasses', 4)}
          >
            4 Clases
          </div>
          <div
            className={amountOfClasses === 6 ? 'menu-item active' : 'menu-item'}
            onClick={() => handleMenuChange('amountOfClasses', 6)}
          >
            6 Clases
          </div>
          <div
            className={amountOfClasses === 8 ? 'menu-item active' : 'menu-item'}
            onClick={() => handleMenuChange('amountOfClasses', 8)}
          >
            8 Clases
          </div>
        </>
      )}
    </>
  )
}

export default Classes
