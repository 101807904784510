import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import { collection, deleteDoc, doc, setDoc } from 'firebase/firestore'

import { Profesor } from '../components/types'
import { firestore } from '../firebase/firebaseConfig'
import { subscribeToCollection, updateDocument } from '../firebase/utils'

interface ProfessorContextProps {
  professorsList: Profesor[] | null
  activeProfessor: Profesor | null
  setActiveProfessor: Dispatch<SetStateAction<Profesor | null>>
  setProfessorsList: Dispatch<SetStateAction<Profesor[] | null>>
  createProfessor: (newProfessor: Profesor) => Promise<void>
  saveProfessor: (
    updatedProfessor: Profesor,
    professorId: string,
  ) => Promise<void>
  deleteProfessor: (professorId: string) => Promise<void>
}

const ProfessorContext = createContext<ProfessorContextProps>({
  professorsList: null,
  activeProfessor: null,
  setActiveProfessor: () => Promise.resolve(null),
  setProfessorsList: () => Promise.resolve(null),
  createProfessor: () => Promise.resolve(),
  saveProfessor: () => Promise.resolve(),
  deleteProfessor: () => Promise.resolve(),
})

export const useProfessors = () => useContext(ProfessorContext)

export const ProfessorsProvider = ({ children }: { children: ReactNode }) => {
  const [activeProfessor, setActiveProfessor] = useState<Profesor | null>(null)
  const [professorsList, setProfessorsList] = useState<Profesor[] | null>(null)

  useEffect(() => {
    const unsubscribeProfessors = subscribeToCollection<Profesor>(
      'professors',
      setProfessorsList,
    )

    return () => unsubscribeProfessors()
  }, [])

  const createProfessor = async (newProfesor: Profesor) => {
    const newProfessorRef = doc(collection(firestore, 'professors'))

    await setDoc(newProfessorRef, { ...newProfesor, uid: newProfessorRef.id })
  }

  const saveProfessor = async (
    updatedProfessor: Profesor,
    professorId: string,
  ) => {
    await updateDocument<Profesor>('professors', professorId, updatedProfessor)
  }

  const deleteProfessor = async (professorId: string) => {
    await deleteDoc(doc(firestore, 'professors', professorId))
  }

  return (
    <ProfessorContext.Provider
      value={{
        professorsList,
        activeProfessor,
        setActiveProfessor,
        setProfessorsList,
        createProfessor,
        saveProfessor,
        deleteProfessor,
      }}
    >
      {children}
    </ProfessorContext.Provider>
  )
}
