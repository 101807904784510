import React, { useState } from 'react'

import { Outlet, useLocation } from 'react-router-dom'

import { Header } from '../components'
import BonoModal from '../components/BonoModal/BonoModal'
import { UserType } from '../components/types/enums'

import Admin from './administration/Admin'
import { InternalContent } from './styles'

const AdministrationPage = () => {
  const location = useLocation()

  const [modalStatus, setModalStatus] = useState(false)
  const [showCourseForm, setShowCourseForm] = useState(false)

  return (
    <>
      <Header
        title={UserType.admin}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
      />
      {modalStatus && (
        <BonoModal modalStatus={modalStatus} setModalStatus={setModalStatus} />
      )}
      <InternalContent>
        <Outlet />
        {location.pathname === '/' && (
          <Admin
            showCourseForm={showCourseForm}
            setShowCourseForm={setShowCourseForm}
          />
        )}
      </InternalContent>
    </>
  )
}

export default AdministrationPage
