import React, { Dispatch, SetStateAction, useState } from 'react'

import { SectionSubtitle, DarkGreyButton } from '../../../../..'
import { ContentLink } from '../styles'

const InputHomework = ({
  showHomework,
  saveTask,
  setShowHomework,
}: {
  showHomework: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveTask: (task: any) => void
  setShowHomework: Dispatch<SetStateAction<boolean>>
}) => {
  const [task, setTask] = useState<
    { taskLink: string; taskDescription: string }[]
  >([])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return
    }

    const { name, value } = event.target

    setTask((prevTask) => ({
      ...prevTask,
      [name]: value, // Update task if provided, otherwise keep the existing task array
    }))
  }

  const addTaskToList = () => {
    //aca reseteo la task y los divs y mando a save
    saveTask(task)
    setTask([])
    setShowHomework(!showHomework)
  }

  return (
    <ContentLink>
      <div className="space-16"></div>
      <div className="">
        <SectionSubtitle fontSize={16} fontWeight={500}>
          Archivo
        </SectionSubtitle>
        <div className="space-16"></div>
        <input
          className="input-link"
          name="taskLink"
          type="text"
          placeholder="Arrastra y pega aquí...."
          style={{ height: '40px' }}
          onChange={handleChange}
        />
        <div className="disclaimer">
          <p>
            O <span>subir archivo</span>
          </p>
        </div>
      </div>
      <div className="">
        <SectionSubtitle fontSize={16} fontWeight={500}>
          Consigna
        </SectionSubtitle>
        <div className="space-16"></div>
        <input
          type="text"
          name="taskDescription"
          placeholder="Escribe una descripción para la clase de la semana......"
          className="input-link"
          style={{ height: '144px' }}
          onChange={handleChange}
        />
      </div>
      <div className="space-16"></div>
      <div className="space-16"></div>
      <div
        className="d-flex justify-content-center"
        style={{ marginBottom: '40px' }}
      >
        <DarkGreyButton onClick={addTaskToList} width={162}>
          Guardar Tarea
        </DarkGreyButton>
      </div>
    </ContentLink>
  )
}

export default InputHomework
