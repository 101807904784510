import { Profesor, Student } from '../../types'

const FilteringList = <T extends Student | Profesor>(
  value: string,
  list: T[] | null,
): T[] | null => {
  const filteredStudents =
    list &&
    list.filter((person) => {
      if (
        person.name.toLowerCase().includes(value.toLowerCase()) ||
        person.lastname.toLowerCase().includes(value.toLowerCase())
      ) {
        return person
      }

      return false
    })
  return filteredStudents
}

export default FilteringList
