import React, { useEffect } from 'react'

import { useParams, useNavigate } from 'react-router-dom'

import {
  SectionSubtitle,
  InlineMenuNoButton,
  DetailPerson,
  Profile,
  ProfessorSharedProccess,
  LightGreyButton,
  ProfessorsList,
  ProfessorIndividualClass,
} from '../../components'
import { Spacer } from '../../components/global'
import TeacherInvoice from '../../components/invoice/TeacherInvoice'
import { AdminProfessorMenu, Profesor } from '../../components/types'
import { useCupons, useProfessors, useUser } from '../../context'

import { AdminsContainer } from './styles'

const Professors = () => {
  const navigate = useNavigate()
  const { part } = useParams<{ user: string; part: string }>()

  const { activeProfessor, setActiveProfessor, saveProfessor, professorsList } =
    useProfessors()
  const { onEditionMode, setOnEditionMode } = useUser()
  const { cupons, setProfessorCupons } = useCupons()

  const selectProfessor = (professor: Profesor) => {
    if (!onEditionMode) {
      setActiveProfessor(professor)

      navigate(`/profesores/${AdminProfessorMenu.activity.label}`)
    } else {
      setActiveProfessor(professor)
    }
  }

  const updateProfile = () => {
    if (activeProfessor) {
      saveProfessor(activeProfessor, activeProfessor.uid)
      setOnEditionMode(!onEditionMode)
    }
  }

  useEffect(() => {
    if (activeProfessor) {
      const filterCupons = cupons.filter(
        (item) => item.teacher === activeProfessor.uid,
      )

      setProfessorCupons(filterCupons)
    }
  }, [activeProfessor, cupons])

  useEffect(() => {
    if (activeProfessor === null && !onEditionMode && professorsList) {
      setActiveProfessor(professorsList[0])
      navigate(`/profesores/${AdminProfessorMenu.activity.label}`)
    }
  }, [])

  return (
    <AdminsContainer>
      <ProfessorsList />

      <div className="data">
        {activeProfessor && (
          <>
            {/* <Section> */}
            <InlineMenuNoButton
              parent="profesores"
              menu={AdminProfessorMenu}
              editing={onEditionMode}
            />
            {part !== AdminProfessorMenu.profile.label && (
              <DetailPerson person={activeProfessor} />
            )}

            <div className="section-title">
              <SectionSubtitle fontWeight={500} fontSize={16} lineHeight={24}>
                <>
                  <Spacer height={32} />
                  {part === AdminProfessorMenu.activity.label
                    ? AdminProfessorMenu.activity.description
                    : part === AdminProfessorMenu.profile.label
                      ? AdminProfessorMenu.profile.description
                      : AdminProfessorMenu.shared.description}
                  <Spacer height={20} />
                </>
              </SectionSubtitle>

              {part === AdminProfessorMenu.profile.label && (
                <LightGreyButton
                  fontSize={14}
                  padding="10px 30px"
                  fontWeight={400}
                  buttonClass={onEditionMode ? 'active-button' : ''}
                  onClick={updateProfile}
                >
                  {onEditionMode ? 'Guardar cambios' : 'Editar'}
                </LightGreyButton>
              )}
            </div>

            {part === AdminProfessorMenu.activity.label && (
              <>
                <ProfessorIndividualClass />
                {/* <Activities classesList={classesList} /> */}

                <Spacer height={50} />

                <TeacherInvoice />
              </>
            )}
            {part === AdminProfessorMenu.profile.label && (
              <Profile
                type="professor"
                editedProfile={activeProfessor}
                setEditedProfile={selectProfessor}
              />
            )}
            {part === AdminProfessorMenu.shared.label && (
              <>
                <Spacer height={32} />
                <ProfessorSharedProccess
                  professorId={activeProfessor.uid}
                  professorName={`${activeProfessor.name} ${activeProfessor.lastname}`}
                />
              </>
            )}
          </>
        )}
      </div>
    </AdminsContainer>
  )
}

export default Professors
