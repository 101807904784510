import React from 'react'

const DisplayContentData = ({
  content,
}: {
  content: {
    contentTitle: string
    contentLink: string
    contentDescription: string
  }[]
}) => {
  return (
    <div>
      {content.map((item) => {
        return (
          <div>
            <div>{item.contentTitle}</div>
            <div>{item.contentLink}</div>
            <div>{item.contentDescription}</div>
          </div>
        )
      })}
    </div>
  )
}

export default DisplayContentData
