import React from 'react'

import { SectionSubtitle, Input } from '../../..'
import { Spacer } from '../../../global'
import { SectionContainer } from '../styles'

const DescriptionSection = ({
  handleChange,
}: {
  handleChange: (event: ChangeEvent) => void
}) => {
  return (
    <SectionContainer direction="column">
      <div>
        <SectionSubtitle>Titulo del Curso*</SectionSubtitle>
        <Spacer height={20} />
        <Input
          type="text"
          name="title"
          id="course-title"
          height={40}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <Spacer height={25} />
      <div>
        <SectionSubtitle>Subtitulo*</SectionSubtitle>
        <Spacer height={20} />
        <Input
          type="text"
          name="subtitle"
          id="course-subtitle"
          placeholder="Describe el curso en 3 palabras"
          height={40}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <Spacer height={25} />
      <div>
        <SectionSubtitle>Descripción</SectionSubtitle>
        <Spacer height={20} />
        <Input
          type="textarea"
          name="description"
          id="course-description"
          placeholder="Escribe una breve descripción"
          height={120}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <Spacer height={50} />
    </SectionContainer>
  )
}

export default DescriptionSection
