import React, { useEffect, useState } from 'react'

import { HiOutlinePlus } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

import { useProfessors, useUser } from '../../../context'
import { NewUserButton } from '../../../pages/administration/styles'
import FilteringList from '../../dropdowns/dropdownList/FilteringList'
import { Spacer } from '../../global'
import Search from '../../search/Search'
import { SectionSubtitle } from '../../texts'
import { AdminProfessorMenu, Profesor } from '../../types'
import ListWithImage from '../listWithImage/ListWithImage'

const ProfessorsList = () => {
  const navigate = useNavigate()
  const { onEditionMode } = useUser()
  const { activeProfessor, professorsList, setActiveProfessor } =
    useProfessors()
  const [searchValue, setSearchValue] = useState<string>('')

  const [filteredProfessors, setFilterProfessors] = useState<Profesor[] | null>(
    null,
  )

  useEffect(() => {
    setFilterProfessors(FilteringList(searchValue, professorsList))
  }, [searchValue])

  const selectProfessor = (professor: Profesor) => {
    if (!onEditionMode) {
      setActiveProfessor(professor)
      navigate(`/profesores/${AdminProfessorMenu.activity.label}`)
    } else {
      setActiveProfessor(professor)
    }
  }

  return (
    <div className="list">
      <Spacer height={15} />
      <div style={{ padding: '8px 24px', width: '100%' }}>
        <SectionSubtitle fontWeight={600} fontSize={16} lineHeight={24}>
          Seleccione Profesor
        </SectionSubtitle>
        <Spacer height={10} />
        <Search
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          width="100%"
        />
      </div>
      <Spacer height={10} />
      <ListWithImage
        list={filteredProfessors ? filteredProfessors : professorsList}
        onClick={selectProfessor}
        disabled={onEditionMode}
        activeId={activeProfessor && activeProfessor.uid}
      />
      <NewUserButton $hasSeparation={true}>
        <button onClick={() => navigate('/nuevo-usuario?user=professor')}>
          <HiOutlinePlus />
        </button>
        <p>Añadir Profesor</p>
      </NewUserButton>
      <NewUserButton $hasSeparation={false}>
        <SectionSubtitle fontSize={14} fontWeight={600}>
          Colaboradores
        </SectionSubtitle>
      </NewUserButton>
      <NewUserButton $hasSeparation={true}>
        <button onClick={() => navigate('/nuevo-usuario?user=admin')}>
          <HiOutlinePlus />
        </button>
        <p>Añadir Administrador</p>
      </NewUserButton>
    </div>
  )
}

export default ProfessorsList
