import { useState } from 'react'

interface InputState {
  [key: string]: string
}

const useInputReset = () => {
  const [inputState, setInputState] = useState<InputState>({})

  const resetInput = (name: string) => {
    setInputState((prevState) => ({
      ...prevState,
      [name]: '',
    }))
  }

  return { inputState, resetInput }
}

export default useInputReset
