import styled from 'styled-components'

import '@fontsource/plus-jakarta-sans'

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 40px 17px 23px;
  background-color: #fdfdfd;
  margin-bottom: 7px;
  width: 100%;

  .icon-plus {
    color: #08a8d2;
    padding-right: 8px;
    font-size: 20px;
  }
`
export const CarouselTitle = styled.div`
  text-align: left;

  margin-bottom: 20px;

  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.72px;
    color: #030410;
  }
`

export const CoursesContainer = styled.div<{ $showCoursesDetails: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 111px);
  flex-wrap: wrap;
  overflow: hidden;
  margin-right: 30px;
  height: ${(props) => (props.$showCoursesDetails ? 'fit-content' : '170px')};
`

export const PlusButton = styled.div`
  font-size: 24px;
  cursor: pointer;
`
