import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: flex;
  min-width: 543px;
  flex-direction: column;
  background-color: #f6f6f6;

  progress-text {
    display: flex;
    align-items: center;
  }
  .progress {
    height: 2px;

    margin-top: -1px;
  }
`

export const DragAndDropContainer = styled.form`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #fdfdfd;
  background: #fafafa;
  height: 40px;
  cursor: pointer;
`

export const UploadButton = styled.div`
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #08a8d2;
  width: 40px;
  height: 40px;
  color: #08a8d2;
  font-size: 25px;
`

export const DragAndDrop = styled.div`
  width: calc(100% - 50px);
  height: 40px;
  text-align: left;
  align-items: center;
  display: flex;

  p {
    font-size: 12px;
    padding: 0 12px;
    font-weight: 500;
    color: #8e92bc;
    cursor: pointer;
  }
`
export const FileTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
`
