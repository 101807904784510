import React from 'react'

import './App.css'
import { AppRouter } from './routes'

import '@fontsource/plus-jakarta-sans'

const App = () => {
  // const [userType, setUserType] = useState("admin");

  return (
    <div className="App" style={{ fontFamily: 'Plus Jakarta Sans' }}>
      <AppRouter />
    </div>
  )
}

export default App
