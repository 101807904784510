import React, { useEffect, useState } from 'react'

import { useStudents } from '../../../context'
import { Student } from '../../types'
import { TableContainer } from '../styles'

const ProfessorSharedProccess = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  professorId,
  professorName,
}: {
  professorId: string
  professorName: string
}) => {
  const { studentsList } = useStudents()

  const [sharedProccess, setSharedProccess] = useState<Student[] | null>(null)

  useEffect(() => {
    if (studentsList) {
      const hasMoreThanOneProfessor = studentsList.filter(
        (student) =>
          student.professors.length > 0 &&
          student.professors.some((professor) => professor === professorName),
      )
      setSharedProccess(hasMoreThanOneProfessor)
    }
  }, [studentsList])

  return (
    <TableContainer>
      {sharedProccess &&
        sharedProccess.map((std, i) => {
          return (
            <div
              key={i}
              className="t-row"
              style={{ justifyContent: 'space-between' }}
            >
              <div className="t-student with-image">
                <div
                  className="profile-image"
                  style={{
                    backgroundImage: `url(${std.image && std.image.length > 0 ? std.image : '/assets/profile.png'})`,
                  }}
                ></div>
                {std.name} {std.lastname}
              </div>
              <div
                className="t-professors"
                style={{
                  width: '50%',
                  flex: 'none',
                  gap: '20px',
                  justifyContent: 'flex-start',
                }}
              >
                Profesores:{' '}
                {std.professors.map((name, i) => {
                  return (
                    <span key={i} style={{ textTransform: 'capitalize' }}>
                      {' '}
                      {name}{' '}
                    </span>
                  )
                })}
              </div>
            </div>
          )
        })}
    </TableContainer>
  )
}

export default ProfessorSharedProccess
