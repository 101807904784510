import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'

import { useCupons, useStudents } from '../../context'
import { InlineMenu } from '../InlineMenu'
import { Spacer } from '../global'
import {
  AdminDebtorsBonusList,
  AdminDueBonusList,
  AdminInactiveStudentsList,
  AdminLastClassBonusList,
} from '../lists'
import { SectionSubtitle } from '../texts'
import { AdminBonusMenu, Cupon, Student } from '../types'

import { BonusFilterContainer } from './styles'

const BonusFilter = () => {
  const { cupons } = useCupons()
  const { studentsList } = useStudents()

  const [activeFilter, setActiveFilter] = useState<string | string[] | null>(
    AdminBonusMenu[0],
  )
  const [filterList, setFilterList] = useState<Cupon[]>([])
  const [filterPersonList, setFilterPersonList] = useState<Student[] | null>([])

  const filterDueBonus = () =>
    cupons.filter((cupon) => dayjs(cupon.dueDate).isBefore(dayjs()))

  const filterLastClassBonus = () =>
    cupons.filter((cupon) => cupon.amountOfClasses - cupon.attendance === 1)

  const filterDebtors = () =>
    studentsList?.filter(
      (student) => student.currentAmountOfClasses < student.currentAttendance,
    ) || []

  const filterInactiveStudents = () => {
    const filterByLastClass = studentsList?.filter(
      (student) => student.lastClassDate,
    )
    return (
      filterByLastClass?.filter(
        (student) => dayjs().diff(dayjs(student.lastClassDate), 'month') >= 3,
      ) || []
    )
  }

  // Mapeo de funciones de filtrado
  const filterMapping: { [key: string]: () => void } = {
    [AdminBonusMenu[0]]: () => setFilterList(filterDueBonus()),
    [AdminBonusMenu[1]]: () => setFilterList(filterLastClassBonus()),
    [AdminBonusMenu[2]]: () => setFilterPersonList(filterDebtors()),
    [AdminBonusMenu[3]]: () => setFilterPersonList(filterInactiveStudents()),
  }

  // Filtra según el filtro activo
  const filterBonus = (filter: string | string[] | null) => {
    setActiveFilter(filter || AdminBonusMenu[0])
    if (typeof filter === 'string') filterMapping[filter]?.()
  }

  useEffect(() => {
    filterBonus(activeFilter)
  }, [activeFilter, studentsList, cupons])

  return (
    <BonusFilterContainer>
      <SectionSubtitle fontSize={18} fontWeight={600} lineHeight={27}>
        Bonos
      </SectionSubtitle>
      <Spacer height={32} />
      <InlineMenu
        userType="admin"
        menuName="adminBonusMenu"
        activeFilter={activeFilter}
        setActiveFilter={(value) => filterBonus(value)}
      />
      <Spacer height={32} />
      {activeFilter && (
        <SectionSubtitle fontSize={14} fontWeight={600}>
          {activeFilter}
        </SectionSubtitle>
      )}
      {filterList.length > 0 && (
        <div
          style={{
            width: activeFilter === AdminBonusMenu[2] ? '400px' : '650px',
          }}
        >
          {activeFilter === AdminBonusMenu[0] && (
            <AdminDueBonusList bonus={filterList} />
          )}
          {activeFilter === AdminBonusMenu[1] && (
            <AdminLastClassBonusList bonus={filterList} />
          )}
        </div>
      )}
      {filterPersonList && filterPersonList.length > 0 && (
        <div
          style={{
            width:
              activeFilter === AdminBonusMenu[3]
                ? '850px'
                : activeFilter === AdminBonusMenu[2]
                  ? '400px'
                  : '650px',
          }}
        >
          {activeFilter === AdminBonusMenu[2] && filterPersonList && (
            <AdminDebtorsBonusList studensList={filterPersonList} />
          )}
          {activeFilter === AdminBonusMenu[3] && filterPersonList && (
            <AdminInactiveStudentsList studensList={filterPersonList} />
          )}
        </div>
      )}
    </BonusFilterContainer>
  )
}

export default BonusFilter
