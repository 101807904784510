import styled from 'styled-components'
import '@fontsource/plus-jakarta-sans'

export const HeaderInitialForm = styled.div`
  padding: 40px 32px 68px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 198px;
    height: 108px;
  }
  h3 {
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -1.2px;
  }
`
