import React from 'react'

import { HeaderInitialForm } from './styles'

const FormHeader = () => {
  return (
    <HeaderInitialForm>
      <img src="/assets/icons/book-square.svg" alt="diga-ah!" />
      <h3> Diga -ah!</h3>
    </HeaderInitialForm>
  )
}

export default FormHeader
