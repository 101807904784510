import React, { useState, ChangeEvent, FormEvent } from 'react'

import Spinner from 'react-bootstrap/esm/Spinner'
import { useNavigate } from 'react-router'

import {
  StepOne,
  StepTwo,
  ConfirmData,
} from '../components/forms/registration-form'
import {
  FormularioRegister,
  ScrollBar,
  TitleForm,
  FormularioRectanguloRegister,
  ContentBack,
  StepProgress,
} from '../components/forms/registration-form/styles'
import {
  calculateCompletionPercentage,
  stepColor,
} from '../components/forms/registration-form/utils'
import { RegistrationForm } from '../components/types'
import { useUser } from '../context'
import { createNewDocument, handleError } from '../firebase/utils'
import { setCookie } from '../hooks/cookies'

const initialFormState: RegistrationForm = {
  name: '',
  lastname: '',
  birthDate: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
  genre: '',
  experienceType: '',
  activities: [],
  form: false,
  role: 'strudent',
}

const RegistrationFormPage = () => {
  const navigate = useNavigate()
  const { createUserWithEmailAndPassword } = useUser()
  const [stepNumber, setStepNumber] = useState(1)
  const [formData, setFormData] = useState<RegistrationForm>(initialFormState)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target
    setFormData((prevState: RegistrationForm) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (stepNumber !== 3) {
      setStepNumber((prevStep) => (prevStep < 3 ? prevStep + 1 : prevStep))
      return
    }

    setError(null)
    setIsLoading(true)

    const { password, confirmPassword, ...userData } = formData

    try {
      if (password !== confirmPassword || userData.email === '') {
        throw new Error('Completa correctamente todos los campos')
      }

      const userCredential = await createUserWithEmailAndPassword(
        userData.email,
        password,
      )

      if (userCredential) {
        const { user } = userCredential
        createNewDocument('students', userData, userCredential.user)

        const token = await user.getIdToken()

        setCookie('userToken', token, { expires: 30 })
        setCookie('userRole', 'student', { expires: 30 })

        setIsLoading(false)
        navigate('/initial-form')
      } else {
        throw new Error('No se pudo crear el usuario')
      }
    } catch (firebaseError) {
      setError(handleError(error))

      setIsLoading(false)
    }
  }

  const goBack = () => {
    stepNumber === 2
      ? setStepNumber(1)
      : stepNumber === 3
        ? setStepNumber(2)
        : navigate(-1)
  }

  return (
    <FormularioRegister>
      <ScrollBar>
        <div className="holaSvg" />

        <TitleForm>Diga-ah!</TitleForm>
        {error ? (
          <div className="error-message">{error}</div>
        ) : isLoading ? (
          <Spinner />
        ) : (
          <FormularioRectanguloRegister
            step={stepNumber}
            onSubmit={handleSubmit}
          >
            <ContentBack onClick={goBack}>
              <div className="arrow-right-back" />
              <p>volver</p>
            </ContentBack>
            {stepNumber === 1 && (
              <div>
                <p className="titleRegister">Primer paso: Crea tu cuenta</p>
              </div>
            )}

            <StepProgress
              $completionPercentages={{
                1: calculateCompletionPercentage(1, formData),
                2: calculateCompletionPercentage(2, formData),
                3: calculateCompletionPercentage(3, formData),
              }}
              $currentStep={stepNumber}
            >
              <div className="stepper-wrapper">
                <div className={stepColor(1, stepNumber)}>
                  <div className="step-counter">1</div>
                </div>
                <div className={stepColor(2, stepNumber)}>
                  <div className="step-counter">2</div>
                </div>
                <div className={stepColor(3, stepNumber)}>
                  <div className="step-counter">3</div>
                </div>
              </div>
            </StepProgress>
            {stepNumber === 1 ? (
              <StepOne
                setStepNumber={setStepNumber}
                handleChange={handleChange}
                formData={formData}
              />
            ) : stepNumber === 2 ? (
              <StepTwo
                setStepNumber={setStepNumber}
                handleChange={handleChange}
                formData={formData}
              />
            ) : (
              stepNumber === 3 && <ConfirmData formData={formData} />
            )}
          </FormularioRectanguloRegister>
        )}
      </ScrollBar>
    </FormularioRegister>
  )
}

export default RegistrationFormPage
