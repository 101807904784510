import styled from 'styled-components'

export const InvoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;

  .invoiceModal {
    padding: 0;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const DateCalendarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .calendar {
    display: flex;
    gap: 20px;
    align-items: flex-end;
  }

  .MuiInputBase-root {
    color: #8e92bc;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    border-radius: 14px;

    .MuiInputBase-input {
      padding: 10px 14px;
    }

    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #dbdbdb;
      &.Mui-focused {
        border-color: #dbdbdb;
        border-width: 1px;
      }
    }
  }
`

export const FilesContainer = styled.div`
  width: 100%;

  .years-section {
    display: flex;
    flex-direction: column;

    .year-title {
      padding-left: 5px;
    }
  }
`

export const FileRow = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  &:hover {
    box-shadow: 0px 8px 8px 0px rgb(74 77 102 / 5%);
    border-radius: 10px;
  }

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 40px);
    text-decoration: none;
    color: #0a0a18;

    .file-name {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
        margin-right: 8px;
      }

      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .download-icon {
      display: flex;
      align-items: center;
      background-color: transparent;
      outline: none;
      border: none;

      svg {
        font-size: 20px;
        color: #08a8d2;
      }
      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .delete-icon {
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      color: #08a8d2;
      font-size: 20px;
    }
  }
`
